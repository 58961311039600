import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
//import { createBrowserHistory } from 'history';
import './index.css';
import App from './App';
import MinWidth from './pages/MinWidth';
import Templates from './pages/Templates';
import Creative from './pages/Creative';
import Offers from './pages/Offers';
import Schedule from './pages/Schedule';
import Registration from './pages/Registration';
import Order from './pages/Order';
import Customer from './pages/Customer';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/Store';
import axios from 'axios';
import { appTheme } from './AppTheme';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { deDE } from '@mui/material/locale';
import { CssBaseline } from '@mui/material';

import { CreativeService } from './service/CreativeService';
import { OrderService } from './service/OrderService';

import { Markets as MarketsMobile } from './m/pages/Markets';
import { Templates as TemplatesMobile } from './m/pages/Templates';
import { Creative as CreativeMobile } from './m/pages/Creative';
import { Offers as OffersMobile } from './m/pages/Offers';
import { Order as OrderMobile } from './m/pages/Order';
import { Preview as PreviewMobile } from './m/pages/Preview';
import { Upselling as UpsellingMobile } from './m/pages/Upselling';
import { Editions as EditionsMobile } from './m/pages/Editions';
import { Schedule as ScheduleMobile } from './m/pages/Schedule';
import { RegisterMail as RegisterMailMobile } from './m/pages/RegisterMail';
import { RegisterContact as RegisterContactMobile } from './m/pages/RegisterContact';
import { RegisterPayment as RegisterPaymentMobile } from './m/pages/RegisterPayment';
import { Customer as CustomerMobile } from './m/pages/Customer';
import { Orders as OrdersMobile } from './m/pages/Orders';
import { Invoices as InvoicesMobile } from './m/pages/Invoices';
import { ServicePackages as ServicePackagesMobile } from './m/pages/ServicePackages';
import { LicenseInfo } from '@mui/x-license-pro';

// import DebugLayout from './DebugLayout';

/*
 * 
 */
const main = async () => {
  // const history = createBrowserHistory();
  const properties = await axios.get('/webstore/service/v1/admin/properties/compact');

  const blockingChannel = new BroadcastChannel('blocking-channel');
  blockingChannel.onmessage = e => {
    if ('starting' === e.data) {
      blockingChannel.postMessage('rejected');
    }
    else if ('rejected' === e.data) {
      let bu = properties.data.blockingUrl;
      bu += "?org=" + encodeURIComponent(window.location.href);
      window.location = bu;
    }
  };
  blockingChannel.postMessage('starting');


  let l = deDE;

  let MUI_LICENSE_KEY = '6e2b4820f96397165c48e437871fb65aTz00NzY3MSxFPTE2ODk5NDQ0NDU0OTEsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
  LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

  let theme = createTheme(appTheme(properties.data), l);

  theme = responsiveFontSizes(theme);
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <CreativeService />
          <OrderService />
          <BrowserRouter >
            <Routes>
              {/* <Route element={<DebugLayout />}> */}
              <Route exact path="/" element={<App />}></Route>
              <Route exact path="/webstore/app/index.html" element={<App />} />
              <Route exact path="/webstore/app/min-width" element={<MinWidth />} />
              <Route exact path="/webstore/app/templates" element={<Templates />} />
              <Route path="/webstore/app/creative" element={<Creative />} />
              <Route exact path="/webstore/app/offers" element={<Offers />} />
              <Route exact path="/webstore/app/schedule" element={<Schedule />} />
              <Route path="/webstore/app/order" element={<Order />} />
              <Route exact path="/webstore/app/customer" element={<Customer />} />
              <Route path="/webstore/app/customer/:tab" element={<Customer />} />
              <Route exact path="/webstore/app/register" element={<Registration />} />
              <Route path="/webstore/app/register/:tab" element={<Registration />} />

              <Route path="/webstore/app/m/markets" element={<MarketsMobile />} />
              <Route path="/webstore/app/m/templates" element={<TemplatesMobile />} />
              <Route path="/webstore/app/m/creative" element={<CreativeMobile />} />
              <Route path="/webstore/app/m/offers" element={<OffersMobile />} />
              <Route path="/webstore/app/m/order" element={<OrderMobile />} />
              <Route path="/webstore/app/m/preview" element={<PreviewMobile />} />
              <Route path="/webstore/app/m/upselling" element={<UpsellingMobile />} />
              <Route path="/webstore/app/m/editions" element={<EditionsMobile />} />
              <Route path="/webstore/app/m/schedule" element={<ScheduleMobile />} />
              <Route path="/webstore/app/m/register-mail" element={<RegisterMailMobile />} />
              <Route path="/webstore/app/m/register-contact" element={<RegisterContactMobile />} />
              <Route path="/webstore/app/m/register-payment" element={<RegisterPaymentMobile />} />
              <Route path="/webstore/app/m/customer" element={<CustomerMobile />} />
              <Route path="/webstore/app/m/customer-orders" element={<OrdersMobile />} />
              <Route path="/webstore/app/m/customer-invoices" element={<InvoicesMobile />} />
              <Route path="/webstore/app/m/customer-servicepackages" element={<ServicePackagesMobile />} />
              {/* </Route> */}
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

main();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();