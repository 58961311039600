import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Card, CardContent, CardActions, Typography, Stack } from '@mui/material';
import AreaCardHeader from '../common/AreaCardHeader';
import { EditionsPicker } from './EditionsPicker';
import { selectedOffer } from '../../redux/Selectors.js';

export const EditionsCard = React.forwardRef(({ height, width, onClose }, ref) => {
    const { t } = useTranslation();


    return (
        <Card
            variant='outlined'
            sx={{ width: '100%', maxWidth: width }}>
            <AreaCardHeader
                sx={{ height: 38 }}
                titleTypographyProps={{ variant: 'areaCardHeader' }}
                title={t('order.editionsCardTitle')}
                onClose={onClose}>
            </AreaCardHeader>
            <CardContent
                sx={{ height: height - 40, width: width, overflowY: "scroll" }}
                alignitems='center'>
                <EditionsPicker width={width} height={height}></EditionsPicker>
            </CardContent>

        </Card >
    );

});