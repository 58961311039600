import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, IconButton, Paper, Typography } from '@mui/material';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ShortcutRoundedIcon from '@mui/icons-material/ShortcutRounded';

export const DraftConfirmationDialog = ({ open, handleClose, order, userName }) => {

    const { t } = useTranslation();

    return order && userName && (
        <Dialog fullScreen open={open}>
            <Paper sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                <Typography
                    sx={{ margin: '5vh', fontSize: 36, fontWeight: 'bold' }}>{t('draft.success.title')}</Typography>
                <Typography
                    sx={{ textAlign: 'center' }}
                    dangerouslySetInnerHTML={{ __html: t('draft.success', { customerName: userName, orderCode: order.orderCode }) }}></Typography>
                <Box sx={{ marginTop: '5vh' }} >
                    <IconButton  >
                        <LocalPrintshopOutlinedIcon />
                    </IconButton>
                    <IconButton  >
                        < ShortcutRoundedIcon />
                    </IconButton>
                </Box>
                <Button sx={{ marginTop: '10vh', textTransform: 'none' }} variant="navigationBack" onClick={handleClose}>{t('order.newAd')}</Button>
            </Paper >
        </Dialog >
    ) || (<></>);
}
