import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Box,
    IconButton,
    Popover,
    Slider,
    Stack,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
    device,
    motifColumns,
    properties,
} from '../../redux/Selectors.js';

import { useOutsideClickListener } from '../../hooks/Hooks.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';

export const UpsellingTool = ({ item, anchorEl, onPopperWidth, onPopperCheck, onPopperDismiss }) => {
    const { t } = useTranslation();
    const _device = useSelector((state) => device(state));
    const _motifColumns = useSelector((state) => motifColumns(state));  // initialer wert
    const _properties = useSelector((state) => properties(state));
    const [width, setWidth] = useState(1);
    const [pos, setPos] = useState(null);
    const ref = useRef(null);

    useOutsideClickListener(ref, (e) => {
        dismiss();
    });

    useEffect(() => {
        if (_motifColumns) {
            setWidth(_motifColumns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_motifColumns]);

    useEffect(() => {
        if (item) {
            setPos(popoverPosition(item));
            setWidth(item.__width);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    const useStyles = makeStyles(theme => ({
        root: {
            width: 300
        },
        margin: {
            //            height: theme.spacing(3)
        },
        thumb: {
            background: "background",
            "&~&": {
                background: "green"
            }
        },
        markLabel: {
            color: 'background',
            background: "primary"
        },
        markLabelActive: {
            fontWeight: 'bold',
            color: 'background',
            background: "primary"
        },
        rail: {
            color: 'background',
            background: "primary",
            /*            background: "linear-gradient(to right, red, red 50%, green 50%, green);"*/
        },
        track: {
            color: 'background',
            background: "primary",
            // background: "orange"
        },
        valueLabel: {
            borderRadius: 40,
            color: _properties.appTextColor,
            background: "background",
        }
    }));

    const classes = useStyles();

    const dismiss = () => {
        //        onPopperWidth(orgWidth, item);
        onPopperDismiss();
    }

    const onChange = (e, item) => {
        setWidth(e.target.value);
        onPopperWidth(e.target.value, item);
    };

    const columnMarks = item => {
        let marks = [];
        if (item.unitWidth === 'COLUMNS' &&
            item.minWidth !== item.maxWidth) {
            for (let i = item.minWidth; i <= item.maxWidth; ++i) {
                marks.push({ value: i, label: String(i) });
            }
        }
        return marks;
    };

    const popoverPosition = (item) => {

        const br = anchorEl.getBoundingClientRect();
        const popOverWidth = 300;

        if (br.width > popOverWidth || columnMarks(item).length || 'mobile' === _device) {
            return ({
                popOverWidth,
                placement: 'bottom',
                aVertical: 'bottom',
                aHorizontal: 'left',
                tVertical: 'top',
                tHorizontal: 'left'
            });
        }
        else {
            return ({
                popOverWidth,
                placement: 'right',
                aVertical: 'center',
                aHorizontal: 'right',
                tVertical: 'center',
                tHorizontal: 'left'
            });
        }
    };

    // const pos = popoverPosition(item);

    return (
        (pos &&
            <Popover
                // Damit das Pfeilchen einen transparenten Hintergrund erhält
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: 'transparent',
                        borderRadius: 0,
                        boxShadow: 0
                    },
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                ok={{ onClick: onPopperCheck }}
                cancel={{ onClick: dismiss }}

                anchorOrigin={{
                    vertical: pos.aVertical,
                    horizontal: pos.aHorizontal,
                }}

                transformOrigin={{
                    vertical: pos.tVertical,
                    horizontal: pos.tHorizontal,
                }}
            >
                <Box
                    id="upselling-toolbox"
                    ref={ref}
                    sx={{
                        position: "relative",
                        ml: 'right' === pos.placement ? "12px" : "0px",
                        mt: 'right' === pos.placement ? "0x" : "12px",
                        // Pfeilchen 
                        "&::before": {
                            backgroundColor: _properties.appPrimaryColor,
                            content: '""',
                            display: "block",
                            position: "absolute",
                            width: 12,
                            height: 12,
                            left: 'right' === pos.placement ? -6 : "calc(12% - 6px)",
                            transform: "rotate(45deg)",
                            top: 'right' === pos.placement ? "calc(50% - 6px)" : -6
                        },
                        width: pos.popOverWidth,
                        backgroundColor: _properties.appPrimaryColor,
                        color: _properties.appBackgroundColor
                    }}>
                    <Stack sx={{ ml: 8, mr: 8 }}>
                        {columnMarks(item).length === 0 && (<>
                            <Typography
                                sx={{ fontWeight: 'bold', fontSize: 14, textAlign: 'left', color: '#F4F4F4', p: 5 }}>{t('upselling.itemTooltip')}</Typography>
                        </>)}
                        {columnMarks(item).length !== 0 && (<>
                            <Typography
                                sx={{ fontWeight: 'bold', fontSize: 14, textAlign: 'left', color: '#F4F4F4', p: 5 }}>{t('upselling.itemOption')}</Typography>
                            <Typography
                                sx={{ fontSize: 14, textAlign: 'left', color: '#F4F4F4', p: 5 }}>{t('creative.columns')}</Typography>
                            <Box sx={{
                                ml: 4, mr: 4,
                            }}>
                                <Slider
                                    classes={{
                                        thumb: classes.thumb,
                                        rail: classes.rail,
                                        track: classes.track,
                                        valueLabel: classes.valueLabel,
                                        markLabel: classes.markLabel,
                                        markLabelActive: classes.markLabelActive,
                                        mark: classes.mark
                                    }}
                                    color='primary'
                                    size="small"
                                    aria-label={t('creative.columns')}
                                    valueLabelDisplay="auto"
                                    min={item.minWidth}
                                    max={item.maxWidth}
                                    value={width}
                                    onChange={(e) => onChange(e, item)}
                                    onChangeCommitted={() => {
                                    }}
                                    marks={columnMarks(item)}
                                    sx={{
                                        ml: 4, mr: 4,
                                        fontSize: 10,
                                        // backgroundColor: _properties.appPrimaryColor,
                                        // color: _properties.appBackgroundColor,
                                        borderRadius: 20,
                                        '& .MuiSlider-thumb': {
                                            color: _properties.appBackgroundColor
                                        },
                                        '& .MuiSlider-track': {
                                            color: _properties.appBackgroundColor
                                        },
                                        '& .MuiSlider-rail': {
                                            color: _properties.appBackgroundColor
                                        },
                                        '& .MuiSlider-active': {
                                            color: _properties.appBackgroundColor
                                        }
                                    }}
                                />
                            </Box>
                        </>)
                        }

                        <Stack direction="row"
                            justifyContent="space-around"
                            alignItems="center"
                            spacing={6}
                            sx={{ width: '100%', pt: 2, pb: 2, pl: 10, pr: 10 }} >
                            <IconButton size="large" sx={{ color: _properties.appBackgroundColor }} onClick={dismiss} >
                                < FontAwesomeIcon icon={faXmark} size='xs' />
                            </IconButton>
                            <IconButton size="large" sx={{ color: _properties.appBackgroundColor }} onClick={onPopperCheck} >
                                < FontAwesomeIcon icon={faCheck} size='xs' />
                            </IconButton>
                        </Stack>
                    </Stack>
                </Box>
            </Popover>)
    );
}
