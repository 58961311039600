import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Box, Grid, FormGroup, FormControlLabel, Checkbox, TextField,
    FormControl, InputLabel, Select, MenuItem, Typography, Input,
    FormHelperText
} from '@mui/material';
import { } from '@mui/material'; // Grid version 2

import { Label } from './Commons.jsx';
import { CustomExpandMore } from '../../common/widgetTools.js';

import { addCriterion, setCriterionWithPattern } from '../../redux/Actions.js';
import { orderCriterionWithPattern, device } from '../../redux/Selectors.js';

export const CriteriaArea = ({ criterias, offer, order, properties }) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const _criterionWithPattern = useSelector((state) => orderCriterionWithPattern(state));
    const _device = useSelector((state) => device(state));

    const [criteriasToDisplay, setCriteriasToDisplay] = useState([]);
    const [localCriteriaValues, setLocalCriteriaValues] = useState([]);
    const [orderCriterionEnabled, setOrderCriterionEnabled] = useState(false);
    const [offerCriterion, setOfferCriterion] = useState({});
    const [offerCriterionValid, setOfferCriterionValid] = useState(true);

    useEffect(() => {
        console.log("CriteriaArea useEffect for criterias and offer");
        console.log(criterias);
        console.log(offer);
        let ctd = [];
        if (criterias && offer) {
            offer.criteriaValues.filter(cv => cv.description === 'ordernopatterncriteria').forEach(crit => {
                var c = criterias.find(x => x.shortName === crit.shortName
                    && (x.validFrom == null || new Date(x.validFrom) < Date.now())
                    && (x.validTo == null || new Date(x.validTo) > Date.now())
                );
                if (Boolean(c)) {
                    ctd.push(c);
                }
            })
        }
        console.log(ctd);
        setCriteriasToDisplay(ctd);
    }, [criterias, offer]);

    useEffect(() => {
        console.log(JSON.stringify(_criterionWithPattern));
        if (offer?.criteriaValues?.find(c => c.description === 'orderwithpatterncriteria')) {
            var criteria = offer.criteriaValues.find(c => c.description === 'orderwithpatterncriteria');
            // Wenn abgelaufen raus
            if (criterias && criterias.find(x => x.shortName === criteria.shortName
                && ((x.validFrom != null && new Date(x.validFrom) > Date.now())
                    || (x.validTo != null && new Date(x.validTo) < Date.now()))
            )) {
                setOrderCriterionEnabled(false);
            } else {
                setOrderCriterionEnabled(true);
                let tmpOfferCriterion = JSON.parse(JSON.stringify(criteria));
                tmpOfferCriterion.value = _criterionWithPattern?.value ? _criterionWithPattern.value : '';
                setOfferCriterion(tmpOfferCriterion);
                console.log(tmpOfferCriterion);
            }
        }
    }, [offer, criterias, _criterionWithPattern]);


    useEffect(() => {
        let values = [];
        criterias?.forEach(criteria => {
            let name = getName(criteria);
            console.log("name " + name);
            let value = '';
            if (order?.criterionValues) {
                const criterionValues = order.criterionValues.slice();
                criterionValues.forEach(ce => {
                    if (ce.shortName === name) {
                        value = ce.value === null ? '' : ce.value;
                    }
                });
            }
            console.log("value of " + name + " = " + value);
            let criteriaPair = { name: name, value: value };
            values.push(criteriaPair);
        });
        console.log(values);
        setLocalCriteriaValues(values);
    }, [order?.criterionValues, criterias])

    const addCriteria = (e) => {
        if (e.target) {
            console.log("addCriteria " + e.target.name + " = " + e.target.value);
            if (e.target.name === offerCriterion.shortName) {
                setOfferCriterion(prevState => ({
                    ...prevState,
                    value: e.target.value
                }));
                const reg = new RegExp(offerCriterion.criterionPattern);
                let valid = !e.target.value || e.target.value.length === 0 || reg.test(e.target.value);
                setOfferCriterionValid(valid);
                dispatch(addCriterion(e.target.name, valid && e.target.value?.length > 0 ? true : false));
                dispatch(setCriterionWithPattern({ name: e.target.name, value: e.target.value }));
            } else {
                let value = e.target.checked !== undefined && e.target.type !== 'text' ? e.target.checked : e.target.value;
                dispatch(addCriterion(e.target.name, value));
            }
        }
    }

    const getName = (criteria) => {
        if (criteria) {
            return (criteria.name ? criteria.name : criteria.shortName)
        } else {
            return '';
        }
    }

    const getValue = (criteria) => {
        console.log(" getValue for " + criteria.name + " returns " + localCriteriaValues.find(c => c.name === getName(criteria))?.value);
        console.log(criteria.criterionDataType);
        if (criteria.criterionDataType === 'Bool') {
            return (localCriteriaValues.find(c => c.name === getName(criteria))?.value);
        } else {
            return (localCriteriaValues.find(c => c.name === getName(criteria))?.value?.replace('VALUE', ''));  // => Das mit dem VALUE muss noch geklärt werden.
        }
    }

    function hashCode(s) {
        for (var i = 0, h = 0; i < s.length; i++)
            h = Math.imul(31, h) + s.charCodeAt(i) | 0;
        return h;
    }


    return (
        <>

            {criteriasToDisplay?.map(criteria => {
                if (criteria.criterionDataType === 'Bool') {
                    return (<React.Fragment key={criteria._id}>
                        {_device === 'desktop' && (<Grid item key={criteria._id} xs={12}>
                            <FormGroup sx={{ fontSize: 14 }}>
                                <FormControlLabel
                                    control={<Checkbox
                                        id="crit-checkbox"
                                        name={criteria.name}
                                        onChange={addCriteria}
                                        checked={Boolean(getValue(criteria))}
                                    />}
                                    label={<Typography sx={{ fontSize: 14 }}>{criteria.name}</Typography>}
                                />

                            </FormGroup>
                        </Grid>)}
                        {_device === 'mobile' && (
                            <FormGroup sx={{ fontSize: 14 }}>
                                <FormControlLabel
                                    control={<Checkbox
                                        id="crit-checkbox"
                                        name={criteria.name}
                                        onChange={addCriteria}
                                        checked={Boolean(getValue(criteria))}
                                    />}
                                    label={<Typography sx={{ fontSize: 14 }}>{criteria.name}</Typography>}
                                />

                            </FormGroup>
                        )}

                    </React.Fragment>);
                } else if (criteria.criterionDataType === 'String' && criteria.collectionEntries?.length > 0) {
                    return (<React.Fragment key={criteria._id}>
                        {_device === 'desktop' && (<> <Grid item xs={5} >
                            <Box sx={{ mt: 3, pt: 8 }}>
                                <Label text={criteria.name} />
                            </Box>
                        </Grid>
                            <Grid item xs={7}>
                                <FormControl variant="standard" sx={{
                                    m: 1, minWidth: '160px', fontSize: 12
                                }}>
                                    <InputLabel id="crit-label"  >{getValue(criteria) ? '' : t('schedule.emptySelect')}</InputLabel>
                                    <Select
                                        id="crit-select"
                                        name={criteria.name}
                                        value={getValue(criteria)}
                                        inputProps={{ style: { fontSize: 14 } }}
                                        onChange={addCriteria}
                                        label={t('schedule.emptySelect')}
                                        sx={{ '& #crit-select': { fontSize: 14 } }}
                                        disableUnderline
                                        IconComponent={CustomExpandMore}
                                        autoWidth
                                    >
                                        {criteria.collectionEntries.map(item => {
                                            console.log(hashCode(item.shortName));
                                            return (<MenuItem key={hashCode(item.shortName)} value={item.shortName} sx={{ fontSize: 14 }}>{item.shortName}</MenuItem>);
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid></>)
                        }
                        {
                            _device === 'mobile' && (
                                <FormControl variant="standard" sx={{ m: 1, minWidth: '160px', fontSize: 12 }}>
                                    <InputLabel id="crit-label">{getValue(criteria) ? '' : criteria.name}</InputLabel>
                                    <Select
                                        id="crit-select"
                                        name={criteria.name}
                                        value={getValue(criteria)}
                                        inputProps={{ style: { fontSize: 14 } }}
                                        onChange={addCriteria}
                                        label={t('schedule.emptySelect')}
                                        sx={{ '& #crit-select': { fontSize: 14 } }}
                                        disableUnderline
                                        IconComponent={CustomExpandMore}
                                        autoWidth
                                    >
                                        {criteria.collectionEntries.map(item => {
                                            console.log(hashCode(item.shortName));
                                            return (<MenuItem key={hashCode(item.shortName)} value={item.shortName} sx={{ fontSize: 14 }}>{item.shortName}</MenuItem>);
                                        })}
                                    </Select>
                                </FormControl>)
                        }
                    </React.Fragment >
                    );
                } else if (criteria.criterionDataType === 'String') {
                    return (<React.Fragment key={criteria._id}>
                        {_device === 'desktop' && (<><Grid item xs={5} >
                            <Box sx={{ mt: 3, pt: 6 }}>
                                <Label text={criteria.name} />
                            </Box>
                        </Grid>
                            <Grid item xs={7} >
                                <TextField
                                    variant='standard'
                                    label={getValue(criteria) ? '' : t('schedule.emptyInput')}
                                    inputProps={{ style: { fontSize: 14 } }}
                                    name={criteria.name}
                                    sx={{
                                        width: 200
                                    }}
                                    value={getValue(criteria)}
                                    onChange={addCriteria}
                                />
                            </Grid></>)}
                        {_device === 'mobile' && (
                            <FormControl fullWidth>
                                <InputLabel shrink={true} sx={{ ml: "-14px" }}>{criteria.name}</InputLabel>
                                <Input
                                    variant='standard'
                                    label={criteria.name}
                                    value={getValue(criteria)}
                                    inputProps={{ style: { fontSize: 14 } }}
                                    onChange={addCriteria}
                                    name={criteria.name}
                                    sx={{ width: '100%' }}

                                />
                            </FormControl>
                        )}
                    </React.Fragment >);
                }
                return (<></>);
            })
            }
            {
                orderCriterionEnabled && (<React.Fragment key='99999'>
                    {_device === 'desktop' && (<> <Grid item xs={5} >
                        <Box sx={{ mt: 3 }}>
                            <Label text={offerCriterion.criterionLabel?.replace(':', '')} />
                        </Box>
                    </Grid>
                        <Grid item xs={7}>
                            <TextField
                                variant='standard'
                                label={offerCriterion.value ? '' : t('schedule.emptyInput')}
                                inputProps={{ style: { fontSize: 14 }, pattern: offerCriterion.criterionPattern }}
                                name={offerCriterion.shortName}
                                sx={{ width: 200 }}
                                value={offerCriterion.value}
                                onChange={addCriteria}
                                helperText={offerCriterion.criterionMessage}
                                error={!offerCriterionValid}
                            />
                        </Grid></>)}
                    {_device === 'mobile' && (
                        <FormControl fullWidth>
                            <InputLabel shrink={true} sx={{ ml: "-14px" }} >{offerCriterion.criterionLabel?.replace(':', '')}</InputLabel>
                            <Input
                                variant='standard'
                                label={offerCriterion.criterionLabel?.replace(':', '')}
                                value={offerCriterion.value}
                                inputProps={{ style: { fontSize: 14 }, pattern: offerCriterion.criterionPattern }}
                                onChange={addCriteria}
                                name={offerCriterion.shortName}
                                error={!offerCriterionValid}
                                sx={{ width: '100%' }}

                            />
                            <FormHelperText sx={{ ml: "0px" }} error={!offerCriterionValid}>{offerCriterion.criterionMessage}</FormHelperText>
                        </FormControl>
                    )}
                </React.Fragment >)
            }



        </>
    );
}