import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { Box } from '@mui/system';
import Stack from '@mui/material/Stack'
import { properties, authentication, authenticationResponse, device } from '../redux/Selectors.js';
import { login } from '../redux/Actions.js';
import { grey } from '@mui/material/colors';
import { Typography } from '@mui/material';
import PasswordReminder from './customer/PasswordReminder.jsx';
import { EyeSlashIcon, EyeIcon } from '../common/widgetTools.js';
import { getPage } from '../common/navigationTools.js';


const LoginForm = ({ handleClose }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    // create state variables for each input
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [authenticationFailed, setAuthenticationFailed] = useState(false);
    const [passwordReminderOpen, setPasswordReminderOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const _properties = useSelector((state) => properties(state));
    const _authenticationResponse = useSelector((state) => authenticationResponse(state));
    const _authentication = useSelector((state) => authentication(state));
    const _device = useSelector(state => device(state));


    const handleSubmit = e => {
        e.preventDefault();
        console.log(email, password);
        dispatch(login(email, password, null, null));

    };

    const handleNewCustomer = e => {
        navigate(getPage('register', _device));
    }

    const handleForgotten = e => {
        setPasswordReminderOpen(true);
    }

    const handelCloseForgotten = () => {
        setPasswordReminderOpen(false);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    useEffect(() => {
        if (_authenticationResponse) {
            if (_authenticationResponse.status === 401) {
                setAuthenticationFailed(true);
            }
        }
    }, [_authenticationResponse]);

    useEffect(() => {
        if (_authentication && _authentication.businessPartnerNumber) {
            setAuthenticationFailed(false);
            handleClose();
        }
    }, [_authentication]);

    const styleForm = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
        width: 300,

        '& .MuiTextField-root': {
            margin: 1,
            width: 300,
            m: 3
        },
        '& .MuiButtonBase-root': {
            margin: 1,
            width: 300,
            m: 3
        },
        '& .MuiBox-root': {
            width: 300
        }
    }

    const styleFormMobile = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 2,
        width: '100%',

        '& .MuiTextField-root': {
            margin: 1,
            width: '70vw',
            m: 3
        },
        '& .MuiButtonBase-root': {
            margin: 1,
            width: '70vw',
            m: 3
        },
        '& .MuiBox-root': {
            width: '70vw'
        }
    }

    const styleButtons = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        mt: 5,
        width: 400
    }

    return (
        <Box sx={_device === 'mobile' ? styleFormMobile : styleForm} >
            {authenticationFailed && (<Typography variant="errorText">
                {t('login.invalid')}
            </Typography>)}


            <form onSubmit={handleSubmit}>

                <TextField
                    label={t('login.name')}
                    variant="outlined"
                    type="text"
                    required
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <TextField
                    label={t('login.password')}
                    variant="outlined"
                    required
                    value={password}
                    type={showPassword ? "text" : "password"}
                    onChange={e => setPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment onClick={handleClickShowPassword}
                                position="end">
                                {showPassword && (<EyeSlashIcon size='lg' />)}
                                {!showPassword && (<EyeIcon size='lg' />)}
                            </InputAdornment>
                        ),
                    }}
                />
                <Box sx={styleButtons}>
                    <Stack spacing={10}>
                        <Button type="submit" variant="contained">
                            {t('login.login')}
                        </Button>
                        < Button variant="contained" onClick={handleNewCustomer} sx={{ backgroundColor: grey[500] }}>
                            {t('status.newcustomer')}
                        </Button>
                        < Button variant="text" onClick={handleForgotten} sx={{ backgroundColor: 'transparent', color: _properties.appPrimaryColor }}>
                            {t('login.forgotten')}
                        </Button>
                    </Stack>
                </Box>
            </form>
            <PasswordReminder
                dialogOpen={passwordReminderOpen}
                userName={email}
                handleDialogClose={handelCloseForgotten} />
        </Box>
    );
};

export default LoginForm;    