import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../configuration/i18n';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Snackbar from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { tabsClasses } from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { Tab as BaseTab, tabClasses } from '@mui/material';
import { TabList, TabPanel } from '@mui/lab';
import { Tabs as BaseTabs } from '@mui/material';
import { TabPanel as BaseTabPanel } from '@mui/lab'
import { TabContext } from '@mui/lab';
import { styled } from '@mui/system';
//import { buttonUnstyledClasses } from '@mui/base';
import buttonClasses from '@mui/material/Button';

import AppHeader from '../components/AppHeader';

import { MailAddress } from '../components/customer/MailAddress';
import { Account } from '../components/customer/Account';
import { Contact } from '../components/customer/Contact';
import { Payment } from '../components/customer/Payment';
import InfoText from '../components/InfoText';
import { newBusinessPartner } from '../common/tools';
import {
    ContactMailIcon, AccountBoxIcon, PaymentsIcon,
} from '../common/widgetTools.js';

import {
    isGuestAuthenticated,
    selectedPaymentType,
    customerType,
    properties,
    locale,
    paymentTypes,
    bpResponse,
    features
} from '../redux/Selectors.js';
import {
    getAuthentication,
    logout,
    resetBpResponse,
    setCustomerType,
    saveBusinessPartner
} from '../redux/Actions';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const Registration = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { tab } = useParams()
    const refAccount = useRef(null);
    const refAddress = useRef(null);
    const refContact = useRef(null);

    const WIDTH = "90%";

    const _isGuestAuthenticated = useSelector((state) => isGuestAuthenticated(state));
    const _customerType = useSelector((state) => customerType(state));
    const _properties = useSelector((state) => properties(state));
    const _locale = useSelector((state) => locale(state));
    const _selectedPaymentType = useSelector((state) => selectedPaymentType(state));
    const _paymentTypes = useSelector((state) => paymentTypes(state));
    const _bpResponse = useSelector((state) => bpResponse(state));
    const _features = useSelector((state) => features(state));

    const [activeTab, setActiveTab] = useState('account');
    const [addressTabDisabled, setAddressTabDisabled] = useState(true);
    const [paymentTabDisabled, setPaymentTabDisabled] = useState(true);
    const [height, setHeight] = useState('auto');
    const [modified, setModified] = useState();
    const [isAccountValid, setIsAccountValid] = useState(false);
    const [isAddressValid, setIsAddressValid] = useState(false);
    const [isContactValid, setIsContactValid] = useState(false);
    const [hasIbanPayment, setHasIbanPayment] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [saveMsg, setSaveMsg] = useState('');

    const listenResize = () => {
        setHeight(window.innerHeight - 235);
    };

    useEffect(() => {
        //       dispatch(setCustomerType(_userTemplate.customerType));

        setModified(newBusinessPartner(_customerType, _properties, _locale, _selectedPaymentType));

        //       setModified(_newBusinessPartner);

        listenResize();
        window.addEventListener('resize', listenResize);
        return (() => {
            window.removeEventListener('resize', listenResize);
        });

    }, []);

    useEffect(() => {
        setActiveTab(_isGuestAuthenticated ? 'address' : 'account');
    }, [_isGuestAuthenticated]);

    useEffect(() => {
        if (_bpResponse) {
            if (_bpResponse.success) {
                setSaveMsg(t('customerdata.success'));
            } else {
                if (_bpResponse.errors && _bpResponse.errors[0] && _bpResponse.errors[0].text && _bpResponse.errors[0].text !== "") {
                    setSaveMsg(_bpResponse.errors[0].text);
                }
            }
            setSnackbarOpen(true);
            setBackdropOpen(false);
        }
    }, [_bpResponse]);

    useEffect(() => {
        console.log("isAccountValid = " + isAccountValid);
        console.log("isAddressValid = " + isAddressValid);
        console.log("isContactValid = " + isContactValid);
    }, [isAccountValid, isAddressValid, isContactValid])

    const closeSnackbarHandler = () => {
        setSnackbarOpen(false);
        if (_bpResponse.success) {
            navigate(-1);
            dispatch(getAuthentication());
        }
        dispatch(resetBpResponse());
    }


    useEffect(() => {
        if (tab) {
            setActiveTab(tab);
        }
    }, [tab])

    useEffect(() => {
        setHasIbanPayment(_paymentTypes.find(pt => pt.ibanPayment));
    }, [_paymentTypes]);

    const handleChange = (event, newValue) => {
        changeTab(newValue);
        if (newValue === 'account') {
            setAddressTabDisabled(true);
            setPaymentTabDisabled(true);
        } else if (newValue === 'address') {
            setPaymentTabDisabled(true);
        }
    };

    const mailAddressChanged = () => {
        let a = JSON.stringify(modified?.legalEntity?.mailAddresses[0]);
        //      let b = JSON.stringify(_businessPartner.legalEntity.mailAddresses[0]);
        //      setMailAddressDirty(a !== b);
    };

    const accountChanged = () => {
        //       const org = { name: _authentication.userName, password: '' };
        let a = JSON.stringify(modified?.legalEntity.credentials);
        //       let b = JSON.stringify(org);
        //       setAccountDirty(a !== b);
    };

    const contactChanged = () => {
        let a = JSON.stringify(modified?.legalEntity?.netAddresses[0]);
        //let b = JSON.stringify(_businessPartner.legalEntity.netAddresses[0]);
        //setContactDirty(a !== b);
    };

    const paymentChanged = () => {
        let a = JSON.stringify(modified?.commercialProps[0]);
        //       let b = JSON.stringify(_businessPartner.commercialProps[0]);
        //       setPaymentDirty(a !== b);
    };
    function changeTab(tab) {
        // navigate(`/webstore/app/customer/${tab}`);
        //       dispatch(setCustomerTab(tab));
        setActiveTab(tab);
    }

    const closeHandler = () => {
        navigate(-1);
    };

    const nextTabAccount = () => {
        console.log("isAccountValid = " + isAccountValid);
        if (isAccountValid) {
            setActiveTab('address');
            setAddressTabDisabled(false);
        } else {
            refAccount.current.showErrors();
        }
    }

    const nextTabAddress = () => {
        console.log("isAddressValid = " + isAddressValid);
        console.log("isContactValid = " + isContactValid);
        if (isAddressValid && isContactValid) {
            setActiveTab('payment');
            setPaymentTabDisabled(false);
        } else {
            refAddress.current.showErrors();
            refContact.current.showErrors();
        }
    }

    const backTabAddress = () => {
        setActiveTab(!_isGuestAuthenticated ? 'account' : 'address');
        setAddressTabDisabled(true);
    }

    const backTabPayment = () => {
        setActiveTab('address');
        setPaymentTabDisabled(true);
    }

    const save = () => {
        //   setBackdropOpen(true);
        //   dispatch(saveBusinessPartner(modified, _businessPartner.businessPartnerNo));
        //        setUserData(JSON.parse(JSON.stringify(modified)));
        setBackdropOpen(true);
        if (modified.bankAccounts.length === 0) {
            let ba = { "iban": undefined, "preferred": false };
            modified.bankAccounts.push(ba);
        }
        modified.legalEntity.netAddresses[0].address = modified.legalEntity.credentials.name;
        console.log("save: " + JSON.stringify(modified));
        dispatch(saveBusinessPartner(modified));
    };

    const cancel = () => {
        //   if (isDirty()) {
        //       setDirtyDialogOpen(true);
        //   }
        //   else {
        if (_isGuestAuthenticated) {
            dispatch(logout());
        }
        closeHandler();
        //   }
    };

    const showSaveButton = () => {
        return ('address' === activeTab || 'account' === activeTab || 'payment' === activeTab
           /* || isDirty()*/);
    };
    const faIconStyle = {
        width: '100%',
        height: 30,
        marginLeft: 2,
        paddingRight: 2
    };

    const tabIconStyle = () => {
        return {
            flex: 1,
            width: '100%',
            mt: 0,
            pt: 0.5,
            ml: 1,
            pb: 0,
            pr: 1,
            height: 30,
            '& .MuiButtonBase-root': {
                p: 0,
                maxWidth: 30,
                maxHeight: 30
            }
        }
    };

    const tabButtonStyle = (tabVal) => {
        let tab = activeTab || 'account';
        let transform, borderBottom;
        if (tab && tabVal && tabVal === tab) {
            transform = 'none';
            borderBottom = `solid ${_properties.appPrimaryColor} 3px`
        }
        else {
            transform = 'none';
        };
        return {
            mt: 5,
            pt: 0,
            ml: 0,
            pb: 0,
            minHeight: 50,
            '& .MuiButtonBase-root': {
                p: 2,
                minWidth: 20,
                minHeight: 20
            },
            '& .MuiButtonBase-root-MuiTab-root': {
                p: 2,
                minWidth: 20,
                minHeight: 20
            },
            '& .MuiBadge-root': {
                maxHeight: 20,
                minWidth: 20
            },
            '& .MuiTypography-root': {
                fontSize: 16
            },
            color: _properties.appPrimaryColor,
            textTransform: transform,
            borderBottom: borderBottom,
            borderRadius: 0,
            mb: -1.5
        }
    };


    const tabLabel = (key, dirty = false) => {
        return (<Typography variant="customerTabButton"> {dirty ? '* ' : ''}{t(key)}</Typography>)
    };


    // TabsUnstyled instead of tab: https://stackoverflow.com/questions/74790378/react-mui-tab-component-expected-an-element-type-that-can-hold-a-ref

    const Tab = styled(BaseTab)(
        ({ theme }) => `
  
  border: none;
  display: flex;
  justify-content: center;

  &:hover {
  }

  &:focus {
    color: #fff;
  }

  &.${tabClasses.selected} {
borderColor: transparent transparent ${theme.palette.primary.main} transparent  },

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`);

    /*   const TabsList = styled(BaseTabsList)(
           ({ theme }) => `
     min-width: 400px;
     margin-bottom: 16px;
     display: flex;
     align-items: center;
     justify-content: center;
     align-content: space-between;
     `,
       ); */

    return (
        <>
            {modified && (<Stack direction="column" alignItems="center" spacing={5}>
                <AppHeader></AppHeader>

                <Paper sx={{ width: WIDTH }} elevation={4}>
                    <TabContext value={activeTab}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <TabList sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 },
                                },
                                mt: 0,
                                pt: 0,
                                height: 30

                            }}
                                variant="scrollable"
                                scrollbuttons="auto"
                                allowscrollbuttonsmobile={true}
                                value={activeTab}
                                onChange={handleChange} >
                                {!_isGuestAuthenticated && (<Tab
                                    key={1}
                                    label={tabLabel('customerdata.account.title', false)}
                                    value='account'
                                    iconPosition='start'
                                    icon={<Box sx={tabIconStyle}><AccountBoxIcon /></Box>} sx={tabButtonStyle}
                                >
                                </Tab>
                                )}
                                <Tab
                                    key={2}
                                    label={tabLabel('customerdata.address.title', false)}
                                    value='address'
                                    iconPosition='start'
                                    icon={<Box sx={tabIconStyle}><ContactMailIcon size='xl' /></Box>}
                                    sx={tabButtonStyle}
                                    disabled={addressTabDisabled}
                                >
                                </Tab>
                                {hasIbanPayment && (<Tab
                                    key={3}
                                    label={tabLabel('customerdata.payment.title', false)}
                                    value='payment'
                                    iconPosition='start'
                                    icon={<Box sx={tabIconStyle}><PaymentsIcon size='xl' /></Box>}
                                    sx={tabButtonStyle}
                                    disabled={paymentTabDisabled}
                                >
                                </Tab>)}
                            </TabList>
                            <Divider orientation="vertical" flexItem ></Divider>
                            <Typography sx={{ mr: '1rem', ml: '1rem' }} variant="pageTitleBold">{t('customerdata.home.description')}</Typography>
                        </Stack>
                        <Divider />

                        <Box sx={{ height: height, overflowY: 'scroll' }}>
                            {!_isGuestAuthenticated && (
                                <TabPanel value='account' key='11' sx={{ height: '100%' }}>
                                    <Stack
                                        direction='column'
                                        sx={{ height: '100%', justifyContent: 'space-between', pt: 2, pb: 2 }} >
                                        <Account
                                            credentials={modified?.legalEntity.credentials}
                                            marketingApproval={modified?.marketingApproval}
                                            dirty={accountChanged}
                                            valid={setIsAccountValid}
                                            bpNew={true}
                                            ref={refAccount}>
                                        </Account>
                                        <Stack
                                            direction="row"
                                            spacing={4}
                                            sx={{ width: '100%', justifyContent: 'end', pr: 8 }}
                                        >
                                            <Box >
                                                <Button
                                                    variant="navigationAction"
                                                    onClick={() => { nextTabAccount() }}>{t('creative.buy')}</Button>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </TabPanel>)}
                            <TabPanel key='12' value='address' sx={{ height: '100%' }}>
                                <Stack
                                    direction='column'
                                    sx={{ height: '100%', justifyContent: 'space-between', pt: 2, pb: 2 }} >
                                    <MailAddress
                                        ref={refAddress}
                                        mailAddress={modified.legalEntity.mailAddresses[0]}
                                        legalEntity={modified.legalEntity}
                                        dirty={mailAddressChanged}
                                        bpNew={true}
                                        valid={setIsAddressValid}>
                                    </MailAddress>
                                    <Contact
                                        ref={refContact}
                                        _teleComms={modified.legalEntity.teleComms}
                                        _netAddress={modified.legalEntity.netAddresses[0]}
                                        dirty={contactChanged}
                                        bpNew={true}
                                        valid={setIsContactValid}>
                                    </Contact>
                                    <Box />
                                    <Stack
                                        direction="row"
                                        spacing={4}
                                        sx={{ width: '100%', justifyContent: 'end', pr: 8 }}
                                    >
                                        <Box >
                                            <Button variant="navigationBack"
                                                onClick={() => { backTabAddress() }} >{t('creative.back')}</Button>
                                        </Box>
                                        {hasIbanPayment && (<Box >
                                            <Button
                                                variant="navigationAction"
                                                onClick={() => { nextTabAddress() }}>{t('creative.buy')}</Button>
                                        </Box>)}
                                    </Stack>
                                </Stack>
                            </TabPanel>
                            <TabPanel key='13' value='payment' sx={{ height: '100%' }}>
                                <Stack
                                    direction='column'
                                    sx={{ height: '100%', justifyContent: 'space-between', pt: 2, pb: 2 }} >
                                    <Payment
                                        commercialProps={modified?.commercialProps[0]}
                                        businessPartner={modified}
                                        dirty={paymentChanged}>
                                    </Payment>
                                    <Stack
                                        direction="row"
                                        spacing={4}
                                        sx={{ width: '100%', justifyContent: 'end', pr: 8 }}
                                    >
                                        <Box >
                                            <Button variant="navigationBack"
                                                onClick={() => { backTabPayment() }} >{t('creative.back')}</Button>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </TabPanel>
                        </Box>
                    </TabContext>
                </Paper >

                {/* Buttons bar    */}

                < Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={5}
                    sx={{ width: WIDTH }}
                >
                    <Typography variant="pageTitleBold">{t('customerdata.home.description')}</Typography>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={5}
                    >
                        <Button variant="navigationBack"
                            onClick={cancel}>{t('customerdataPage.close')}
                        </Button>
                        {(showSaveButton()) && (
                            <Button
                                variant="navigationAction"
                                disabled={!((isAccountValid || _isGuestAuthenticated) && isAddressValid && isContactValid)}
                                onClick={save}>
                                {t('customerdataPage.adobt')}
                            </Button>
                        )}
                    </Stack>
                </Stack >
            </Stack >)
            }

            <InfoText />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => {
                    // setSnackbarOpen(false);
                    closeSnackbarHandler();
                }}
                message={saveMsg}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
                onClick={() => {
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );

}

export default Registration;

