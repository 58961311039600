import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import MarketLink from './MarketLink';
import { device, marketsForSelection, properties, selectedMarket } from '../redux/Selectors.js';
import { setMarket, setOffer } from '../redux/Actions.js';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Box, Stack, tabsClasses, Typography } from '@mui/material';
import { TabList } from '@mui/lab';
import { TabContext } from '@mui/lab';
import { Tabs } from '@mui/material';
//import { styled } from '@mui/system';
import { Tab } from '@mui/material';
import { urlIsSvg, readSVG, SVGIcon } from '../common/SvgTools.js';

/**
 * 
 * @returns 
 */
const Desktop = ({ items, value, onMarketSelected }) => {

    // TabsUnstyled instead of tab: https://stackoverflow.com/questions/74790378/react-mui-tab-component-expected-an-element-type-that-can-hold-a-ref
    const [activeTab, setActiveTab] = useState();

    const _selectedMarket = useSelector((state) => selectedMarket(state));

    useEffect(() => {
        setActiveTab('999999');
    }, [])

    useEffect(() => {
        if (value) {
            setActiveTab(String(value));
        } else {
            setActiveTab('999999');
        }
    }, [value]);

    useEffect(() => {
        setActiveTab(_selectedMarket?._id ? _selectedMarket?._id : '999999');
    }, [_selectedMarket])


    const tabButtonStyle = () => {

        return {
            mt: 3,
            pt: 0,
            ml: 0,
            pb: 3,
            minHeight: 50,
            '& .MuiButtonBase-root': {
                p: 2,
                minWidth: 20,
                minHeight: 20
            },
            '& .MuiButtonBase-root-MuiTab-root': {
                p: 2,
                minWidth: 20,
                minHeight: 20,
            },
            '& .MuiBadge-root': {
                maxHeight: 20,
                minWidth: 20,
            }
        }
    };

    return (<>
        <Tabs>
            <TabContext value={activeTab !== undefined ? activeTab : "999999"}>
                <TabList sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                    mt: 0,
                    pt: 0,
                    height: 30

                }}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile={true}
                    value={activeTab !== undefined ? activeTab : "999999"}
                    onChange={onMarketSelected}
                >
                    {items.map(e =>
                        <Tab
                            key={e._id}
                            label={e.name}
                            value={e._id !== undefined ? String(e._id) : '999999'}
                            iconPosition='start'
                            sx={tabButtonStyle}
                            //slots={{ root: MarketLink }}
                            component={MarketLink}
                            market={e}
                            selectedMarketId={value}
                            marketAction={onMarketSelected}
                        >
                        </Tab>
                    )}
                </TabList>
            </TabContext>
        </Tabs>
    </>
    );
};

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const MarketIcon = ({ market, selected }) => {

    const href = selected && selected._id === market._id ? market._links.selectedMarketIcon.href : market._links.marketIcon.href;

    return market ?
        (<img style={{ height: "2rem" }} src={href} alt={market.name}></img>)
        :
        (<></>);
};

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const MarketSvgIcon = ({ market, selected, properties }) => {

    const color = selected && selected._id === market._id ? properties.appBackgroundColor : properties.appPrimaryColor;
    market.svg.fill = color;
    market.svg.name = market.name;

    const MSvgIcon = (props) => {
        //       return <></>
        return <SVGIcon svg={market.svg} {...props} />;
    }

    return market ?
        //    (<img style={{ height: "2rem", fill: color }} src={href} alt={market.name}></img>)
        (<MSvgIcon sx={{ fontSize: "2rem" }} />)
        :
        (<></>);
};

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const Mobile = ({ items, selected, onMarketSelected, properties }) => {

    const [itemsWithType, setItemsWithType] = useState(JSON.parse(JSON.stringify(items)));

    useEffect(() => {
        createMarketList(items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);


    let list = [];
    async function createMarketList(items) {
        let l = JSON.parse(JSON.stringify(items));
        for await (let item of l) {
            let isSVG = await urlIsSvg(item._links.marketIcon.href);
            if (isSVG === true) {
                item.type = 'svg';
                let svg = await readSVG(item._links.marketIcon.href);
                item.svg = svg;
            } else {
                item.type = 'png';
            }
            list.push(item);
        }
        setItemsWithType(list);
    }





    const marketStyle = market => {
        let c, bc;
        if (selected && selected._id === market._id) {
            c = properties.appBackgroundColor;
            bc = properties.appPrimaryColor;
        }
        else {
            c = properties.appPrimaryColor;
            bc = properties.appBackgroundColor;
        }

        return (
            {
                padding: '0.2rem',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                color: c,
                backgroundColor: bc,
                border: '1px solid',
                borderRadius: '10px',
                cursor: 'pointer'
            }
        );
    };

    const marketTypographyStyle = market => {
        return (
            {
                color: selected && selected._id === market._id ? properties.appBackgroundColor : properties.appPrimaryColor,
                fontSize: 28
            }
        );
    }

    return (
        <Stack direction="column" spacing={6}>
            {itemsWithType?.map(item =>
                <Box
                    key={item._id}
                    sx={marketStyle(item)}
                    onClick={(e) => onMarketSelected(e, item._id)}
                >
                    <Stack spacing={5} direction="row" alignItems="center" justifyContent="left">
                        {item.type === "png" && (<MarketIcon market={item} selected={selected}></MarketIcon>)}
                        {item.type === "svg" && (<MarketSvgIcon market={item} selected={selected} properties={properties}></MarketSvgIcon>)}
                        <Typography
                            variant='marketPickerTitle'
                            sx={marketTypographyStyle(item)}
                        >{item.name}
                        </Typography>
                    </Stack>
                </Box>
            )}
        </Stack >
    );

};



/**
 * 
 * @returns 
 */
export const MarketPicker = ({ next }) => {

    const ID_ALLMARKETS = 999999;
    const dispatch = useDispatch();
    const _device = useSelector((state) => device(state));
    const _markets = useSelector((state) => marketsForSelection(state));
    const _properties = useSelector((state) => properties(state));
    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const [value, setValue] = useState(_selectedMarket?._id || ID_ALLMARKETS);
    //   const value = _selectedMarket?._id || ID_ALLMARKETS;

    useEffect(() => {
        setValue(_selectedMarket?._id ? _selectedMarket?._id : ID_ALLMARKETS);
    }, [_selectedMarket, _markets])

    const onMarketSelected = (e, id) => {
        const marketId = id ? id : e ? e.target ? e.target.id : Number(e._id) : null;
        dispatch(setMarket(marketId));
        if (ID_ALLMARKETS === marketId) {
            dispatch(setOffer());
        }
        if (next) {
            next();
        }

    };

    if ('desktop' === _device) {
        return <Desktop items={_markets} value={value} onMarketSelected={onMarketSelected} selected={_selectedMarket} properties={_properties}></Desktop>
    }
    else if ('mobile' === _device) {
        return <Mobile items={_markets.filter(e => e._id !== ID_ALLMARKETS)} value={value} onMarketSelected={onMarketSelected} selected={_selectedMarket} properties={_properties}></Mobile>
    }
    else {
        return <></>
    }

}

export default MarketPicker; 