import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Box, Button, Divider, FormControlLabel, Grid, Checkbox, Stack, Typography, Alert,
    Dialog, DialogActions, DialogContent, DialogContentText, Skeleton
} from '@mui/material';
import {
    editionDisplayNames, epaymentConfirmed,
    isBusinessPartnerAuthenticated, properties, bpForModify, orderPriceResponse,
    selectedMarket, selectedOffer, selectedTemplate, fullOrder, isTermsAndConditionsConfirmed,
    orderToEdit, userName, orderResponse, customerType, preferredBankAccount, selectedPaymentMethod,
    selectedPaymentType, businessPartner, selectedEditionPart, desiredDate, orderComplete
} from '../../redux/Selectors.js';
import {
    buy, confirmTermsAndConditions, updateOrder, resetOrder, setBankAccount, initOrder, setBpForModify, setEPayment, confirmEPayment,
} from '../../redux/Actions';
import format from 'date-fns/format';
import i18n, { localeMap, formatMap } from '../../configuration/i18n';
import AppHeader from '../../components/AppHeader';
import Preview from '../../components/Preview';
import SelectedMarket from '../components/SelectedMarket.jsx';
import OfferPicker from '../../components/common/OfferPicker.jsx';
import { DesiredDate } from '../components/DesiredDate';
import { EditionPartHierarchieSummary as EditionPartHierarchie } from '../../components/order/EditionPartHierarchie';
import { OrderLoginCard } from '../../components/order/OrderLoginCard';
import NavigationBar from '../components/NavigationBar';
import { EditIcon } from '../../common/widgetTools.js';
import { OrderProgressDialog } from '../../components/order/OrderProgressDialog.jsx';
import { OrderConfirmationDialog } from '../components/OrderConfirmationDialog.jsx';
import { OrderFailureDialog } from '../components/OrderFailureDialog.jsx';
import { DEVICE_MOBILE, getPage } from '../../common/navigationTools.js';
import PriceArea from '../../components/common/PriceArea.jsx';
import InfoText from '../../components/InfoText.jsx';
import { openPaymentWindow, PaymentProvider } from '../../frames/PaymentProvider';

/**
 * @returns 
 */
export const Order = () => {

    const windowHeight = window.innerHeight;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const _editionDisplayNames = useSelector((state) => editionDisplayNames(state));
    const _isBusinessPartnerAuthenticated = useSelector((state) => isBusinessPartnerAuthenticated(state));
    const _orderPriceResponse = useSelector((state) => orderPriceResponse(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _customerType = useSelector((state) => customerType(state));
    const _fullOrder = useSelector((state) => fullOrder(state));
    const _isTermsAndConditionsConfirmed = useSelector((state) => isTermsAndConditionsConfirmed(state));
    const _orderToEdit = useSelector((state) => orderToEdit(state));
    //   const _isGuestAuthenticated = useSelector((state) => isGuestAuthenticated(state));
    //    const _isUncommittedBP = useSelector((state) => isUncommittedBP(state));
    const _userName = useSelector((state) => userName(state));
    const _orderResponse = useSelector((state) => orderResponse(state));
    const _preferredBankAccount = useSelector((state) => preferredBankAccount(state));
    const _selectedPaymentMethod = useSelector((state) => selectedPaymentMethod(state));
    const _selectedPaymentType = useSelector((state) => selectedPaymentType(state));
    const _businessPartner = useSelector((state) => businessPartner(state));
    const _selectedEditionPart = useSelector((state) => selectedEditionPart(state));
    const _desiredDate = useSelector((state) => desiredDate(state));
    const _bpForModify = useSelector((state) => bpForModify(state));
    const _epaymentConfirmed = useSelector((state) => epaymentConfirmed(state));


    const [displayDate, setDisplayDate] = useState('');
    const [displayDeadline, setDisplayDeadline] = useState('');
    // terms and conditions checkbox 
    const [termsAndConditionsConfirmed, setTermsAndConditionsConfirmed] = useState(false);
    const [termsAndConditionsConfirmedOpen, setTermsAndConditionsConfirmedOpen] = React.useState(false);
    const [termsAndConditionsRequired, setTermsAndConditionsRequired] = React.useState(false);
    const [progressOpen, setProgressOpen] = React.useState(false);
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);
    const [failureMsgOpen, setFailureMsgOpen] = React.useState(false);
    const [confirmedOrder, setConfirmedOrder] = useState(null);
    const [dataComplete, setDataComplete] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(_selectedPaymentMethod);

    const DEVICE = 'mobile';
    const BACK = getPage('schedule', DEVICE);
    const LABEL_BACK = 'm.navigation.toSchedule';


    useEffect(() => {
        window.addEventListener('message', listenPayment);
        return (() => {
            window.removeEventListener('message', listenPayment);
        });
    }, []);

    useEffect(() => {
        window.scrollBy({ left: 0, top: -window.innerHeight * 2, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (_businessPartner) {
            const bankaccounts = _businessPartner?.bankAccounts;
            let pBankAccount = {};
            let pMethod = 'paymentDirectDebit';
            if (_preferredBankAccount !== undefined) {
                pBankAccount = _preferredBankAccount;
            } else {
                pBankAccount = bankaccounts?.length > 0 ? bankaccounts[0] : {};
            }
            if (_selectedPaymentMethod !== undefined) {
                pMethod = _selectedPaymentMethod;
            }
            //       console.log("bankAccount = " + pBankAccount?.ibanHidden);
            //       console.log("paymentMethod = " + pMethod);
            //           dispatch(setBankAccount(pBankAccount));
            setBankAccount(pBankAccount);
            setPaymentMethod(pMethod);
        } else {
            //        console.log("no businessPartner -> paymentMethod = undefined");
            setPaymentMethod(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_preferredBankAccount, _businessPartner, _selectedPaymentMethod]);

    useEffect(() => {
        if (_orderPriceResponse && _orderPriceResponse.value) {
            var orderPrice = _orderPriceResponse.value;
            var displayDate = orderPrice?.effectiveDates?.map(eDate => format(new Date(eDate), formatMap[i18n.language], { locale: localeMap[i18n.language] }))
                .join(" \u2022 ");
            setDisplayDate(displayDate);
            //         console.log(orderPrice?.deadline);
            var deadline = orderPrice?.deadline;
            setDisplayDeadline(deadline ? format(new Date(deadline), formatMap[i18n.language], { locale: localeMap[i18n.language] }) : '');
        }
    }, [_orderPriceResponse]);

    useEffect(() => {
        if (_orderResponse) {
            if (_orderResponse.success) {
                let co = _orderResponse.value;
                setProgressOpen(false);
                setConfirmedOrder(co);
                if (co.orderPayment && !_epaymentConfirmed) {
                    setOrderPayment(co.orderPayment);
                    processPayment(co.orderPayment);
                }
                else {
                    setConfirmationOpen(true);
                    dispatch(initOrder(_customerType));
                }
            }
            else {
                console.log("orderFailure: " + JSON.stringify(_orderResponse));
                setProgressOpen(false);
                setFailureMsgOpen(true);
            }
        }
    }, [_orderResponse]);

    // --------------------------------------------------
    // terms and conditions
    // --------------------------------------------------

    const TermsAndConditionDialog = () => {
        return (<Dialog
            open={termsAndConditionsConfirmedOpen}
            onClose={termsAndConditionsConfirmedClosed}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Alert severity="warning">
            </Alert>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('order.confirmCheckbox')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={{ textTransform: 'none' }} variant="navigationAction" onClick={termsAndConditionsConfirmedClosed}>Ok</Button>
            </DialogActions>
        </Dialog>)
    };
    const termsAndConditionsConfirmedClosed = () => {
        setTermsAndConditionsRequired(true);
        setTermsAndConditionsConfirmedOpen(false);
    };
    useEffect(() => {
        if (_isTermsAndConditionsConfirmed) {
            setTermsAndConditionsRequired(false);
        }

    }, [_isTermsAndConditionsConfirmed]);

    useEffect(() => {
        if (_selectedOffer && _selectedEditionPart && _desiredDate && _orderPriceResponse?.value?.effectiveDates?.length > 0) {
            setDataComplete(true);
        } else {
            setDataComplete(false);
        }
    }, [_selectedOffer, _selectedEditionPart, _desiredDate, _orderPriceResponse]);

    const handleEditPayment = () => {
        //     console.log("handleEditPayment ...");
        //     console.log(_bpForModify);
        //     console.log(_businessPartner);
        if (!_bpForModify) {
            dispatch(setBpForModify(_businessPartner));
        }
        navigate(getPage('register-payment', DEVICE_MOBILE), { state: { saveChangesInOrder: true } });
    }

    const handleBuy = () => {
        if (!_isTermsAndConditionsConfirmed) {
            setTermsAndConditionsConfirmedOpen(true);
            return;
        }
        // mit desktop var. konsolidieren
        //      console.log('handleBuy');
        //      console.log(_fullOrder);
        dispatch(buy(_fullOrder));
        setProgressOpen(true);
    };

    const handleSave = () => {
        if (!_isTermsAndConditionsConfirmed) {
            setTermsAndConditionsConfirmedOpen(true);
            return;
        }
        var upOrder = JSON.parse(JSON.stringify(_fullOrder));
        upOrder.orderCode = _orderToEdit.orderCode;
        dispatch(updateOrder(upOrder, _orderToEdit.orderCode));
        setProgressOpen(true);
    }

    const Action2Button = () => {
   /*     if ((!(_isGuestAuthenticated || _isUncommittedBP) && _features?.ASE_1021) && _isWSS) {
            return <Button variant="navigationAction" onClick={() => { handleSaveDraft(); }}>{t('order.saveDraft')}</Button>
        }
        else */if (!_orderToEdit) {
            return <Button sx={{ textTransform: 'uppercase', width: '100%' }} disabled={!dataComplete} variant={'contained'} onClick={() => { handleBuy(); }}>
                {t(!_selectedMarket.freeOfCharge ? 'order.book' : 'order.bookFree')}
            </Button>;
        }
        else {
            return <Button variant="navigationAction" onClick={() => { handleSave(); }}>{t('order.saveMotif')}</Button>
        }
    };

    const handleProgressClosed = () => {
        dispatch(resetOrder());
        navigate(getPage('start', DEVICE_MOBILE));
    };

    const handleOrderFailureClosed = () => {
        setProgressOpen(false);
        setFailureMsgOpen(false);
    }

    const back = () => {
        navigate(BACK);
    };

    const handleTermsAndConditionsConfirmedChange = (e) => {
        setTermsAndConditionsConfirmed(e.target.checked);
        dispatch(confirmTermsAndConditions(e.target.checked));
    };
    const TermsAndConditions = () => {
        return (
            <FormControlLabel
                labelPlacement="end"
                label={<Typography
                    variant='mobileTermsAndConditions'
                    dangerouslySetInnerHTML={{ __html: t('order.checkbox1') }}></Typography>}
                control={
                    <Checkbox
                        sx={{ color: termsAndConditionsRequired ? 'error.main' : 'primary.main' }}
                        checked={termsAndConditionsConfirmed}
                        onChange={handleTermsAndConditionsConfirmedChange}
                        size="small" />
                }
            />);
    };


    // --------------------------------------------------
    // payment handling
    // --------------------------------------------------
    const [paymentOpen, setPaymentOpen] = React.useState(false);
    const [paymentWindow, setPaymentWindow] = React.useState(null);
    const [paymentStatus, setPaymentStatus] = React.useState(null);
    const [paymentFailureOpen, setPaymentFailureOpen] = React.useState(false);
    const [orderPayment, setOrderPayment] = React.useState(null);

    const pwRef = useRef(null);  // ? wird nicht wirklich verwendet
    const timerRef = useRef(null);

    const listenPayment = event => {
        if (event.data.paymentStatus) {
            setPaymentStatus(event.data.paymentStatus);
        }
        else if ('paymentCancelled' === event.data ||
            'paymentError' === event.data ||
            'paymentConfirmed' === event.data) {
            setPaymentStatus(event.data);
        }
    };

    const processPayment = payment => {
        dispatch(setEPayment(payment));
        switch (payment.method) {
            // https://docs.datatrans.ch/docs/redirect-lightbox
            // Avoid embedding our Redirect or Lightbox integration in an iframe
            // To avoid issues with our payment forms, avoid embedding them via an iFrame. 
            // By embedding the Redirect or Lightbox integration in an iframe, you may break some payment flows.
            // case 'datatrans':
            //     setPaymentOpen(true);
            //     break;
            // case 'paypal':
            //     setPaymentOpen(true);
            //     break;
            // case 'payunity':
            //     setPaymentOpen(true);
            //     break;
            default:
                if (pwRef !== null) {
                    pwRef.current = openPaymentWindow(payment);
                    setPaymentWindow(pwRef.current);
                    timerRef.current = setInterval(() => {
                        if (pwRef?.current !== null && pwRef.current.closed) {
                            pwRef.current = null;
                            setPaymentWindow(null);
                            clearInterval(timerRef.current);
                        }
                    }, 500);
                }
                break;
        }
    }

    /**
     * process payment result
     */
    useEffect(() => {
        if (paymentStatus) {
            if (paymentWindow && !paymentWindow.closed) {
                paymentWindow.close();
                setPaymentWindow(null);
            }
            else {
                setPaymentOpen(false);
            }
            switch (paymentStatus.action) {
                case 'paymentConfirmed':
                    setConfirmationOpen(true);
                    dispatch(initOrder(_customerType));
                    dispatch(confirmEPayment());
                    setPaymentStatus(null);
                    break;
                case 'paymentCancelled':
                    break;
                case 'paymentError':
                    setPaymentFailureOpen(true);
                    break;
                default:
                    console.log(`handlePaymentClosed() event=${paymentStatus}`);
            }
        }
    }, [paymentStatus]);


    const Payment = () => {
        const { t } = useTranslation();

        if (!paymentMethod) {
            return (<Typography variant="mobilePageLabel">
                {t("m.order.payment.login")}
            </Typography>);
        }

        return (
            <>
                {paymentMethod !== 'paymentDirectDebit' && (
                    <>

                        <Typography variant="mobilePageLabel">
                            {_selectedPaymentType?.name}
                        </Typography>
                        <Button onClick={() => handleEditPayment()}>
                            <EditIcon size='lg' />
                        </Button>
                    </>
                )}
                {paymentMethod === 'paymentDirectDebit' && (
                    <>
                        <Stack direction="row">
                            <Box>
                                <Typography variant="mobilePageLabel">
                                    {_preferredBankAccount?.bankName}
                                </Typography>

                                <Typography variant="mobilePageLabel">
                                    {_preferredBankAccount?.ibanHidden}
                                </Typography>
                            </Box>
                            <Button onClick={() => handleEditPayment()}>
                                <EditIcon size='lg' />
                            </Button>
                        </Stack>
                    </>
                )}

            </>
        );
    }

    const Navigation = () => {
        return (
            <Box sx={{
                marginTop: '1rem',
                padding: '1rem',
                // backgroundColor: _properties.appPrimaryColor,
                //                position: 'fixed',
                //                bottom: 'auto',
                //              left: 0,
                width: '90%'
            }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    {_isBusinessPartnerAuthenticated && (<>

                        <Action2Button />
                    </>
                    )}
                    {!_isBusinessPartnerAuthenticated && (<>
                        <OrderLoginCard />
                    </>
                    )}
                </Stack>
            </Box>);

    };

    const styleFormStack = () => {
        return (
            {
                mt: 8,
                width: '90%',
                minHeight: windowHeight - 150,
                '& .MuiInputBase-root': { mt: 3, mb: 8 },
                '& .MuiInputLabel-root': { zIndex: 0 }  // WS-8348 Fragmente der Order-Page bei Anmeldung sichtbar
            });
    }

    return (
        <Stack direction="column" alignItems="center" justifyContent="flexStart" spacing={8}>
            <AppHeader></AppHeader>

            <Stack sx={styleFormStack} direction="column" alignItems="left" spacing={5}>
                <SelectedMarket market={_selectedMarket}></SelectedMarket>
                <Divider />


                <Typography variant="mobilePageSubTitle">{t('m.order.preview')}:</Typography>
                <Stack direction="row">
                    <Preview></Preview>
                    <Button onClick={() => navigate(getPage('creative', DEVICE_MOBILE))}>
                        <EditIcon size='xl' />
                    </Button>
                </Stack>

                <Typography sx={{ pb: 5, pt: 6 }} variant="mobilePageSubTitle">{t('m.order.result')}:</Typography>

                <EditionPartHierarchie variant="linkToSchedulePage" />

                <OfferPicker variant="linkToOfferPage"></OfferPicker>

                <Grid container >
                    <Grid item xs={5} >
                        <Typography variant="mobilePageLabel">
                            {_editionDisplayNames?.includes(' \u2022 ') ? t('m.order.editions') : t('m.order.edition')}:
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography variant="mobilePageLabel">
                            {_editionDisplayNames ? _editionDisplayNames : ''}
                        </Typography>
                    </Grid>
                </Grid>

                <Box sx={{ pt: 10 }}>
                    <DesiredDate variant="linkToSchedulePage" />
                </Box>

                <Grid container>
                    <Grid item xs={12} >
                        <PriceArea />
                    </Grid>
                    <Grid item xs={5} >
                        <Typography variant="mobilePageLabel">
                            {t('m.order.payment')}:
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Payment />
                    </Grid>
                </Grid>
                <TermsAndConditions />
            </Stack>
            <TermsAndConditionDialog />
            <OrderProgressDialog open={progressOpen}></OrderProgressDialog>
            <OrderConfirmationDialog
                handleClose={handleProgressClosed}
                open={confirmationOpen}
                order={confirmedOrder}
                userName={_userName}
                displayDate={displayDate}
            />
            <OrderFailureDialog handleClose={handleOrderFailureClosed} open={failureMsgOpen} />
            <Navigation></Navigation>
            <NavigationBar
                back={{ clicked: back, label: LABEL_BACK }}>
            </NavigationBar>
            <InfoText />
        </Stack >
    );
}

export default Order;
