
import { createSelector } from 'reselect';

import { isMarketValidForDevice, mapMotifCriterionValues, privacyFromLE, cutCatchword, tomorrow } from '../common/tools';
import { getPage, DEVICE_MOBILE } from '../common/navigationTools';

import { parseMotifStructure } from '../common/tools';

function trace(s, x) {
	//	console.log(`select ${x ? '---' : '+++'} ${s}`);
}

const getAuthentication = state => state.authentication;
const getCommon = state => state.common;
const getApi = state => state.api;
const getBusinessPartner = state => state.BP;
const getBusinessPartnerOrders = state => state.businessPartnerOrdersReducer;
const getRegistration = state => state.registration;
const getMasterData = state => state.masterData;
const getOrder = state => state.order;
const getOrderResults = state => state.orderResults;
const getCreative = state => state.creative;
const getUI = state => state.ui;
//const getUser = state => state.user.user;
const getTracking = state => state.tracking;
const getcheCkPasswordAllowed = state => state.checkPasswordAllowed;
const getCheckUserNameValid = state => state.checkUserName;
const getPerfLog = state => state.perfLog;
const getStylo = state => state.stylo;


export const perflogBaseEvent = createSelector([getPerfLog, getAuthentication, getBusinessPartner],
	(m, a, b) => {
		if (m.perflogBaseEvent) {
			let pbe = m.perflogBaseEvent;
			if (b !== null && b.businessPartner !== null && b.businessPartner.legalEntity !== null && b.businessPartner.legalEntity.mailAddress !== null) pbe.userName = b.businessPartner.legalEntity.mailAddress.name + ", " + b.businessPartner.businessPartnerNo;
			else if (a !== null && a.userName !== null) pbe.userName = a.userName;
			return pbe;
		}
		else return null;
	}
);
export const serviceDependendPerfLogs = createSelector([getPerfLog],
	(m) => {
		if (m.serviceDependendPerfLogs) return m.serviceDependendPerfLogs;
		else return null;
	}
);
export const appTitle = createSelector([getCommon], r => r.properties ? r.properties.appTitle : null);

export const features = createSelector([getCommon], (common) => {
	trace('features');
	return common.features;
});

export const checkedPasswordValid = createSelector([getcheCkPasswordAllowed], r => r.checkPasswordAllowed);
export const checkedUserNameValid = createSelector([getCheckUserNameValid], r => r.userNameValid);

export const authentication = state => {
	trace('authentication', 'x');
	return state.authentication.authentication;
};

export const authRefresh = state => state.authentication.authRefresh;

export const authenticationResponse = createSelector([getAuthentication], r => r.authenticationResponse);
export const passwordReminderResponse = createSelector([getAuthentication], r => r.passwordReminderResponse);
export const isAuthenticated = createSelector([getAuthentication],
	(r) => {
		if (r.authentication) {
			return Boolean(r.authentication.userName &&
				(r.authentication.roles.includes('ADMIN') ||
					r.authentication.roles.includes('USER') ||
					r.authentication.roles.includes('GUEST')));
		}
	}
);
export const isAdminAuthenticated = createSelector([getAuthentication],
	(r) => {
		trace('isAdminAuthenticated');
		if (r.authentication) {
			return Boolean(r.authentication && r.authentication.roles && r.authentication.roles.includes('ADMIN'));
		}
		else {
			return false;
		}
	}
);

export const isGuestAuthenticated = createSelector([getAuthentication], r => {
	return r.authentication && r.authentication.roles && -1 !== r.authentication.roles.findIndex(role => 'GUEST' === role)
});

export const isOAuthAuthenticated = createSelector([getAuthentication], r => r.authentication && r.authentication.roles && -1 !== r.authentication.roles.findIndex(role => 'SOCIAL' === role));

export const cicLogin = createSelector([getAuthentication], r => r.CIC);

export const isCicAuthenticated = createSelector([getAuthentication],
	(r) => {
		trace('isCicAuthenticated');
		if (r.authentication) {
			//			return Boolean(r.authentication && r.authentication.roles && r.authentication.roles.includes('CIC'));
			// nur für Test mit CIC bis WSS-Anmeldung möglich
			return Boolean(r.CIC);
		}
	}
);

export const isBusinessPartnerAuthenticated = createSelector([getAuthentication],
	(r) => {
		return Boolean(r.authentication && r.authentication.businessPartnerNumber);
	}
);

export const isGuestAuthenticationOnly = createSelector([getCommon],
	(common) => {
		return Boolean(!common.oAuthProvider && common.features && common.features.OAUTH2_AUTHENTICATION.active);
	}
);

export const isSsoAuthentication = createSelector([getAuthentication], (r) => {
	return Boolean(r.authentication && r.authentication.roles && r.authentication.roles.includes('SOCIAL'));
});

export const isCreateDeeplinks = createSelector([getCommon, isAdminAuthenticated], (common, isAdminAuthenticated) => {
	return isAdminAuthenticated || common.deepLinks;
});

export const businessPartner = state => {
	return state.BP.businessPartner;
};

export const selectAdvertizersForSelection = createSelector([businessPartner], (businessPartner) => {
	let list = businessPartner?.advertizers;
	if (list) {
		list.unshift({ "businessPartnerNo": "", "name": " " });
	}
	return list;
});

export const selectAdvertizers = createSelector([businessPartner], (businessPartner) => {
	return businessPartner?.advertizers;
});

export const selectHasAdvertizers = createSelector([businessPartner], (businessPartner) => {
	return Boolean(businessPartner?.advertizers.length && !(businessPartner?.advertizers.length === 1 && businessPartner?.advertizers[0].name === ' '));
});

export const userName = createSelector([getAuthentication, businessPartner],
	(authentication, businessPartner) => {
		trace('userName');
		let userName = '';
		if (businessPartner && businessPartner.legalEntity) {
			const le = businessPartner.legalEntity;
			if (le.mailAddresses[0]) {
				if (le.type !== "privatePerson") {
					userName = le.mailAddresses[0].name;
				}
				else {
					if (le.mailAddresses[0].forename) {
						userName = le.mailAddresses[0].forename + ' ' + le.mailAddresses[0].name;
					}
					else {
						userName = le.mailAddresses[0].name;
					}
				}
			}
		}
		else {
			userName = authentication.userName && authentication.roles.indexOf('GUEST') !== 0 ? authentication.userName : '';
		}
		return userName;
	}
);

export const isUncommittedBP = createSelector([businessPartner], businessPartner => {
	trace('isUncommittedBP');
	return businessPartner?.businessPartnerNo && businessPartner?.businessPartnerNo === 'BpNoUndefined';
});

export const businessPartnerHasSalesReps = createSelector([businessPartner, getCommon], (businessPartner, features) => {
	return Boolean(businessPartner &&
		businessPartner.commissionBeneficirarys &&
		businessPartner.commissionBeneficirarys[0] &&
		businessPartner.commissionBeneficirarys[0].salesRepNo.length > 0 &&
		features.SALES_REPS &&
		features.SALES_REPS.active);
});
export const salesReps = createSelector([getBusinessPartner], r => r.salesReps);
export const salesRep = createSelector([salesReps], salesReps => {
	let salesRep = null;
	if (salesReps && salesReps.length > 0) {
		salesRep = salesReps[0];
		salesRep.mobile = salesRep.legalEntity.teleComms.filter(slr => slr.mobilityType === "mobile");
		salesRep.fixed = salesRep.legalEntity.teleComms.filter(slr => slr.mobilityType === "fixed");
	}
	return salesRep
});

export const bpForModify = createSelector([getBusinessPartner], BP => BP.bpForModify);

export const bpResponse = createSelector([getBusinessPartner], BP => BP.bpResponse);

export const bankAccountValidation = createSelector([getBusinessPartner], (BP) => BP.bankAccountValidation);

export const servicePackages = createSelector([getBusinessPartner],
	(BP) => {
		return BP.businessPartner ? BP.businessPartner.servicePackages : null;
	}
);

export const servicePackagesCount = createSelector([getBusinessPartner],
	(BP) => {
		return BP.businessPartner && BP.businessPartner.servicePackages ? BP.businessPartner.servicePackages.length : 0;
	}
);

export const customerType = (state) => {
	return state.common.customerType || state.common.defaults.customer;
}
export const customerTypeChecked = createSelector([getMasterData], masterData => {
	return Boolean(masterData.customerType === 'business');
});

export const orgMarketingApproval = createSelector([getCommon],
	(common) => common.orgMarketingApproval
);

export const defaults = createSelector([getCommon],
	(common) => common.defaults
);

export const services = createSelector([getApi], (api) => {
	return api.services.common;
});
export const properties = createSelector([getCommon], common => common.properties);

export const device = createSelector([properties], (props) => {
	trace('device');

	if (!props || Object.keys(props).length === 0) {
		return null;
	}

	const urlParams = new URLSearchParams(window.location.search);
	if (urlParams.has('mobile') && 'true' === urlParams.get('mobile')) {
		return 'mobile'
	}
	else if (Math.min(window.screen.width, window.innerWidth) < props.minimumWidth) {
		return 'mini';
	}
	else if (Math.min(window.screen.width, window.innerWidth) < props.bazaarWebstore) {
		return 'mobile';
	}
	else {
		return 'desktop';
	}
});

export const editionIds = state => {
	trace('editionIds', 'x');
	return state.masterData.editionIds;
};

/*export const allTemplates = createSelector([templates, customerType],
	(templates, customerType) => {
	trace('allTemplates', 'x');
	return templates;
});*/


export const allTemplates = state => {
	trace('allTemplates ' + (state.masterData.templates ? state.masterData.templates[0]?.marketIds[0] : ''), 'x');
	return state.masterData.templates;
};

export const offeredTemplates = createSelector([allTemplates, customerType, editionIds],
	(allTemplates, customerType, editionIds) => {
		trace('offeredTemplates');
		if (allTemplates) {
			let filtered = allTemplates;
			if (customerType) {
				filtered = filtered.filter(e => e.legalEntityTypes.includes(customerType.toUpperCase()));
			}
			filtered = filtered.filter(e => e.offerIds.length > 0);

			if (editionIds && editionIds.length) {
				const subset = [];
				filtered = filtered.filter(e => e.editionIds.length > 0);
				editionIds.forEach(id => {
					filtered.forEach(t => {
						if (t.editionIds.includes(id)) {
							subset.push(t);
						}
					});
				});
				filtered = subset;
			}

			return filtered.sort((a, b) => a.ordering - b.ordering);
		}
		else {
			return null;
		}
	}
);

const allMarkets = state => {
	trace('allMarkets', 'x');
	return state.masterData.markets;
};

export const markets = createSelector([allMarkets, customerType, device, offeredTemplates],
	(allMarkets, customerType, device, templates) => {
		trace('markets');
		let filtered = undefined;
		if (allMarkets && customerType && templates) {
			// 
			filtered = allMarkets.filter(m => isMarketValidForDevice(m, device));
			// WS-2886: Kategorieauswahl / Muster
			let marketIds = [];
			//marketIds = Array.from(new Set(templates.map(t => t.marketIds).flat()));
			// SUP-14184: WebStore lädt keine Kategorien und keine Muster: Array.flat doesn't work under Microsoft Edge or IE11
			//
			templates.forEach((t) => {
				trace("template " + t._id);
				t.marketIds.forEach((m) => {
					if (-1 === marketIds.indexOf(m)) {
						trace("add marketid " + m);
						marketIds.push(m);
					}
				});
			});
			filtered = filtered.filter(e => marketIds.find(i => i === e._id) !== undefined);
			filtered = filtered.filter(e => e.legalEntityTypes.includes(customerType.toUpperCase()));
		}
		return filtered;
	}
);

export const marketsForSelection = createSelector([markets], m => {
	if (m) {
		let all = [{ _id: 999999, shortName: 'ALL_MARKETS', name: 'Alle' }];
		return all.concat(m);
	}
});

export const allOffers = state => {
	trace('allOffers', 'x');
	return state.masterData.offers;
};

export const preSelectedOffer = state => {
	return state.masterData.offerId ? state.masterData.offers.find(e => e._id === state.masterData.offerId) : null;
};

export const selectedTemplate = createSelector([getMasterData, features],
	(masterData, features) => {
		let template = masterData.selectedTemplate;
		// WSS: Default-Rubrik (und ...) am Muster ergeben sich aus Markt-Muster-Zuordnung
		if (masterData.selectedTemplate && masterData.selectedMarket && masterData.selectedMarket.templateDetails) {
			let td = masterData.selectedMarket.templateDetails.find(it => it.templateId === template._id);
			if (td) {
				template.defaultAdEdPartId = td.defaultEditionpartId;
			}
		}
		if (template?.forceAdBox &&
			features?.SUP_39408_PRESET_FORCED_CHIFFRE.active) {
			template.presetAdBox = true;
		}
		return template;
	}
);

export const preSelectedTemplate = (state) => {
	return state.masterData.templateId ? state.masterData.templates.find(e => e._id === state.masterData.templateId) : null;
}

export const orderToRepeat = createSelector([getBusinessPartnerOrders, allOffers], (r, offers) => {
	if (r && r.orderToRepeat && offers) {
		let order = r.orderToRepeat;
		let offer = offers.find(o => o._id === order.offerId);
		if (offer && offer.sapRepeatType) {
			order.sapRepeatType = offer.sapRepeatType;
		}
		return order;
	}
});

export const offers = createSelector([getMasterData, selectedTemplate, isCicAuthenticated, isAdminAuthenticated, customerType, orderToRepeat],
	(masterData, selectedTemplate, isCicAuthenticated, isAdminAuthenticated, customerType, orderToRepeat) => {
		trace('offers');
		let filtered = [];

		// ASE-1116
		if (masterData.offers && orderToRepeat && orderToRepeat.sapRepeatType) {
			return masterData.offers.filter(o => o._id === orderToRepeat.offerId);
		}

		if (masterData.offers && selectedTemplate) {
			filtered = masterData.offers.filter(o => o.templates.includes(selectedTemplate._id));
		}

		// SUP-18944: Ungültiges Angebot wird angezeigt
		filtered = filtered.filter(o => {
			let from, to, now = Date.now();
			from = Date.parse(o.validFrom);
			to = Date.parse(o.validTo);
			return Boolean(from < now && to > now);
		});

		if (customerType) filtered = filtered.filter(e => e[customerType + "Customer"]);
		filtered = filtered.filter(o => isCicAuthenticated ? true : !o.cicAuthenticationOnly);

		if (!isCicAuthenticated) {
			filtered = filtered.filter(o => !o.adminOnly || isAdminAuthenticated || (window.params && o._id === window.params.offer));
		}

		return filtered;
	}
);

export const selectedOffer = createSelector([getMasterData, offers], (r, offers) => {
	trace('selectedOffer');
	return (offers.find(e => r.offer)) || r.selectedOffer || (offers.find(e => e.defaultOffer) || offers[0]);
});

export const selectedMarket = createSelector([getMasterData],
	(masterData) => {
		return masterData.selectedMarket;
	}
);

export const preSelectedMarket = (state) => {
	return state.masterData.preSelectedMarketId ? state.masterData.markets.find(e => e._id === state.masterData.preSelectedMarketId) : null;
}

export const templates = createSelector([features, allTemplates, businessPartner, device, isAdminAuthenticated, isCicAuthenticated, markets, selectedMarket, allOffers, selectedOffer, editionIds, customerType],
	(features, allTemplates, businessPartner, device, isAdminAuthenticated, isCicAuthenticated, markets, selectedMarket, allOffers, selectedOffer, editionIds, customerType) => {
		trace('templates');
		if (features && allTemplates) {
			const now = Date.now();
			let filtered = allTemplates;
			/* ---> führt zu Fehler WS-8326			filtered.forEach(t => { 
							if (markets) {
								t.marketIds = t.marketIds.filter(i => markets.find(m => m._id === i));
							}
						}); */
			if (customerType) {
				filtered = filtered.filter(e => e.legalEntityTypes.includes(customerType.toUpperCase()));
			}
			filtered = filtered.filter(t => t.marketIds.length > 0);
			filtered = filtered.filter(e => !e.recommendedOnly && (e.preferred || selectedMarket));
			if (selectedMarket) {
				filtered = filtered.filter(e => e.marketIds.includes(selectedMarket._id));
			}
			if (allOffers) {

				// WS-6963: Deeplink mit offer-id: nicht buchbares Muster auswählbar
				let offers = allOffers.filter(o => {
					let from = Date.parse(o.validFrom.substring(0, 10));
					let to = Date.parse(o.validTo.substring(0, 10));
					return now >= from && now <= to;
				});

				// WS-6966: Im CIC-Modus auch nicht öffentliche Angebote anzeigen
				if (!isCicAuthenticated) {
					offers = offers.filter(o => !o.adminOnly || isAdminAuthenticated || (window.params && o._id === window.params.offer));
				}

				if (!isCicAuthenticated) {
					offers = offers.filter(o => !o.cicAuthenticationOnly);
				}
				filtered = filtered.filter(e => {
					var valid = false;
					e.offerIds.forEach(oid => {
						if (offers.find(o => o._id === oid)) valid = true;
					});
					return valid;
				});
			}

			// ???????????????
			// if (selectedOffer) {
			// 	filtered = filtered.filter(e => selectedOffer.templates.includes(e._id));
			// }
			// ???????????????

			if (editionIds && editionIds.length) {
				const subset = [];
				filtered = filtered.filter(e => e.editionIds.length > 0);
				editionIds.forEach(id => {
					filtered.forEach(t => {
						if (t.editionIds.includes(id)) {
							subset.push(t);
						}
					});
				});
				filtered = subset;
			}

			// ASE-853: Kundenspezifische Muster
			if (features.ASE_853.active) {
				let filter = (businessPartner, template) => {
					if (template.restrictedToCustomerNumbers && template.restrictedToCustomerNumbers.length) {
						return businessPartner ? template.restrictedToCustomerNumbers.find(cn => cn === businessPartner.businessPartnerNo) : false;
					}
					else {
						return true;
					}
				};
				filtered = filtered.filter(e => filter(businessPartner, e));
			}

			// SUP-15815
			if (device) {
				filtered = filtered.filter(e => {
					if (device === 'desktop' && e.desktopEnabled) {
						return true;
					}
					// ASE-1132: Bazaarmuster bauen ab jetzt immer auf Formularen auf. Ist im Muster kein Formular definiert, dann wird das Muster auch nicht zur Gestaltung angeboten.
					else if (device === 'mobile' && e.mobileEnabled && e.formxName) {
						return true;
					}
					else {
						return false;
					}
				});
			}

			// SUP-19588: Webstore und Gültigkeit von Formaten
			filtered = filtered.filter(e => {
				let from = Date.parse(e.validFrom.substring(0, 10));
				let to = Date.parse(e.validTo.substring(0, 10));
				return now >= from && now <= to;
			});

			// WSS / Für nur über Direct-Link erreichbare, sonst unsichtbare Angebote nur Muster dieses Angebotes anzeigen
			// WSS-6966
			if (!isCicAuthenticated) {
				if (preSelectedOffer && (selectedOffer && selectedOffer.adminOnly)) {
					filtered = filtered.filter(e => {
						return selectedOffer.templates.includes(e._id);
					});
				}
			}


			// test masonry
			// filtered = filtered.filter(e => {
			// 	return e.unitWidth === "COLUMNS" && e.minWidth >= 2;
			// });


			return filtered.sort((a, b) => a.ordering - b.ordering);
			// return filtered.sort((a, b) => { return a.shortName.toUpperCase() < b.shortName.toUpperCase() ? -1 : (a.shortName.toUpperCase() > b.shortName.toUpperCase() ? 1 : 0) });
		}
		else {
			return null;
		}
	}
);

export const designAlternatives = createSelector([getMasterData, customerType],
	(masterData, customerType) => {
		let filtered = masterData.designAlternatives;
		//let filtered = undefined;
		if (customerType && filtered) {
			filtered = filtered.filter(e => {
				return e.legalEntityTypes && e.legalEntityTypes.includes(customerType.toUpperCase());
			});
			filtered.forEach(alternative => {
				let full = masterData.templates.find(template => template._id === alternative._id);
				if (full) {
					alternative._links = full._links;
				}
			});
		}

		return filtered;
	}
);

export const upsellingAlternatives = createSelector([getMasterData, customerType],
	(masterData, customerType) => {
		let filtered = masterData.upsellingAlternatives;
		if (customerType && filtered) {
			filtered = filtered.filter(e => {
				return e.legalEntityTypes && e.legalEntityTypes.includes(customerType.toUpperCase());
			});
			filtered.forEach(alternative => {
				let full = masterData.templates.find(template => template._id === alternative._id);
				if (full) {
					alternative._links = full._links;
				}
			});
		}

		return filtered;
	}
);

export const criteria = createSelector([getMasterData],
	(masterData) => {
		return masterData.criteria;
	}
);

export const components = createSelector([getMasterData],
	(masterData) => {
		return masterData.components;
	}
);

export const offercriterias = createSelector([getMasterData],
	(masterData) => {
		if (masterData && masterData.offercriterias)
			return masterData.offercriterias.filter(oc => oc.collectionEntries.length > 0 || oc.criterionDataType === "Bool");
	}
);

export const ordercriterias = createSelector([getMasterData],
	(masterData) => {
		if (masterData && masterData.offercriterias)
			return masterData.offercriterias.filter(oc => oc.criterionDataType === "String" || oc.criterionDataType === "Bool");
	}
);

export const ordercriteriasbool = createSelector([getMasterData],
	(masterData) => {
		if (masterData && masterData.offercriterias)
			return masterData.offercriterias.filter(oc => oc.criterionDataType === "Bool");
	}
);

export const orderCriterionWithPattern = createSelector([getOrder], order => order.orderCriterionWithPattern);

/*export const orderCriterionWithPattern = createSelector([selectedOffer, ordercriterias, getOrder],
	(offer, ordercriterias, order) => {
		if (offer.criteriaValues) {
			var criteria = offer.criteriaValues.find(c => c.description === 'orderwithpatterncriteria');
			if (!(ordercriterias?.find(x => x.shortName === criteria.shortName
				&& ((x.validFrom != null && new Date(x.validFrom) > Date.now())
					|| (x.validTo != null && new Date(x.validTo) < Date.now())))
			)) {
				if (order?.criterionValues) {
					const criterionValues = order.criterionValues.slice();
					criterionValues.forEach(ce => {
						if (ce.shortName === criteria.shortName) {
								return ce.value === null ? {} : { name: criteria.shortName, value: ce.value }; 
						}
						return orderCriterionWithPattern;
					});
				}
			}
		}
		return {};
	}
); 
*/
export const templatePrices = createSelector([getOrderResults], r => r.templatePrices);
export const paymentResponse = createSelector([getOrderResults], r => r.paymentResponse);
export const offerPrices = createSelector([getOrderResults], r => r.offerPrices);
export const offerPricesPending = createSelector([getOrderResults], r => r.offerPricesPending);
export const offerMinPrice = createSelector([getOrderResults], r => {
	let minPrice = null;
	for (let key in r.offerPrices) {
		let mp = r.offerPrices[key];
		if (!minPrice) {
			minPrice = mp;
		}
		else if (!minPrice.price) {
			minPrice = mp;
		}
		else if (mp.price && mp.price < minPrice.price) {
			minPrice = mp;
		}
	}
	return minPrice;
});
export const offerPriceResponse = createSelector([getOrderResults], r => r.offerPriceResponse);

export const namedMedia = createSelector([getCreative], creative => creative.namedMedia);

export const motifUuid = state => state.creative.uuid;

export const templateMediaNames = createSelector([namedMedia], namedMedia => {
	return namedMedia ? namedMedia.map(e => e.getAttribute('name')) : null;
});

const xmlMotifStructure = state => state.creative.xmlMotifStructure;

/**
 * Retrieve named media and textinputs from stylo xml data
 */
export const motifStructure = createSelector([xmlMotifStructure], xmlMotifStructure => parseMotifStructure(xmlMotifStructure));

export const formxUri = createSelector([selectedTemplate], t => {
	return t && t._links.formx ? t._links.formx.href : null
});

export const styloReady = state => state.creative.styloReady;

export const formxReady = state => state.creative.formxReady;

export const formxValues = state => state.creative.formxValues;

export const formxValid = createSelector([getCreative], creative => creative.formxValid);

export const formxUserFieldValues = createSelector([formxValues, templateMediaNames],
	(values, mediaNames) => {
		//		if (values && values.changedField) { ??????????????? !!!!!!!!!!!!!!!!
		if (values) {
			let userFields = [];
			let images = [];
			let material = [];
			let map = values => {
				for (let k in values) {
					let type = undefined;
					let value = undefined;
					if (values[k] instanceof Array && values[k].length) {
						if (values[k][0].type) {
							type = values[k][0].type;
							if (-1 !== type.indexOf('image')) {
								type = 'image';
							}
						}
					}
					value = values[k] instanceof Array ? values[k].map(v => v.name).join(' ') : values[k]; //  ??!!
					if ('object' !== typeof value) {
						let f = {
							name: k,
							value: value,
							force: true
						};
						if (type === 'image') {
							if (mediaNames && !mediaNames.find(e => e === f.name)) {
								material.push(f);
							}
							else {
								images.push(f);
							}
						}
						else {
							userFields.push(f);
						}
					}
				}
				if (values.$type) for (const [key, value] of Object.entries(values.$type)) {
					if (value === "Form" && values[key] && values[key].length) {
						values[key].forEach(f => map(f));
					}
				}
			}
			map(values.changedField ? values.changedField : values);
			return {
				UserFields: userFields,
				images: images,
				material: material
			};
		}
		else {
			return null;
		}
	});

export const originalMotifCode = createSelector([getCreative], (creative) => creative.originalMotifCode);
export const confirmedOrder = createSelector([getOrderResults],
	(r) => {
		if (r.confirmedOrder && r.orderPriceResponse && r.orderPriceResponse.success) {
			confirmedOrder.effectiveDates = r.orderPriceResponse.value.effectiveDates;
			return confirmedOrder;
		}
		else {
			return null;
		}
	}
);

export const orderPriceResponse = createSelector([getOrderResults, getCommon],
	(or, common) => {
		if (!or.orderPriceResponse) {
			return null;
		}
		const response = JSON.parse(JSON.stringify(or.orderPriceResponse));
		if (response.success) {
			response.value.price = common.customerType === "business" ? response.value.netPrice : response.value.grossPrice;
		}
		else {
			console.log('orderPriceResponse', response);
		}
		return response;
	}
);

export const orderPrice = createSelector([orderPriceResponse],
	(opr) => {
		return opr?.value;
	}
);

export const orderPricePending = createSelector([getOrderResults], r => r.orderPricePending);

export const orderResponse = createSelector([getOrderResults], or => or.orderResponse);

export const draftResponse = createSelector([getOrderResults], or => or.draftResponse);

export const editionPartPaymentTypeRestriction = createSelector([getMasterData], masterData => {
	const pts = masterData && masterData.paymentTypes ? masterData.paymentTypes : [];
	let restrictions = undefined;
	pts.forEach(pt => {
		if (pt.mandatoryForEditionParts) {
			restrictions = restrictions || {};
			pt.mandatoryForEditionParts.forEach(e => {
				restrictions[e] = pt._key;
			});
		}
	});
	return restrictions;
});

export const selectedEditionPart = createSelector([getMasterData], masterData => masterData.selectedEditionPart);

export const allPaymentTypes = state => {
	return state.masterData.paymentTypes;
};

export const paymentTypes = createSelector([getMasterData, customerType, getBusinessPartner, selectedTemplate, selectedEditionPart, editionPartPaymentTypeRestriction, isCicAuthenticated],
	(masterData, customerType, BP, selectedTemplate, selectedEditionPart, restrictions, isCicAuthenticated) => {
		if (!customerType) {
			return null;
		}
		let filtered = masterData.paymentTypes;

		// ASE-1019: WSS: Keine Einschränkung im CIC-Modus
		if (isCicAuthenticated) {
			return filtered;
		}

		// ASE-1019: WSS: Einschränkung aufgrund Rubrik
		if (filtered && selectedEditionPart && restrictions) {
			let key = restrictions[selectedEditionPart.shortName];
			if (key) {
				return filtered.filter(pt => pt._key === key);
			}
		}

		if (filtered) {
			filtered = filtered.filter(pt => !pt.legalEntityType || pt.legalEntityType === customerType.toUpperCase());
		}
		// ASE-551: Berücksichtigung von Bonität und Sperren bei den angebotenen Zahlungsarten
		if (BP.businessPartner && BP.businessPartner.commercialProps && BP.businessPartner.commercialProps[0] && BP.businessPartner.commercialProps[0].prepaymentRequired) {
			filtered = filtered.filter(pt => pt.forcePrePayment);
		}

		// ASE-492: Einschränkung der verwendbaren Zahlungsarten
		if (selectedTemplate && selectedTemplate.excludedPaymentTypes.length) {
			filtered = filtered.filter(pt => !selectedTemplate.excludedPaymentTypes.includes(pt.shortname));
		}

		//		filtered = filtered.filter(pt => pt.ibanPayment || pt.provider);


		// wofür ist das gut?

		// if (filtered) {
		// 	let shortname = null;
		// 	if (order.paymentMethod) {
		// 		shortname = order.paymentMethod;
		// 	}
		// 	else if (masterData.selectedPaymentType) {
		// 		shortname = masterData.selectedPaymentType.shortname;
		// 	}
		// 	else if (BP.businessPartner && BP.businessPartner.commercialProps && BP.businessPartner.commercialProps[0]) {
		// 		shortname = BP.businessPartner.commercialProps[0].preferredPaymentMethod;
		// 	}
		// 	if (shortname) {
		// 		filtered.forEach(pt => {
		// 			pt._selected = pt.shortname === shortname;
		// 		});
		// 	}
		// 	else {
		// 		filtered.forEach(pt => {
		// 			pt._selected = pt.ibanPayment;
		// 		});
		// 	}
		// 	if (BP != null && BP.businessPartner != null && BP.businessPartner.commercialProps[0] != null) {
		// 		if (BP.businessPartner.commercialProps[0].blocking == 'block' || BP.businessPartner.commercialProps[0].creditWorthinessReaction == 'block') {
		// 			filtered = [];
		// 		}
		// 		else if (BP.businessPartner.commercialProps[0].prepaymentRequired || BP.businessPartner.commercialProps[0].blocking == 'paymentInAdvance' || BP.businessPartner.commercialProps[0].creditWorthinessReaction == 'paymentInAdvance') {
		// 			filtered = filtered.filter(pt => pt.epayment);
		// 		}
		// 	}
		// 	for (let i = 0; i < filtered.length; ++i) {
		// 		filtered[i]._id = i;
		// 	}
		//	}
		return filtered;
	}
);

export const selectedPaymentMethod = createSelector([businessPartner, getOrder], (businessPartner, order) => {
	if (order && order.paymentMethod) {
		return order.paymentMethod;
	}
	else if (businessPartner && businessPartner.commercialProps && businessPartner.commercialProps.length) {
		return businessPartner.commercialProps[0].preferredPaymentMethod;
	} else if (isWSS) {
		return businessPartner?.bankAccounts?.length > 0 ? 'paymentDirectDebit' : undefined;
	}
});

export const selectedPaymentType = createSelector([paymentTypes, selectedPaymentMethod],
	(paymentTypes, paymentMethod) => {
		return paymentTypes.find(it => paymentMethod === it.shortname);
	}
);

export const creditStatus = createSelector([getBusinessPartner, paymentTypes],
	(BP, paymentTypes) => {
		let cs = null;// Possible vals are: null, paymentInAdvance, block
		if (BP === null || BP.businessPartner === null || BP.businessPartner.commercialProps[0] === null || paymentTypes === null) return cs;
		if (BP.businessPartner.commercialProps[0].blocking === 'block' || BP.businessPartner.commercialProps[0].creditWorthinessReaction === 'block' || (BP.businessPartner.commercialProps[0].prepaymentRequired && paymentTypes.length === 0)) cs = 'block';
		else if (BP.businessPartner.commercialProps[0].prepaymentRequired || BP.businessPartner.commercialProps[0].blocking === 'paymentInAdvance' || BP.businessPartner.commercialProps[0].creditWorthinessReaction === 'paymentInAdvance') cs = 'paymentInAdvance';
		return cs;
	}
);

export const ibanPaymentSelected = createSelector([creditStatus, selectedPaymentType], (creditStatus, pt) => {
	let res = pt ? (Boolean(pt) && pt.ibanPayment && creditStatus !== 'block' && creditStatus !== 'paymentInAdvance') : false;
	return res;
}
);

export const forOrderSelectedPaymentType = createSelector([getOrder, paymentTypes],
	(order, paymentTypes) => {
		if (paymentTypes && Boolean(paymentTypes.filter(pt => pt.shortname === order
			.forOrderSelectedPaymentType)))
			return paymentTypes.filter(pt => pt.shortname === order.forOrderSelectedPaymentType)[0]
	}
);

export const prePaymentTypes = createSelector([paymentTypes], (paymentTypes) => {
	return paymentTypes ? paymentTypes.filter(pt => pt.epayment) : null;
});

export const showCustomerDataBank = createSelector([paymentTypes],
	(paymentTypes) => {
		return paymentTypes && paymentTypes.filter(pt => pt.ibanPayment && pt.ibanPayment === true).length > 0;
	}
);

export const editionParts = createSelector([getMasterData], masterData => masterData.editionParts);

export const editionPartsHierarchy = createSelector([getMasterData],
	(masterData) => {
		return masterData.editionPartsHierarchy;
	}
);

export const salutations = createSelector([getMasterData], masterData => masterData.salutations);

export const titles = createSelector([getMasterData], masterData => masterData.titles);

export const countries = (state) => state.masterData.countries;

export const orderTypes = createSelector([getMasterData], masterData => masterData.orderTypes);

/**
 * Dont't show customertype-selection if:
 * - session has an authentication
 * - only one customertype is defined by property 'legalEntityTypes'
 */
export const hideCustomerTypes = createSelector([getCommon, getBusinessPartner],
	(common, BP) => {
		if (BP && BP.businessPartner && BP.businessPartner && BP.businessPartner.businessPartnerNo) {
			return true;
		}
		else if (common.properties && common.properties.legalEntityTypes) {
			return common.properties.legalEntityTypes.indexOf(',') === -1;
		}
		else {
			return false;
		}
	}
);

export const textInput = createSelector([getCreative], creative => creative.textInput);
export const rawText = createSelector([getCreative], creative => creative.rawText);
export const creativeParams = createSelector([getCreative], creative => creative.params);
export const preview = createSelector([getCreative], creative => creative.preview);
export const textFlows = createSelector([getCreative], creative => creative.textFlows);
export const upsellingTextflows = createSelector([getCreative], creative => creative.upsellingTextflows);
export const selectUpsellingImages = state => {
	return state.creative?.upsellingImages;
};
export const creativeResources = createSelector([getCreative], creative => creative.resources);
export const materialSelector = createSelector([getCreative], creative => creative.material);
export const material = createSelector([materialSelector], material => {
	return material ? material.map(m => {
		let f = m.shortName.match(/\.(.*?)\./);
		let widgetName;
		if (f) {
			let g = f[1].replace(/_[a-f0-9]{2}/gi, h => String.fromCharCode(parseInt(h.substring(1), 16)));
			widgetName = g.substring(g.lastIndexOf("/") + 1);
		}
		return Object.assign(m, { formxWidgetName: widgetName });
	}) : undefined;
});

export const mergedMaterial = createSelector([material, motifStructure], (material, motifStructure) => {
	return material ? material.map(m => {
		let n = JSON.parse(JSON.stringify(m));
		let alreadyRendered = motifStructure?.namedMedias?.find(nm => m.formxWidgetName === nm.name);
		return alreadyRendered && 'true' !== alreadyRendered.dummy ? Object.assign(n, { aicUrl: alreadyRendered.url }) : n;
	}) : undefined;
});

export const adBoxNumberTypes = createSelector([getMasterData], md => md.adBoxNumberTypes);

// 		export const adBoxNumberTypes = createSelector([selectedTemplate], (selectedTemplate) => {
// 			return selectedTemplate && selectedTemplate.adBoxNumberTypes ? { content: selectedTemplate.adBoxNumberTypes } : null;
// 		});

export const adBoxNumber = createSelector([getOrder], order => order.adBoxNumber);

export const voucher = createSelector([getOrder], order => order.voucher);

export const tenant = createSelector([getApi], r => r.tenant);

export const textLead = createSelector([selectedTemplate, creativeParams, motifStructure, rawText], (selectedTemplate, creativeParams, motifStructure, rawText) => {
	if (selectedTemplate?.textLeadFieldName && creativeParams && motifStructure) {
		let field = motifStructure.textFlows.find(e => {
			return e.name === selectedTemplate.textLeadFieldName;
		});
		let value = field?.content; // !!
		if (!value) {
			field = motifStructure.userFields.find(e => {
				return e.name === selectedTemplate.textLeadFieldName;
			});
			value = field?.value; // !!
		}
		if (value) {
			let tl = value.replace(/<\/p>/g, " ").replace(/<.*?>/g, "").trim().replace(/[\s\r\n]+/g, " ");
			return selectedTemplate.textLeadCharCount > 0 ? cutCatchword(tl, selectedTemplate.textLeadCharCount) : tl; // ASE-2129
		}
	}
	return rawText;
});

export const orderCatchword = createSelector([textLead, getOrder, tenant],
	(textLead, order, tenant) => {
		let length = tenant?.orderCatchword ? tenant.orderCatchword : 100;
		if (order?.orderCatchword && order?.orderCatchword !== '') {
			return cutCatchword(order.orderCatchword, length);
		} else if (textLead !== '') {
			return cutCatchword(textLead, length);
		} else {
			return '';
		}
	});

export const motifCriterionValues = createSelector([getCreative], creative => creative.motifCriterionValues);

export const templateUserFields = createSelector([getCreative], creative => creative.templateUserFields);

export const templateUserFieldValues = createSelector([getCreative], creative => creative.templateUserFieldValues);

export const userFields = createSelector([getStylo], stylo => {
	let values = [];
	for (let v in stylo) {
		values.push(stylo[v]);
	}
	return values;
});

export const adBoxFields = (state) => state.creative.adBoxFields;

export const onlineId = createSelector([getCreative], creative => creative.onlineId);

export const oAuthConfig = createSelector([getCommon], r => r.oAuthProvider);

export const orders = createSelector([getBusinessPartnerOrders], r => r.orders ? r.orders : []);
export const drafts = createSelector([getBusinessPartner], r => r.drafts);
export const orderToEdit = createSelector([getBusinessPartner], r => r.orderToEdit);
export const orderFormxValues = createSelector([getBusinessPartner], r => r.orderFormxValues);

export const cancelUrl = createSelector([getBusinessPartner], r => r.cancelUrl);

export const assetBaseUrl = state => {
	let tenant = state.api.tenant;
	if (!tenant) {
		return '';
	}
	return tenant && tenant.webdavUri ? `${tenant.webdavUri}/webstore` :
		`${tenant.styloTargetUri}/webdav/doc/webstore`;
};

export const assetOdtUrl = createSelector([assetBaseUrl, motifUuid], (assetBaseUrl, motifUuid) => {
	return assetBaseUrl && motifUuid ? `${assetBaseUrl}/${motifUuid}.odt` : '';
});
export const assetPreviewUrl = createSelector([motifUuid, properties], (motifUuid, properties) => {
	return `/webstore/service/v1/order/motif/${motifUuid}.${properties.previewFormat}`;
});

export const styloSaved = state => {
	return state.creative.styloSaved;
};

export const creativeUrl = createSelector([getMasterData, getBusinessPartner, assetOdtUrl, styloSaved],
	(masterData, BP, assetOdtUrl, styloSaved) => {
		if (BP.orderToRepeat) {
			return BP.orderToRepeat._links.source.href;
		}
		else if (BP.orderToEdit) {
			return BP.orderToEdit._links.source.href;
		}
		else if (assetOdtUrl && styloSaved) {
			return assetOdtUrl;
		}
		else if (masterData.selectedTemplate) {
			return masterData.selectedTemplate._links.source.href
		}
	});

export const pendingOrder = createSelector(
	[getMasterData, businessPartner, getOrder, getCommon, getCreative, device, selectedOffer, selectedTemplate,
		selectedPaymentMethod, assetOdtUrl, orderCatchword],
	(masterData, businessPartner, order, common, creative, device, selectedOffer, selectedTemplate,
		selectedPaymentMethod, assetOdtUrl, orderCatchword) => {

		let orderType = (order, masterData) => {
			if (order && masterData) {
				let ot = order.orderType;
				if (!ot && masterData.orderTypes && masterData.orderTypes.length) {
					ot = masterData.orderTypes[0].key;
				}
				return ot;
			}
			else {
				return '';
			}
		};

		let numberOfColors = 0;
		const motif = creative && creative.params ? {} : null;
		if (motif) {
			motif.params = creative.params;
			motif.rawText = creative.rawText ? creative.rawText : "";
			mapMotifCriterionValues(motif, creative.formxValues);
			motif.uuid = creative.uuid;
			numberOfColors = motif.params['number-of-colours'];
			motif.materials = creative.material ? creative.material.map(e => e._links.material.href) : [];
			motif.originalMotifCode = creative.originalMotifCode;
		}

		if (masterData && order && motif) {
			let colorType = 'blackAndWhite';
			let critVals = [];
			if (order && order.criteriaValues && order.criteriaValues.length > 0) critVals.forEach(c => critVals.push(c));
			if (masterData.selectedOffer && masterData.selectedOffer.criteriaValues && masterData.selectedOffer.criteriaValues.length > 0) {
				masterData.selectedOffer.criteriaValues.forEach(c => critVals.push(c));
			}
			if (order.criterionValues) {
				order.criterionValues.forEach(c => {
					var i = critVals.findIndex(cv => cv.shortName === c.shortName);
					if (critVals.find(cv => cv.shortName === c.shortName)) critVals.splice(i, 1);
					critVals.push(c);
				});
			}
			if (critVals.find(cv => cv.description === "offercriteria")) {
				var allOffercriterias = [];
				allOffercriterias.push(critVals.find(cv => cv.description === "offercriteria"));
				allOffercriterias.forEach(oc => {
					if (masterData.offercriterias && masterData.offercriterias.find(x => x.shortName === oc.shortName
						&& ((x.validFrom !== null && new Date(x.validFrom) > Date.now())
							|| (x.validTo !== null && new Date(x.validTo) < Date.now()))
					)) critVals.splice(critVals.find(cv => cv.shortName === oc.shortName), 1);
				});
			}



			if (critVals.length === 0) critVals = null;
			if (masterData.selectedTemplate) {
				colorType = masterData.selectedTemplate.defaultColorType ? masterData.selectedTemplate.defaultColorType : numberOfColors > 1 ? '_4C' : 'blackAndWhite';
			}
			let po = {
				UUID: order.UUID,
				adBoxNumber: order.adBoxNumber,
				auctionCredit: Boolean(businessPartner && businessPartner.auctionCredit && order.auctionCredit),
				bankAccountId: businessPartner && businessPartner.businessPartnerNo ? order.bankAccountId : null,
				iban: order.useAlternativeIban ? order.iban : null,
				swift: order.useAlternativeIban ? order.swift : null,
				businessPartnerNumber: businessPartner ? businessPartner.businessPartnerNo : null,
				criterionValues: critVals,
				// SUP-13517: ...
				// wie geht das in WSS??
				editionPartId: masterData.selectedEditionPart ? masterData.selectedEditionPart._id : 0,
				legalEntityType: common.customerType ? common.customerType.toUpperCase() : null,
				orgLegalEntityType: businessPartner ? businessPartner.legalEntity.type : null,
				marketId: masterData.selectedMarket ? masterData.selectedMarket._id : 0,
				marketingApproval: businessPartner && businessPartner.marketingApproval ? businessPartner.marketingApproval.value : false,
				offerId: selectedOffer?._id,
				paymentMethod: selectedPaymentMethod,
				price: order.orderPrice ? {
					"currency": order.orderPrice.currency, "amount": order.orderPrice.price
				} : null,
				scheduleCompositions: [],
				//selectedEditionIds: masterData.selectedOffer && masterData.selectedOffer.editionsSelectable && !order.selectedEditionIds ? masterData.selectedOffer.preSelectedEditions : order.selectedEditionIds,
				selectedEditionIds: masterData.selectedEditionIds,
				servicePackageId: order.servicePackageId,
				startDate: selectedOffer ? order.desiredDate : undefined,
				// ASE-559
				productionDates: order.productionDates,
				templateId: masterData.selectedTemplate ? masterData.selectedTemplate._id : 0,
				voucher: order.voucher,
				motif: motif ? {
					catchword: motif.rawText,
					charCount: motif.params["character-count"],
					colorType: colorType,
					columnCount: creative.columns,
					creativeParams: motif.params,
					creativeParamsString: JSON.stringify(motif.params),
					criterionValues: motif.motifCriterionValues,
					height: motif.params["doc-height-1000mm"],
					lineCount: motif.params["row-count"],
					motifUrl: assetOdtUrl,
					materials: motif.materials,
					motifUuid: motif.uuid,
					nominalHeight: motif.params["doc-nominal-height-1000mm"],
					nominalWidth: motif.params["doc-width-1000mm"],
					width: motif.params["doc-width-1000mm"],
					wordCount: motif.params["word-count"],
					originalMotifCode: motif.originalMotifCode,
					formxData: motif.formxData,
					schema: motif.schema
				} : null,
				advertizerNumber: order.advertizer,
				device: device,
				orderCatchword: orderCatchword,
				orderComment: order.orderComment,
				alternativeAddress: order.alternativeAddress ? {
					salutation: order.alternativeAddress.salutation,
					title: order.alternativeAddress.title,
					name2: order.alternativeAddress.firstName,
					name: order.alternativeAddress.name2,
					street: order.alternativeAddress.street,
					houseNumber: order.alternativeAddress.houseNumber,
					zipCode: order.alternativeAddress.zipCode,
					cityName: order.alternativeAddress.cityName,
					country: order.alternativeAddress.country,
					orderMail: order.alternativeAddress.orderMail,
					invoiceMail: order.alternativeAddress.invoiceMail
				} : null,
				orderType: orderType(order, masterData),
				priceCalculation: order.priceCalculation,
				orderFixPrice: order.orderFixPrice,
				onlineId: creative.onlineId,
				orderIsCombi: order.orderIsCombi,
				useAlternativeAddress: order.useAlternativeAddress,
				orderConfirmation: order.orderConfirmation,
				orderCodeToReplace: order.orderCodeToReplace
			};

			if (!po.selectedEditionIds) {
				po.selectedEditionIds = selectedOffer?.editions;

			}
			if (!po.editionPartId) {
				po.editionPartId = selectedTemplate?.defaultAdEdPartId;
			}
			return po;
		}
	}
);

/*export const orderComplete = createSelector([pendingOrder, orderPriceResponse], (o, opr) => {
	return Boolean(o && o.editionPartId && o.offerId && o.startDate && opr && opr.value && opr.value.effectiveDates);
}); funktioniert nicht, da o.editionPartId immer einen Wert enthält, sobald ein Muster ausgewählt ist. (siehe pendingOrder) */

export const possibleDates = createSelector([getOrderResults, selectedOffer],
	(or, selectedOffer) => {
		if (or.possibleDatesResponse && or.possibleDatesResponse.success && selectedOffer && selectedOffer.publicationCalendar) {
			return or.possibleDatesResponse.value.map(e => e.productionDate.substring(0, 10));
		}
		else {
			return [];
		}
	});

export const desiredDate = createSelector([getOrder, orderPriceResponse],
	(order, opr) => {
		if (opr?.value?.effectiveDates && opr.value.effectiveDates.length > 0) {
			return opr.value.effectiveDates[0];
		} else {
			return Date.parse(order?.desiredDate) < Date.parse(tomorrow()) ? tomorrow() : order.desiredDate;
		}
	});


export const selectedEditionIds = createSelector([getMasterData, selectedOffer], (r, offer) => {
	if (r?.selectedEditionIds?.length > 0) {
		return r.selectedEditionIds;
	}
	else if (offer && offer.editionsSelectable) {
		return offer.preSelectedEditions;
	}
	else if (offer) {
		return offer.editions;
	}
});

export const editionDisplayNames = createSelector([selectedEditionIds, getMasterData],
	(selectedEditionIds, masterData) => {
		if (selectedEditionIds && selectedEditionIds.length && masterData.editions) {
			var selected = selectedEditionIds;
			var filtered = masterData.editions.filter(e => {
				for (var i = 0; i < selected.length; ++i) {
					if (selected[i] === e._id) {
						return true;
					}
				}
				return false;
			});
			return filtered.map(e => e.name).join(' \u2022 ');
		}
		else {
			return '';
		}
	}
);


export const orderCancelled = createSelector([getBusinessPartnerOrders], r => r.orderCancelled);
export const draftReleased = createSelector([getBusinessPartnerOrders], r => r.draftReleased);
export const draftDeleted = createSelector([getBusinessPartnerOrders], r => r.draftDeleted);
export const invoices = createSelector([getBusinessPartner], r => r.invoices);

export const busy = createSelector([getUI], r => {
	if (r.styloPending) {
		return true;
	};
	return false;
});
export const entryPoint = createSelector([getUI], r => r.entryPoint);

/**
 * actual application page. If undefined, use 'templates' as entrypoint
 */
export const page = (state) => state.ui.page || 'templates';
export const previousPage = (state) => state.ui.previousPage;

export const language = createSelector([getUI], r => r.language);
export const locale = createSelector([getUI], r => r.locale);

export const versionInfo = createSelector([getApi], r => {
	return {
		version: !r.version || r.version.indexOf("@") !== -1 ? "SNAPSHOT" : r.version,
		server: r.tenant && r.tenant.serviceUri ? r.tenant.serviceUri.replace(/^.*\/\/([^:/]*).*$/, "$1") : "",
		adSuite: r.serviceVersionInfo ? r.serviceVersionInfo.versionString : ""
	}
});

export const validIbanCountries = createSelector([getMasterData], (masterData) => {
	if (masterData && masterData.paymentTypes) {
		let filtered = masterData.paymentTypes.filter(e => e.ibanPayment);
		return filtered.length ? filtered[0].validForCountryCodes : null;
	}
	return null;
});

/**
 * Business-Partner / bankaccount
 */
export const validIbans = createSelector([getBusinessPartner, getOrder, validIbanCountries],
	(BP, order, validIbanCountries) => {
		let ibans = [];
		if (BP.businessPartner && BP.businessPartner.bankAccounts) {
			const now = Date.now();
			ibans = BP.businessPartner.bankAccounts.filter(ba => {
				return ((!ba.validFrom || Date.parse(ba.validFrom) < now) && (!ba.validTo || Date.parse(ba.validTo) > now));
				//						return false;
			});
		}

		// ASE-1024: add alternative iban to list
		if (order.iban && !order.bankAccountId) {
			ibans.push({ _id: 9999, iban: order.iban, toBeCreated: true, _selected: true, preferred: true, swift: order.swift })
		}

		// ASE-1019: WSS: Zahlungsabwicklung allgemein: Einschränkung aufsubstrinmg bestimmte Länder
		if (validIbanCountries && validIbanCountries.length) {
			ibans = ibans.filter(ba => {
				let country = ba.iban.substring(0, 2).toLowerCase();
				return Boolean(validIbanCountries.find(e => e === country));
			});
		}

		// generate display values (last 4 chars visible)
		ibans = ibans.map(iban => {
			iban._displayIban = iban.iban.replace(/\s/g, "").replace(/./g, function (c, i, t) {
				if (i < t.length - 4) c = "\u2022";
				return i && !(i % 4) ? " " + c : c;
			});
			return iban;
		});

		if (order.bankAccountId) {
			ibans.map(iban => {
				iban._selected = iban._id === order.bankAccountId;
				return iban;
			});
		}
		else {
			// mark preferred iban as selected
			ibans.map(iban => {
				iban._selected = iban.preferred;
				return iban;
			});
			// choose first as selected if no iban is marked as preferred
			if (-1 === ibans.findIndex(i => i._selected) && ibans.length) {
				ibans[0]._selected = true;
			}
		}
		return ibans;
	}
);

export const ibanRequired = createSelector([selectedPaymentType, getOrder], (selectedPaymentType) => {
	if (selectedPaymentType) {
		return selectedPaymentType.ibanPayment;
	}
	else {
		return false;
	}
});

export const initIban = createSelector([getOrder], order => {
	let oIban = {
		iban: order.iban,
		swift: order.swift
	}
	return oIban;
});

export const initAlternative = createSelector([getOrder], order => Boolean(order.iban));

export const orderAlternativeAddress = createSelector([getOrder], (order) => {
	let altAddr = order.alternativeAddress ? {
		firstName: order.alternativeAddress.name2,
		lastName: order.alternativeAddress.name,
		street: order.alternativeAddress.street,
		number: order.alternativeAddress.houseNumber,
		zip: order.alternativeAddress.zipCode,
		city: order.alternativeAddress.cityName,
		title: order.alternativeAddress.title,
		salutation: order.alternativeAddress.salutation,
		country: order.alternativeAddress.country,
		orderEmail: order.alternativeAddress.orderMail,
		invoiceEmail: order.alternativeAddress.invoiceMail
	} : null;
	return altAddr;
});

export const hasAlternativeAddress = createSelector([getOrder], (order) => { return order.useAlternativeAddress });

/*
 * compute if order is free of charge
 */
export const freeOfCharge = createSelector([selectedMarket, orderPriceResponse], (selectedMarket, orderPriceResponse) => {
	return Boolean(selectedMarket && selectedMarket.freeOfCharge && orderPriceResponse && (!orderPriceResponse.value || !orderPriceResponse.value.price));
});

export const epaymentConfirmed = createSelector([getOrderResults], (or) => {
	return Boolean(or.paymentResponse && or.paymentResponse.success);
});

export const ePayment = createSelector([getOrderResults], or => {
	return or.ePayment;
});

export const reqProductionDates = createSelector([pendingOrder, selectedOffer], (order, offer) => {
	return order ? order.productionDates : null;
});

export const userProfile = createSelector([getRegistration], registration => registration.userProfile);

/** Model of Businesspartner in UI-Widgets */
/*export const userTemplate = createSelector([locale, language, customerType, userProfile, authentication], (locale, language, customerType, userProfile, authentication) => {
	const up = userProfile || {};
	const ct = up.legalEntityType ? up.legalEntityType.toLowerCase() : customerType;
	const privacy = ct == 'business' ? 'office' : 'home';

	let fp = {
		internationalAreaCode: '',
		areaCode: '',
		subscriberCode: '',
		privacy: privacy
	}, mp = {
		internationalAreaCode: '',
		areaCode: '',
		subscriberCode: '',
		privacy: privacy
	};
	if (up.telecom && up.telecom.mobilityType === 'fixed') {
		fp.internationalAreaCode = up.telecom.internationalAreaCode;
		fp.areaCode = up.telecom.areaCode;
		fp.subscriberCode = up.telecom.subscriberCode;
		fp.privacy = up.telecom.privacy;
	}
	else if (up.telecom && up.telecom.mobilityType === 'mobile') {
		mp.internationalAreaCode = up.telecom.internationalAreaCode;
		mp.areaCode = up.telecom.areaCode;
		mp.subscriberCode = up.telecom.subscriberCode;
		mp.privacy = up.telecom.privacy;
	}

	const userTemplate = {
		mailAddress: {
			country: locale ? locale.toUpperCase() : "DE",
			forename: up.firstName,
			name: up.legalEntityType === 'PRIVATE' ? up.lastName : up.companyName,
			salutation: up.salutation,
			title: up.title
		},
		netAddress: {
			address: up.email
		},
		foreignBusinessPartnerNo: authentication ? authentication.providerUserId : null,
		fixedPhone: [fp],
		mobilePhone: [mp],
		bankAccounts: [
			{ iban: undefined, preferred: false }
		],
		businessPartner: {
			marketingApproval: { value: false }
		},
		customerType: ct
	};
	return userTemplate;
}); */

/** data from ui widgets */
/*export const userData = createSelector([getUser], user => user);*/

export const isWSS = createSelector([getCommon, getApi], (r, a) => {
	return a && a.activeProfiles && Boolean(a.activeProfiles.find(p => p === 'SAP-SC'));
});

export const userDataFromBP = createSelector([getBusinessPartner, isWSS], (bpart, isWSS) => {
	var usr = {};
	var businessPartner = {};
	if (bpart && bpart.businessPartner && bpart.businessPartner.businessPartnerNo) {
		businessPartner = JSON.parse(JSON.stringify(bpart.businessPartner));
		usr.vatNo = businessPartner.legalEntity.vatNo;
		let ma = businessPartner.legalEntity.mailAddresses[0];
		usr.mailAddress = {
			'_id': ma._id,
			'salutation': ma.salutation,
			'title': ma.title,
			'forename': ma.forename,
			'name': ma.name,
			'street': ma.street,
			'houseNumber': (ma.houseNumber ? ma.houseNumber : '') + (ma.houseNumberAnnex ? ma.houseNumberAnnex : ''),
			'country': ma.country,
			'zipCode': ma.zipCode,
			'cityName': ma.cityName,
			'isProtectedFromChange': ma.isProtectedFromChange
		};
		let na = businessPartner.legalEntity.netAddresses[0];
		usr.netAddress = { 'address': na ? na.address : null };
		usr.bankAccounts = JSON.parse(JSON.stringify(businessPartner?.bankAccounts));
		if (!usr.bankAccounts) usr.bankAccounts = [];
		if (!isWSS || !usr.bankAccounts.length) {
			usr.bankAccounts.push({ "iban": undefined, "preferred": false });
		}
		usr.mobilePhone = [];
		usr.fixedPhone = [];
		if (businessPartner.legalEntity.teleComms) {
			for (var i = 0; i < businessPartner.legalEntity.teleComms.length; i++) {
				if (businessPartner.legalEntity.teleComms[i].areaCode !== '' && businessPartner.legalEntity.teleComms[i].subscriberCode !== '') {
					if (businessPartner.legalEntity.teleComms[i].mobilityType === 'mobile') {
						usr.mobilePhone.push({
							'internationalAreaCode': businessPartner.legalEntity.teleComms[i].internationalAreaCode,
							'areaCode': businessPartner.legalEntity.teleComms[i].areaCode,
							'subscriberCode': businessPartner.legalEntity.teleComms[i].subscriberCode,
							'privacy': businessPartner.legalEntity.teleComms[i].privacy,
							'_id': businessPartner.legalEntity.teleComms[i]._id,
							'mobilityType': businessPartner.legalEntity.teleComms[i].mobilityType
						});
					} else {
						usr.fixedPhone.push({
							'internationalAreaCode': businessPartner.legalEntity.teleComms[i].internationalAreaCode,
							'areaCode': businessPartner.legalEntity.teleComms[i].areaCode,
							'subscriberCode': businessPartner.legalEntity.teleComms[i].subscriberCode,
							'privacy': businessPartner.legalEntity.teleComms[i].privacy,
							'_id': businessPartner.legalEntity.teleComms[i]._id,
							'mobilityType': businessPartner.legalEntity.teleComms[i].mobilityType
						});
					}
				}
			}
		}
		let privacy = privacyFromLE(businessPartner.legalEntity.type);
		if (!isWSS || !usr.mobilePhone.length) usr.mobilePhone.push({
			'internationalAreaCode': this.LastinternationalAreaCode,
			'areaCode': '',
			'subscriberCode': '',
			'privacy': privacy,
			'mobilityType': 'mobile'
		});
		if (!isWSS || !usr.fixedPhone.length) usr.fixedPhone.push({
			'internationalAreaCode': this.LastinternationalAreaCode,
			'areaCode': '',
			'subscriberCode': '',
			'privacy': privacy,
			'mobilityType': 'fixed'
		});
		if (businessPartner.legalEntity.credentials) {
			usr.loginname = businessPartner.legalEntity.credentials.name;
		}
		usr.businessPartner = {};
		usr.businessPartner.marketingApproval = businessPartner.marketingApproval;
		if (businessPartner.commercialProps && businessPartner.commercialProps[0]) usr.commercialProps = businessPartner.commercialProps;
		return usr;
	} else return null;
});

/** model to create a new businesspartner from registration data */
/*export const newBusinessPartner = createSelector([userData, properties, customerType, selectedPaymentType, paymentTypes], (user, props, customerType, selectedPaymentType, paymentTypes) => {

	if (Boolean(paymentTypes) && !selectedPaymentType) {
		selectedPaymentType = paymentTypes.filter(pt => pt.ibanPayment).length > 0 ? paymentTypes.filter(pt => pt.ibanPayment) : paymentTypes[0];
	}
	if (!customerType) customerType = "private";
	if (!user || !user.mailAddress || !props || !customerType || !selectedPaymentType) {
		return null;
	}

	const privacy = customerType === "private" ? "home" : "office";
	const salutation = customerType === "private" ? user.mailAddress.salutation || '' : props.defaultcustomerdataSalutation || '';
	const businessPartner = {
		foreignBusinessPartnerNo: user.foreignBusinessPartnerNo,

		legalEntity: {
			type: leTypeFromCT(customerType),
			vatNo: user.vatNo,
			mailAddresses: [
				{
					salutation: salutation,
					title: user.mailAddress.title,
					forename: user.mailAddress.forename,
					name: user.mailAddress.name,
					street: user.mailAddress.street,
					houseNumber: user.mailAddress.houseNumber,
					houseNumberAnnex: '',
					country: user.mailAddress.country,
					zipCode: user.mailAddress.zipCode,
					cityName: user.mailAddress.cityName,
					vatNo: user.vatNo
				}
			],
			teleComms: [],
			netAddresses: [{
				address: user.netAddress.address,
				privacy: privacy
			}],
			credentials: !user.foreignBusinessPartnerNo ? {
				name: user.loginname,
				password: user.password
			} : null
		},
		bankAccounts: null,
		// default payment stored in commercial props
		commercialProps: user.commercialProps && user.commercialProps[0] ? user.commercialProps : [{ "preferredPaymentMethod": selectedPaymentType.shortname }],
		marketingApproval: user.businessPartner.marketingApproval
	};

	[{ type: 'mobile', teleComm: user.mobilePhone[0] }, { type: 'fixed', teleComm: user.fixedPhone[0] }].forEach(t => {
		if (t.teleComm && t.teleComm.subscriberCode) {
			businessPartner.legalEntity.teleComms.push({
				mobilityType: t.type,
				internationalAreaCode: t.teleComm.internationalAreaCode,
				areaCode: t.teleComm.areaCode,
				subscriberCode: t.teleComm.subscriberCode,
				privacy: privacy
			});
		}
	});

	if (user.bankAccounts) {
		businessPartner.bankAccounts = user.bankAccounts.filter(a => a.iban || a.accountNumber);
		businessPartner.bankAccounts.forEach(a => a.iban = a.iban.replace(/ /g, ""));
	}
	return businessPartner;
}); */

/** model to update businesspartner from customer data */
/*export const modifiedBusinessPartner = createSelector([businessPartner, userData, selectedPaymentType, customerType, properties, paymentTypes, authentication], (businessPartner, user, selectedPaymentType, customerType, props, paymentTypes, authentication) => {
	if (Boolean(paymentTypes) && !selectedPaymentType) {
		selectedPaymentType = paymentTypes.filter(pt => pt.ibanPayment).length > 0 ? paymentTypes.filter(pt => pt.ibanPayment) : paymentTypes[0];
	}
	if (!user || !businessPartner || !props || !selectedPaymentType) {
		return null;
	}

	const modified = JSON.parse(JSON.stringify(businessPartner));//Object.assign({}, businessPartner);
	const privacy = customerType == "private" ? "home" : "office";
	const salutation = customerType == "private" ? user.mailAddress.salutation || '' : props.defaultcustomerdataSalutation || '';
	if (Boolean(modified.legalEntity.mailAddresses)) {
		modified.legalEntity.mailAddresses.filter(mail => mail._id === user.mailAddress._id).map(mail => {
			mail.salutation = salutation;
			mail.title = user.mailAddress.title;
			mail.forename = user.mailAddress.forename;
			mail.name = user.mailAddress.name;
			mail.street = user.mailAddress.street;
			mail.houseNumber = user.mailAddress.houseNumber;
			mail.houseNumberAnnex = '';
			mail.country = user.mailAddress.country;
			mail.zipCode = user.mailAddress.zipCode;
			mail.cityName = user.mailAddress.cityName;
		})
	} else {
		modified.legalEntity.mailAddresses = [
			{
				'salutation': salutation,
				'title': user.mailAddress.title,
				'forename': user.mailAddress.forename,
				'name': user.mailAddress.name,
				'street': user.mailAddress.street,
				'houseNumber': user.mailAddress.houseNumber,
				'houseNumberAnnex': '',
				'country': user.mailAddress.country,
				'zipCode': user.mailAddress.zipCode,
				'cityName': user.mailAddress.cityName
			}
		];
	}
	modified.legalEntity.vatNo = user.vatNo;
	if (!modified.legalEntity.teleComms) modified.legalEntity.teleComms = [];
	if (user.mobilePhone) {
		for (var i = 0; i < user.mobilePhone.length; i++) {
			if (user.mobilePhone[i]._id && modified.legalEntity.teleComms.filter(t => t._id === user.mobilePhone[i]._id).length > 0) {
				modified.legalEntity.teleComms.filter(t => t._id === user.mobilePhone[i]._id).map(tel => {
					tel.mobilityType = user.mobilePhone[i].mobilityType;
					tel.internationalAreaCode = user.mobilePhone[i].internationalAreaCode;
					tel.areaCode = user.mobilePhone[i].areaCode;
					tel.subscriberCode = user.mobilePhone[i].subscriberCode;
				})
			} else if (user.mobilePhone[i].subscriberCode) {
				modified.legalEntity.teleComms.push({
					"mobilityType": "mobile",
					'internationalAreaCode': user.mobilePhone[i].internationalAreaCode,
					'areaCode': user.mobilePhone[i].areaCode,
					'subscriberCode': user.mobilePhone[i].subscriberCode,
					'privacy': user.mobilePhone[i].privacy
				});
			}
		}
	}
	if (user.fixedPhone) {
		for (var i = 0; i < user.fixedPhone.length; i++) {
			var tel = modified.legalEntity.teleComms.filter(t => t._id === user.fixedPhone[i]._id)
			if (user.fixedPhone[i]._id && modified.legalEntity.teleComms.filter(t => t._id === user.fixedPhone[i]._id).length > 0) {
				modified.legalEntity.teleComms.filter(t => t._id === user.fixedPhone[i]._id).map(tel => {
					tel.mobilityType = user.fixedPhone[i].mobilityType;
					tel.internationalAreaCode = user.fixedPhone[i].internationalAreaCode;
					tel.areaCode = user.fixedPhone[i].areaCode;
					tel.subscriberCode = user.fixedPhone[i].subscriberCode;
				})
			} else if (user.fixedPhone[i].subscriberCode) {
				modified.legalEntity.teleComms.push({
					"mobilityType": "fixed",
					'internationalAreaCode': user.fixedPhone[i].internationalAreaCode,
					'areaCode': user.fixedPhone[i].areaCode,
					'subscriberCode': user.fixedPhone[i].subscriberCode,
					'privacy': user.fixedPhone[i].privacy
				});
			}
		}
	}

	if (user.commercialProps && user.commercialProps[0]) {
		modified.commercialProps = user.commercialProps;
	} else modified.commercialProps = [{ "preferredPaymentMethod": selectedPaymentType.shortname }];
	// ASE-403: Marketing-Einverständnis
	if (user.businessPartner) {
		modified.marketingApproval = user.businessPartner.marketingApproval;
	}

	if (user.bankAccounts) {
		modified.bankAccounts = user.bankAccounts.filter(a => a.iban || a.accountNumber);
		modified.bankAccounts.forEach(a => a.iban = a.iban.replace(/ /g, ""));
	}

	if (user.password && !user.foreignBusinessPartnerNo && businessPartner.legalEntity.credentials && businessPartner.legalEntity.credentials.name) {
		modified.legalEntity.credentials = {
			name: businessPartner.legalEntity.credentials.name,
			password: user.password
		};
	};

	modified.foreignBusinessPartnerNo = authentication ? authentication.providerUserId : null;

	return modified;
}); */

export const orderCodeToRepeat = createSelector([getCommon], common => common.orderCode);

export const trackingEvent = createSelector([getTracking], (tracking) => {
	return tracking && tracking.tracker && tracking.tracker.trackingAccepted && tracking.event ? tracking.event : null;
});

export const tracker = createSelector([getTracking], (tracking) => {
	return tracking.tracker;
});

export const orderPrint = createSelector([getOrderResults], r => {
	return r.orderPrint;
});

export const orderPrintUrl = createSelector([getOrderResults], r => {
	return r.orderPrintUrl;
});

export const exposeStatus = createSelector([getOrderResults], (r) => {
	return r.exposeStatus;
});

export const fullOrder = createSelector([orderPriceResponse, pendingOrder, selectedOffer], (orderPriceResponse, pendingOrder, selectedOffer) => {
	if (orderPriceResponse && orderPriceResponse.value && pendingOrder && selectedOffer) {
		let order = JSON.parse(JSON.stringify(pendingOrder));
		order.orderPrice = orderPriceResponse.value;
		return order;
	}
	else {
		return null;
	}
});

export const advertizer = createSelector([getOrder], r => r.advertizer);

export const ibanMissing = createSelector([selectedPaymentType, getOrder, validIbans, isWSS], (selectedPaymentType, order, validIbans, isWSS) => {
	if (selectedPaymentType && order && !isWSS) {
		return Boolean(!validIbans.length);
	}
	else {
		return false;
	}
});

export const promotionalText = createSelector([selectedEditionIds, getMasterData], (selectedEditionIds, r) => {
	let text;
	if (selectedEditionIds) {
		let editions = r.editions.filter(e => {
			return Boolean(selectedEditionIds.indexOf(e._id) !== -1);
		});
		editions.forEach(e => {
			if (e.htmlText) {
				text = text ? text + e.htmlText : e.htmlText;
				text += ' ';
			}
		});
	}
	return text;
});

export const channels = createSelector([getMasterData], r => r.channels);

export const activeProfiles = createSelector([getApi], a => {
	return a?.activeProfiles || [];
});

/*export const isExposeOrder = createSelector([getMasterData, getCommon], (r, common) => {
	if (!r.selectedEditionIds || !r.exposeConfiguration || !r.exposeConfiguration.jsExpression) {
		return false;
	}
		const market = r.selectedMarket;
		const offer = r.selectedOffer;
		const effectiveEditionNames = r.editions.filter(e => r.selectedEditionIds.includes(e._id)).map(e => e.name);
		const selectedEditionShortNames = r.editions.filter(e => r.selectedEditionIds.includes(e._id)).map(e => e.shortName);
		const selectedEditionIds = r.selectedEditionIds; 
	return eval(r.exposeConfiguration.jsExpression);
}); */

export const exposeOperations = createSelector([getApi], (api) => api.exposeOperations);

export const immomarktConfiguration = createSelector([getMasterData], r => r.immomarktConfiguration);

export const paymentStatus = createSelector([getBusinessPartner], (bp) => {
	if (bp) {
		return bp.paymentStatus;
	}
});

export const forceLogin = (state) => state.common.customerType;

export const requiredCustomerFormFields = createSelector([customerType, properties], (customerType, properties) => {
	if (customerType && properties) {
		const value = 'business' === customerType ? properties.registerFormRequieredElemntsBusiness : properties.registerFormRequieredElemnts;
		return value ? value.split(',') : [];
	}
	else {
		return [];
	}
});

/**
 * calculate adbox-abstract text fields
 */
export const adBoxTextFlows = createSelector([adBoxNumber, components, textFlows], (adBoxNumber, components, textFlows) => {
	if (components && textFlows && textFlows.textFlows) {
		let userFields = [];
		components.variables.filter(v => v.semantic.startsWith("AD_BOX_NUMBER_")).forEach(v => {
			let uf = { "name": v.shortName, "force": true };
			switch (v.semantic) {
				case "AD_BOX_NUMBER_TEXT":
					uf.value = adBoxNumber ? adBoxNumber.text : "";
					break;
				case "AD_BOX_NUMBER_CODE":
					uf.value = adBoxNumber ? adBoxNumber.code : "";
					break;
				default:
					uf.value = "";
					break;
			}
			userFields.push(uf);
		});
		let at = {};
		userFields?.forEach(f => at[f.name] = { "value": f.value?.replace(/\n/mg, "<br>") });
		Object.entries(textFlows.textFlows).forEach(f => {
			if (!at[f[0]]) at[f[0]] = { "value": f[1] };
		});
		return at?.AdBoxCode?.hasOwnProperty("value") || at?.AdBoxNumber?.hasOwnProperty("value") || at?.AdBoxText?.hasOwnProperty("value") ? at : null;
	}
});

export const motifColumns = createSelector([getCreative, selectedTemplate], (creative, selectedTemplate) => {
	return creative.columns || selectedTemplate?.defaultWidth;
});

export const upsellingColumns = createSelector([getCreative], creative => {
	return creative.upsellingColumns;
});

export const isMotifColoured = createSelector([getCreative], creative => {
	return creative.coloured;
});

export const isTermsAndConditionsConfirmed = createSelector([getOrder], order => order.termsAndConditionsConfirmed);

export const bankAccounts = createSelector([businessPartner, bpForModify], (businessPartner, bpForModify) => {
	if (bpForModify?.bankAccounts) {
		return bpForModify?.bankAccounts;
	}
	return businessPartner?.bankAccounts;
});

export const preferredBankAccount = createSelector([bankAccounts], (bankAccounts, order) => {
	if (bankAccounts) {
		let bAccount = bankAccounts.find(it => it.preferred);
		if (!bAccount) {
			bAccount = bankAccounts[0];
		}
		return bAccount;
	}
});

export const invoicesPeriod = createSelector([getUI], ui => {
	console.log('selector', 'invoicesPeriod', ui.customer.invoices.period);
	return ui.customer.invoices.period;
});

// export const invoicesPeriod = state => state.ui.customer.invoices.period;

export const ordersPeriod = state => state.ui.customer.orders.period;

export const ordersLayout = state => state.ui.customer.orders.layout;

export const customerPageTab = state => state.ui.customer.tab;

export const datagridLocaleText = state => state.ui.datagridLocaleText;

export const navigateCreativeNext = state => {
	return !state.ui.upSellingDone &&
		state.common.properties.forceUpselling &&
		state.masterData.upsellingAlternatives?.length ?
		getPage('upselling', DEVICE_MOBILE)
		:
		getPage('offers', DEVICE_MOBILE)
};

export const upsellingDone = state => state.ui.upSellingDone;

export const templatesPerPage = state => state.ui.templatesPerPage;

export const pageDimensions = createSelector([getMasterData], masterData => {
	return masterData ? masterData.pageDimensions : [];
});

export const pageDimension = createSelector([pageDimensions, upsellingAlternatives], (pageDimensions, alternatives) => {
	if (pageDimensions && pageDimensions.length && alternatives && alternatives.length) {
		let pd = pageDimensions.find(it => it._id === alternatives[0].pageDimensionId);
		return pd;
	}
	else {
		return undefined;
	}
});

export const helpUrl = createSelector([device, properties], (device, properties) => {
	return 'mobile' === device ? properties.mobileHelpUrl : properties.helpUrl;
});

export const imprintUrl = createSelector([device, properties], (device, properties) => {
	let u = 'mobile' === device ? properties.mobileImprintUrl : properties.imprintUrl;
	if (!u.startsWith('http')) {
		u = window.location.origin + u;
	}
	return u;
});

export const editions = state => state.masterData.editions;

export const sessionTimeoutSeconds = createSelector([getApi, authentication], (api, authentication) => {
	return api?.sessionTimeoutSeconds || authentication?.sessionTimeoutSeconds;
});

export const isMotifValid = createSelector([selectedTemplate, creativeParams], (selectedTemplate, creativeParams) => {
	if (selectedTemplate && creativeParams) {
		let height = Number(creativeParams['doc-height-1000mm']) / 1000;
		if (height && selectedTemplate.maxHeight && height > selectedTemplate.maxHeight) {
			return false;
		}
	}
	return true;
});

