import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Divider, Stack, Typography, RadioGroup, FormControlLabel, Radio, Snackbar, List,
    ListItem, Backdrop, CircularProgress, FormControl
} from '@mui/material';
import AppHeader from '../../components/AppHeader';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';
import RegistrationPaging from '../components/RegistrationPaging';
import {
    bpResponse, device, paymentTypes, bpForModify,
    isGuestAuthenticated, preferredBankAccount, isWSS,
} from '../../redux/Selectors.js';
import {
    setBpForModify, saveBusinessPartner, getAuthentication, resetBpResponse, setPaymentMethod,
    setAlternativeIban, setAlternativeBic, setUseAlternativeIban, setBankAccount
} from '../../redux/Actions';
import { RadioButtonCheckedIcon, RadioButtonUncheckedIcon } from '../../common/widgetTools';
import { CreateBankAccountSmall } from '../../components/customer/BankAccount';
/**
 * @returns 
 */
export const RegisterPayment = (props) => {

    const windowHeight = window.innerHeight;

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _bpForModify = useSelector((state) => bpForModify(state));
    const _paymentTypes = useSelector((state) => paymentTypes(state));
    const _preferredBankAccount = useSelector((state) => preferredBankAccount(state));
    const _bpResponse = useSelector((state) => bpResponse(state));
    const _device = useSelector((state) => device(state));
    const _isGuestAuthenticated = useSelector((state) => isGuestAuthenticated(state));
    const _isWSS = useSelector((state) => isWSS(state));

    //   const [paymentTypeShortName, setPaymentTypeShortName] = useState('');
    // const [preferredPaymentType, setPreferredPaymentType] = useState({});
    const [bankaccount, setBankaccount] = useState(_preferredBankAccount);
    const [isBankaccountValid, setIsBankaccountValid] = useState(false);
    const [payMethod, setPayMethod] = useState('');
    const [valid, setValid] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [saveMsg, setSaveMsg] = useState('');
    const [isNewBp, setIsNewBp] = useState(true);
    const [saveChangesInOrder, setSaveChangesInOrder] = useState(false);

    useEffect(() => {
        console.log(location.state);
        setIsNewBp(Boolean(!_bpForModify?.businessPartnerNo));
        setPayMethod(_bpForModify?.commercialProps[0]?.preferredPaymentMethod ? _bpForModify?.commercialProps[0].preferredPaymentMethod : _paymentTypes[0]._key);

        window.scrollBy({ left: 0, top: -window.innerHeight * 2, behavior: 'smooth' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log(location.state);
        setSaveChangesInOrder(location?.state?.saveChangesInOrder === true ? true : false);
    }, [location]);

    useEffect(() => {
        const bankaccounts = _bpForModify?.bankAccounts;
        //const payMethod = _bpForModify?.commercialProps[0].preferredPaymentMethod ? _bpForModify?.commercialProps[0].preferredPaymentMethod : _paymentTypes[0]._key;
        //setPreferredPaymentType(findPaymentType(payMethod));
        //   setPaymentTypeShortName(preferredPaymentType?.shortname);
        setPayMethod(_bpForModify?.commercialProps[0]?.preferredPaymentMethod ? _bpForModify?.commercialProps[0].preferredPaymentMethod : _paymentTypes[0]._key);
        setBankaccount(_preferredBankAccount ? _preferredBankAccount : (bankaccounts?.length > 0 ? bankaccounts[0] : {}));
        setIsNewBp(Boolean(!_bpForModify?.businessPartnerNo));
    }, [_bpForModify/*, _preferredBankAccount, _paymentTypes*/]);

    useEffect(() => {
        setValid(!findPaymentType(payMethod)?.ibanPayment || isBankaccountValid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payMethod?.ibanPayment, isBankaccountValid]);

    const handleChanged = (e) => {
        console.log("handleChanged");
        console.log(e.target.value);
        let pt = findPaymentType(e.target.value);
        //       setPaymentTypeShortName(e.target.value);
        if (saveChangesInOrder === true && findPaymentType(e.target.value)?.ibanPayment === true && (bankaccount?.iban === undefined || bankaccount?.iban === "" || bankaccount?.iban === null)) {
            setSaveChangesInOrder(false);
        }
        setPayMethod(e.target.value);
        dispatch(setPaymentMethod(pt.shortname));

    };

    useEffect(() => {
        if (_bpResponse) {
            if (_bpResponse.success) {
                setSaveMsg(isNewBp ? t(_isGuestAuthenticated ? 'm.customerdata.guest.success' : 'm.customerdata.success') : t('m.customerdata.change.success'));
            } else {
                if (_bpResponse.errors && _bpResponse.errors[0] && _bpResponse.errors[0].text && _bpResponse.errors[0].text !== "") {
                    setSaveMsg(_bpResponse.errors[0].text);
                }
            }
            setSnackbarOpen(true);
            setBackdropOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_bpResponse]);

    const closeSnackbarHandler = () => {
        setSnackbarOpen(false);
        if (_bpResponse.success) {
            navigate(isNewBp || _isGuestAuthenticated ? -3 : -1);
            dispatch(getAuthentication());
        }
        dispatch(resetBpResponse());
    }

    const back = () => {
        if (!isWSS) {
            let tmpBpForModify = JSON.parse(JSON.stringify(_bpForModify));
            tmpBpForModify.commercialProps[0].preferredPaymentMethod = payMethod;
            if (findPaymentType(payMethod)?.ibanPayment) {
                tmpBpForModify.bankAccounts.push(bankaccount);
            }
            console.log(tmpBpForModify);
            dispatch(setBpForModify(tmpBpForModify));
        }
        navigate(-1);
    };

    const next = () => {
        if (valid) {
            if (saveChangesInOrder === true) {
                if (findPaymentType(payMethod)?.ibanPayment) {
                    console.log(bankaccount);
                    dispatch(setBankAccount(bankaccount));
                }
                navigate(-1);
            } else if (!_isWSS || _isGuestAuthenticated) {
                setBackdropOpen(true);
                let tmpBpForModify = JSON.parse(JSON.stringify(_bpForModify));
                tmpBpForModify.commercialProps[0].preferredPaymentMethod = payMethod;
                if (findPaymentType(payMethod)?.ibanPayment) {
                    tmpBpForModify.bankAccounts.push(bankaccount);
                }
                if (tmpBpForModify.bankAccounts.length === 0) {
                    let ba = { "iban": undefined, "preferred": false };
                    tmpBpForModify.bankAccounts.push(ba);
                }
                console.log(tmpBpForModify);
                dispatch(setBpForModify(tmpBpForModify));
                console.log("save: " + JSON.stringify(tmpBpForModify));
                if (isNewBp) {
                    dispatch(saveBusinessPartner(tmpBpForModify));
                } else {
                    dispatch(saveBusinessPartner(tmpBpForModify, tmpBpForModify.businessPartnerNo));
                }
            } else {
                console.log("take alternative payment method ...");
                // WSS -> save as alternative iban in order
                if (findPaymentType(payMethod)?.ibanPayment) {
                    console.log("save as alternative iban " + JSON.stringify(bankaccount));
                    dispatch(setUseAlternativeIban(true));
                    dispatch(setAlternativeIban(bankaccount.iban));
                    dispatch(setAlternativeBic(bankaccount.swift));
                    bankaccount._id = 9999;
                    bankaccount.preferred = true;
                    let tmpBpForModify = JSON.parse(JSON.stringify(_bpForModify));
                    if (findPaymentType(payMethod)?.ibanPayment) {
                        tmpBpForModify.bankAccounts.forEach(ba => {
                            ba.preferred = false;
                        });
                        tmpBpForModify.bankAccounts.push(bankaccount);
                    }
                    if (tmpBpForModify.bankAccounts.length === 0) {
                        let ba = { "iban": undefined, "preferred": false };
                        tmpBpForModify.bankAccounts.push(ba);
                    }
                    console.log(tmpBpForModify);
                    dispatch(setBpForModify(tmpBpForModify));
                }
                dispatch(setBankAccount(bankaccount));
                navigate(-1);
            }
        } else {
            //          setShowError(true);
        }

        //       navigate('/webstore/app/m/order'); */
    };

    const findPaymentType = key => _paymentTypes.find(e => e._key === key);

    return (
        <Stack direction="column" alignItems="center" spacing={8}>
            <AppHeader></AppHeader>
            <Stack sx={{ xwidth: '90%', minHeight: windowHeight - 150 }} direction="column" alignItems="left" spacing={5}>
                <RegistrationPaging step={isNewBp ? 3 : 0} pagetitle='m.register.payment.title' />
                <Divider />
                <Typography variant={_device === 'mobile' ? "mobilePageSubTitle" : "pageTitleBold"}>
                    {_device === 'mobile' ? (_isWSS ? t('customer.m.home.payment.alternative.title') : t('customer.m.home.payment.title')) : ''}
                </Typography>
                <FormControl>
                    <RadioGroup
                        alignitems="center"
                        sx={{
                            width: '100%',
                        }}
                        onChange={handleChanged}
                        value={payMethod}
                    >
                        <List

                        >
                            {_paymentTypes.map(it => {
                                return (
                                    <>
                                        <ListItem
                                            variant="outlined"
                                            key={it._key}
                                            sx={{
                                                backgroundColor: it._key === payMethod ? 'primary.main' : 'secondary.main',
                                                borderRadius: '20px',
                                                padding: '0px',
                                                m: 4,
                                                pt: 1,
                                                width: '80vw'
                                            }}
                                        >

                                            <FormControlLabel
                                                control={<Radio
                                                    overlay={true}
                                                    value={it._key}

                                                    checkedIcon={<RadioButtonCheckedIcon />}
                                                    icon={<RadioButtonUncheckedIcon />}
                                                    sx={{
                                                        ml: 4,
                                                        fontSize: 12,
                                                        '&.Mui-checked': {
                                                            color: '#FFFFFF'
                                                        }
                                                    }}
                                                />
                                                }
                                                label={<Typography sx={{ whiteSpace: 'nowrap', color: '#FFFFFF' }} variant='termsAndConditions' >{it.name}</Typography>}
                                                sx={{ width: '80vw', fontSize: 12 }}
                                            />

                                        </ListItem>
                                        {it._key === payMethod && findPaymentType(it._key)?.ibanPayment && (
                                            <CreateBankAccountSmall baccount={bankaccount} valid={setIsBankaccountValid} paymethod={payMethod} readOnly={saveChangesInOrder} />
                                        )}
                                    </>

                                )
                            })}
                        </List>
                    </RadioGroup >
                </FormControl>
            </Stack>
            <NavigationBar
                back={{ clicked: back, label: isNewBp ? t('m.register.payment.back') : (isWSS ? 'customerdata.back' : 'm.register.back') }}
                next={{ clicked: next, label: isNewBp || _isGuestAuthenticated ? t(_isGuestAuthenticated ? 'm.register.payment.guest.save' : 'm.register.payment.save') : (_isWSS || saveChangesInOrder ? 'm.register.take' : 'm.register.save') }}>
            </NavigationBar>
            <InfoText />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => {
                    // setSnackbarOpen(false);
                    closeSnackbarHandler();
                }}
                message={saveMsg}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
                onClick={() => {
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>


        </Stack>
    );
}

export default RegisterPayment;