import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel';
import { useDispatch } from 'react-redux';
import { setEditionPart } from '../../redux/Actions.js';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Input, InputAdornment, IconButton } from '@mui/material';
import { selectedEditionPart, editionParts, device } from '../../redux/Selectors.js';
import { getEditionPartIdForLevel } from '../../common/tools';
import { Stack } from '@mui/material';
import { LabelShrink, DataBold } from '../common/Commons.jsx';
import { CustomExpandMore, EditIcon } from '../../common/widgetTools';
import { getPage, DEVICE_MOBILE } from '../../common/navigationTools.js';


/**
 * 
 * @param {*} props 
 * @returns 
 */
export const RecursiveEditionPartSelect = ({ editionPartsHierarchy, level, edPartId, styleSelect, labelLevel, variant }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const _selectedEditionPart = useSelector((state) => selectedEditionPart(state));
    const _editionParts = useSelector((state) => editionParts(state));

    console.log("edPartId " + edPartId);
    console.log(editionPartsHierarchy);
    const [editionPartId, setEditionPartId] = useState(edPartId === -1 || edPartId === undefined || edPartId === '' ?
        (editionPartsHierarchy.editionParts?.length === 1 ? editionPartsHierarchy.editionParts[0]._id : '') : edPartId);
    //    console.log("initialize editionPartId with " + editionPartId);
    const [localEditionPartHierarchy, setLocalEditionPartHierarchy] = useState(editionPartsHierarchy);
    const [selEditionPart, setSelEditionPart] = useState();
    const [inputLabel, setInputLabel] = useState('');


    useEffect(() => {
        setEditionPartId(edPartId === -1 || edPartId === undefined ?
            (editionPartsHierarchy.editionParts?.length === 1 ? editionPartsHierarchy.editionParts[0]._id : '') : edPartId);

        //       console.log("RecursiveEditionPartSelect - useEffect: level = " + level + " / selEditionPart = " + JSON.stringify(selEditionPart));
        //       console.log("RecursiveEditionPartSelect - useEffect: editionPartId = " + editionPartId + " / editionPartsHierarchy = " + JSON.stringify(editionPartsHierarchy));
        //       console.log("RecursiveEditionPartSelect - useEffect: _selectedEditionPart = " + JSON.stringify(_selectedEditionPart));

        if (_selectedEditionPart?.level > level) {
            let sed = getEditionPartIdForLevel(_editionParts, _selectedEditionPart._id, level);
            //           console.log("RecursiveEditionPartSelect: start with editionPartId " + sed._id);
            initSelection(sed._id);
            //          setStartEditionPartId(sed._id);
        } else if (editionPartsHierarchy.editionParts?.length > 0) {
            if (editionPartsHierarchy.editionParts?.length === 1) {
                initSelection(editionPartId);
                dispatch(setEditionPart(editionPartId));
            } else {
                var selected = editionPartsHierarchy.editionParts?.find(e => e.selected === true);
                setSelEditionPart(selected ? selected : undefined);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        //       console.log("RecursiveEditionPartSelect - useEffect[editionPartsHierarchy]: _selectedEditionPart = " + JSON.stringify(_selectedEditionPart));

        if (editionPartsHierarchy.editionParts?.length > 0 && !(_selectedEditionPart?.level > level)) {
            //           console.log("RecursiveEditionPartSelect - useEffect[editionPartsHierarchy]: level = " + level + " / editionPartsHierarchy = " + JSON.stringify(editionPartsHierarchy));
            setLocalEditionPartHierarchy(JSON.parse(JSON.stringify(editionPartsHierarchy)));
            setEditionPartId(level === 1 ? editionPartId : (editionPartsHierarchy.editionParts?.length === 1 ? editionPartsHierarchy.editionParts[0]._id : ''));
            //           setSelEditionPart(level === 1 ? selEditionPart : undefined);
            var selected = editionPartsHierarchy.editionParts?.find(e => e.selected === true);
            setSelEditionPart(selected ? selected : undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editionPartsHierarchy])

    useEffect(() => {
        setInputLabel(getInputLabel());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editionPartsHierarchy, labelLevel, editionPartId]);

    const handleChange = (event) => {
        setEditionPartId(event.target.value);
        dispatch(setEditionPart(event.target.value));
        var eph = JSON.parse(JSON.stringify(localEditionPartHierarchy));
        clearSelections(eph.editionParts);
        eph.editionParts?.forEach(item => item.selected = item._id === event.target.value ? true : false);
        var sep = eph.editionParts?.find(e => e._id === event.target.value);
        setSelEditionPart(sep);
        /*       console.log("selEditionPart: ");
               console.log(sep);
       
               console.log("setLocalEditionPartHierarchy: ");
               console.log(eph); */

        setLocalEditionPartHierarchy(eph);
    };

    const initSelection = (editionPartId) => {
        setEditionPartId(editionPartId);
        //       dispatch(setEditionPart(event.target.value));
        var eph = JSON.parse(JSON.stringify(localEditionPartHierarchy));
        clearSelections(eph.editionParts);
        eph.editionParts?.forEach(item => item.selected = item._id === editionPartId ? true : false);
        var sep = eph.editionParts?.find(e => e._id === editionPartId);
        setSelEditionPart(sep);
        /*        console.log("init selEditionPart: ");
                console.log(sep);
        
                console.log("init setLocalEditionPartHierarchy: ");
                console.log(eph); */

        setLocalEditionPartHierarchy(eph);
    };


    const getStyleFormControl = (theme) => {
        if (variant === 'mobile') {
            return (
                {
                    m: 1,
                    width: '100%',
                    mt: 5
                });
        } else {
            return (
                {
                    m: 1,
                    width: '100%',
                    mt: 5,
                    '& .MuiInput-root': { mt: '1px !important' },
                    '& .MuiInputLabel-root': { ml: '-14px !important' },
                    '& .selectError': { color: theme.palette.error.main }
                }
            );
        }
    }



    const getInputLabel = () => {
        //       console.log("getInputLabel: editionPartsHierarchy = " + JSON.stringify(editionPartsHierarchy));
        //       console.log("getInputLabel: labelLevel = " + labelLevel);

        let hasSecondLevel = true;
        if (labelLevel === 1) {
            hasSecondLevel = editionPartsHierarchy.editionParts.map(e => e.editionParts?.length > 0 ? true : false)?.includes(true);
            //           console.log("getInputLabel: hasSecondLevel = " + hasSecondLevel);
        }
        if (variant === 'mobile') {
            return (editionPartId === '' ? t('order.selectEditionPart.mobile') : (hasSecondLevel ? t('order.level.mobile') + ' ' + labelLevel : ''));
        } else {
            return (editionPartId === '' ? t('order.selectEditionPart') : (hasSecondLevel ? t('order.level') + ' ' + labelLevel : ''));
        }
    }




    return (
        <>
            <Grid item md={variant === 'vertical' ? 12 : 6} xs={12} >
                <FormControl
                    sx={(theme) => getStyleFormControl(theme)}
                    error={labelLevel === 1 && editionPartId === ''}
                >
                    <InputLabel sx={{ ml: "-14px" }}>{inputLabel}</InputLabel>
                    <Select
                        value={editionPartId}
                        onChange={handleChange}
                        name="editionparts"
                        variant="standard"
                        //   inputComponent={props => CustomSelect(props)}
                        IconComponent={(props) => (<CustomExpandMore {...props} className={labelLevel === 1 && editionPartId === '' ? "selectError" : ""} />)}
                        sx={styleSelect}
                        disableUnderline
                        autoWidth
                    >
                        {localEditionPartHierarchy?.editionParts?.length > 0 &&
                            (localEditionPartHierarchy.editionParts?.map(item =>
                                <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
                            ))}

                    </Select>
                </FormControl>
            </Grid>
            {selEditionPart?.editionParts?.length > 0 ?

                <RecursiveEditionPartSelect
                    editionPartsHierarchy={selEditionPart}
                    level={level + 1}
                    edPartId={selEditionPart.editionParts.find(e => e.selected === true) ? selEditionPart.editionParts.find(e => e.selected === true)._id : ''}
                    styleSelect={styleSelect}
                    variant={variant}
                    labelLevel={labelLevel + 1}
                />
                : <Box></Box>}

        </>
    );

}

export const RecursiveEditionPartSummary = ({ editionPartsHierarchy, level, edPartId, labelLevel, variant }) => {
    const { t } = useTranslation();

    const _selectedEditionPart = useSelector((state) => selectedEditionPart(state));
    const _editionParts = useSelector((state) => editionParts(state));


    //  const [editionPartId, setEditionPartId] = useState(edPartId === -1 || edPartId === undefined ? '' : edPartId);
    const [localEditionPartHierarchy, setLocalEditionPartHierarchy] = useState(editionPartsHierarchy);
    const [selEditionPart, setSelEditionPart] = useState();


    useEffect(() => {
        /*       console.log("RecursiveEditionPartSummary - useEffect: level = " + level + " / selEditionPart = " + JSON.stringify(selEditionPart));
               console.log("RecursiveEditionPartSummary - useEffect: editionPartId = " + editionPartId + " / editionPartsHierarchy = " + JSON.stringify(editionPartsHierarchy));
               console.log("RecursiveEditionPartSummary - useEffect: _selectedEditionPart = " + JSON.stringify(_selectedEditionPart)); */

        if (_selectedEditionPart?.level > level) {
            let sed = getEditionPartIdForLevel(_editionParts, _selectedEditionPart._id, level);
            //           console.log("RecursiveEditionPartSelect: start with editionPartId " + sed._id);
            initSelection(sed._id);
            //          setStartEditionPartId(sed._id);
        } else if (editionPartsHierarchy.editionParts?.length > 0) {
            var selected = editionPartsHierarchy.editionParts?.find(e => e.selected === true);
            setSelEditionPart(selected ? selected : undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const initSelection = (editionPartId) => {
        //       setEditionPartId(editionPartId);
        //       dispatch(setEditionPart(event.target.value));
        var eph = JSON.parse(JSON.stringify(localEditionPartHierarchy));
        clearSelections(eph.editionParts);
        eph.editionParts?.forEach(item => item.selected = item._id === editionPartId ? true : false);
        var sep = eph.editionParts?.find(e => e._id === editionPartId);
        setSelEditionPart(sep);
        /*       console.log("init selEditionPart: ");
               console.log(sep);
       
               console.log("init setLocalEditionPartHierarchy: ");
               console.log(eph); */

        setLocalEditionPartHierarchy(eph);
    };

    return (
        <>
            <Grid item md={variant === 'vertical' ? 12 : 6} xs={12} >
                <Stack>
                    <LabelShrink text={t('order.level') + ' ' + labelLevel} />
                </Stack>
                <DataBold text={selEditionPart?.name} />
            </Grid>
            {selEditionPart?.editionParts?.length > 0 ?

                <RecursiveEditionPartSummary
                    editionPartsHierarchy={selEditionPart}
                    level={level + 1}
                    edPartId={selEditionPart.editionParts.find(e => e.selected === true) ? selEditionPart.editionParts.find(e => e.selected === true)._id : ''}
                    variant={variant}
                    labelLevel={labelLevel + 1}
                />
                : <Box></Box>}

        </>
    );

}

const clearSelections = (eps) => {
    if (eps?.length > 0) {

        eps.forEach(e => {
            e.selected = false;
            if (e.editionParts) clearSelections(e.editionParts);
        });
    }
}

export const RecursiveEditionPartSummaryOneLevel = ({ variant }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const _selectedEditionPart = useSelector((state) => selectedEditionPart(state));
    const _device = useSelector((state) => device(state));

    const LinkToSchedulePage = () => {
        return (<FormControl fullWidth>
            <InputLabel shrink={true} sx={{ ml: "-14px" }}>{t('schedule.editionPart')}</InputLabel>
            <Input
                variant='standard'
                label={t('schedule.editionPart')}
                value={_selectedEditionPart?.name}
                sx={{ width: '100%' }}
                endAdornment={<InputAdornment position='end'><IconButton onClick={() => navigate(getPage('schedule', DEVICE_MOBILE))} sx={{ pr: 0 }}><EditIcon /></IconButton></InputAdornment>}
            />
        </FormControl>);
    };

    return (
        <>
            {_device === 'desktop' && (<DataBold text={_selectedEditionPart ? _selectedEditionPart.name : 'xxx'} />)}
            {_device === 'mobile' && variant === 'linkToSchedulePage' && (<LinkToSchedulePage />)}
        </>
    );

}