import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronRight, faChevronLeft, faAngleDown, faTrashCan, faXmark
} from '@fortawesome/pro-regular-svg-icons';
import {
    faPen, faEye, faEyeSlash, faChevronUp, faChevronDown, faCircleQuestion, faCircleInfo, faGear,
    faRightToBracket, faRightFromBracket, faUser, faStar, faPlus, faCheckCircle, faCircle, faHouse,
    faLockKeyhole, faUserShield, faAddressBook, faMoneyBill, faRectangleAd, faFileInvoice, faBox, faScribble,
    faList, faCircleCheck, faFilePdf, faRepeat, faXmarkCircle, faEllipsis, faGrid, faTable, faPenToSquare, faCheck,
    faDownload, faCircleExclamation
} from '@fortawesome/pro-solid-svg-icons';
/**
 * 
 * @param {*} props 
 * @returns 
 */

export const SwiperNextIcon = (props) => {
    return (< FontAwesomeIcon icon={faChevronRight} size={props.size ? props.size : 'sm'} />);
}

export const SwiperPrevIcon = (props) => {
    return (< FontAwesomeIcon icon={faChevronLeft} size={props.size ? props.size : 'sm'} />);
}

export const DropDownIcon = (props) => {
    return (< FontAwesomeIcon icon={faAngleDown} size={props.size ? props.size : 'sm'} />);
}

export const PenIcon = (props) => {
    return (< FontAwesomeIcon icon={faPen} size={props.size ? props.size : 'sm'} />);
}

export const EyeIcon = (props) => {
    return (< FontAwesomeIcon icon={faEye} size={props.size ? props.size : 'xs'} />);
}

export const EyeSlashIcon = (props) => {
    return (< FontAwesomeIcon icon={faEyeSlash} size={props.size ? props.size : 'xs'} />);
}

export const TrashCanIcon = (props) => {
    return (< FontAwesomeIcon icon={faTrashCan} size={props.size ? props.size : 'xs'} />);
}

export const ClearIcon = (props) => {
    return (< FontAwesomeIcon icon={faXmark} size={props.size ? props.size : 'xs'} />);
}

export const CollapseUpIcon = (props) => {
    return (< FontAwesomeIcon icon={faChevronUp} size={props.size ? props.size : 'sm'} />);
}

export const CollapseDownIcon = (props) => {
    return (< FontAwesomeIcon icon={faChevronDown} size={props.size ? props.size : 'sm'} />);
}

export const HelpIcon = (props) => {
    return (< FontAwesomeIcon icon={faCircleQuestion} size={props.size ? props.size : 'sm'} />);
}

export const InfoIcon = (props) => {
    return (< FontAwesomeIcon icon={faCircleInfo} size={props.size ? props.size : 'sm'} />);
}

export const SettingsIcon = (props) => {
    return (< FontAwesomeIcon icon={faGear} size={props.size ? props.size : 'lg'} />);
}

export const LoginIcon = (props) => {
    return (< FontAwesomeIcon icon={faRightToBracket} size={props.size ? props.size : 'sm'} />);
}

export const LogoutIcon = (props) => {
    return (< FontAwesomeIcon icon={faRightFromBracket} size={props.size ? props.size : 'sm'} />);
}

export const UserIcon = (props) => {
    return (< FontAwesomeIcon icon={faUser} size={props.size ? props.size : 'sm'} />);
}

export const StarIcon = (props) => {
    return (< FontAwesomeIcon icon={faStar} size={props.size ? props.size : 'sm'} />);
}

export const AddIcon = (props) => {
    return (< FontAwesomeIcon icon={faPlus} size={props.size ? props.size : 'lg'} />);
}

export const RadioButtonCheckedIcon = (props) => {
    return (< FontAwesomeIcon icon={faCheckCircle} size={props.size ? props.size : 'lg'} />);
}

export const RadioButtonUncheckedIcon = (props) => {
    return (< FontAwesomeIcon icon={faCircle} size={props.size ? props.size : 'lg'} />);
}

export const HomeIcon = (props) => {
    return (< FontAwesomeIcon icon={faHouse} size={props.size ? props.size : 'xl'} />);
}

export const LockIcon = (props) => {
    return (<FontAwesomeIcon icon={faLockKeyhole} size={props.size ? props.size : 'xl'} />);
}

export const UserShieldIcon = (props) => {
    return (<FontAwesomeIcon icon={faUserShield} size={props.size ? props.size : 'xl'} />);
}

export const AddressBookIcon = (props) => {
    return (<FontAwesomeIcon icon={faAddressBook} size={props.size ? props.size : 'xl'} />);
}

export const MoneyBillIcon = (props) => {
    return (<FontAwesomeIcon icon={faMoneyBill} size={props.size ? props.size : 'xl'} />);
}

export const RectangleAdIcon = (props) => {
    return (<FontAwesomeIcon icon={faRectangleAd} size={props.size ? props.size : 'xl'} />);
}

export const FileInvoiceIcon = (props) => {
    return (<FontAwesomeIcon icon={faFileInvoice} size={props.size ? props.size : 'xl'} />);
}

export const BoxIcon = (props) => {
    return (<FontAwesomeIcon icon={faBox} size={props.size ? props.size : 'xl'} />);
}

export const ScribbleIcon = (props) => {
    return (<FontAwesomeIcon icon={faScribble} size={props.size ? props.size : 'xl'} />);
}

export const ListIcon = (props) => {
    return (<FontAwesomeIcon icon={faList} size={props.size ? props.size : 'sm'} />);
}

export const ReleaseIcon = (props) => {
    return (<FontAwesomeIcon icon={faCircleCheck} size={props.size ? props.size : 'sm'} />);
}

export const PdfIcon = (props) => {
    return (<FontAwesomeIcon icon={faFilePdf} size={props.size ? props.size : 'sm'} />);
}

export const RepeatIcon = (props) => {
    return (<FontAwesomeIcon icon={faRepeat} size={props.size ? props.size : 'sm'} />);
}

export const DeleteIcon = (props) => {
    return (<FontAwesomeIcon icon={faXmarkCircle} size={props.size ? props.size : 'sm'} />);
}

export const EditIcon = (props) => {
    return (<FontAwesomeIcon icon={faPen} size={props.size ? props.size : 'sm'} />);
}

export const MotifEditIcon = (props) => {
    return (<FontAwesomeIcon icon={faPenToSquare} size={props.size ? props.size : 'sm'} />);
}

export const MoreIcon = (props) => {
    return (<FontAwesomeIcon icon={faEllipsis} size={props.size ? props.size : 'sm'} />);
}

export const GridViewIcon = (props) => {
    return (<FontAwesomeIcon icon={faGrid} size={props.size ? props.size : 'sm'} />);
}

export const TableViewIcon = (props) => {
    return (<FontAwesomeIcon icon={faTable} size={props.size ? props.size : 'sm'} />);
}

export const CheckIcon = (props) => {
    return (<FontAwesomeIcon icon={faCheck} size={props.size ? props.size : 'sm'} />);
}

export const DownloadIcon = (props) => {
    return (<FontAwesomeIcon icon={faDownload} size={props.size ? props.size : 'sm'} />);
}

export const WarningIcon = (props) => {
    return (<FontAwesomeIcon icon={faCircleExclamation} size={props.size ? props.size : 'sm'} />);
}


