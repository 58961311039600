import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setCustomerType, initOrder } from '../redux/Actions.js';
import {
    customerType as selectCustomerType,
    customerTypeChecked as selectCustomerTypeChecked
} from '../redux/Selectors.js';

import Switch from '@mui/material/Switch';

import { useTranslation } from 'react-i18next';
import '../configuration/i18n';
import { Stack, Typography } from '@mui/material';
import { properties, device } from '../redux/Selectors.js';

// https://www.digitalocean.com/community/tutorials/redux-reselect

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const CustomerType = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const customerType = useSelector((state) => selectCustomerType(state));
    const customerTypeChecked = useSelector((state) => selectCustomerTypeChecked(state));
    const _properties = useSelector((state) => properties(state));
    const _device = useSelector((state) => device(state));

    const changed = (e) => {
        dispatch(setCustomerType((e.target.checked && _device === 'desktop') || (!e.target.checked && _device !== 'desktop') ? 'business' : 'private'));
    }

    const listenResize = () => {
        if (window.innerHeight < 900) {
            setCustomerType('private');
        }
    };

    useEffect(() => {
        window.addEventListener('resize', listenResize);
        return (() => {
            window.removeEventListener('resize', listenResize);
        });
    }, []);

    useEffect(() => {
        if (customerType) {
            dispatch(initOrder(customerType));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerType])


    const styleSelected = {
        color: _properties.appPrimaryColor,
    };

    const styleUnselected = {
        color: _properties.appPrimaryColor,
    };

    const getStyle = (is, ct) => {
        return is === ct ? styleSelected : styleUnselected;
    };

    return (
        // <Box width="100%" sx={{ alignItems: 'flex-end', justifyContent: 'center' }} display={{ xs: 'none', md: 'flex' }}>
        <Stack
            direction={_device === 'desktop' ? "row" : "column"}
            spacing={5}
            alignItems={_device === 'desktop' ? "center" : "left"}
            sx={_device === 'desktop' ? {} : { width: '90%' }}
        >
            <Typography fontSize={{ xs: 14, lg: 14 }} sx={getStyle('private', customerType)}>{_device === 'desktop' ? t('main.private') : t('main.m.customertype')}</Typography>
            <Switch onChange={(e) => changed(e)} checked={_device === 'desktop' ? customerTypeChecked : !customerTypeChecked} />
            {_device === 'desktop' && (<Typography fontSize={{ xs: 12, lg: 14 }} sx={getStyle('business', customerType)}>{t('main.business')}</Typography>)}
        </Stack>
        // </Box>
    );

}

export default CustomerType;
