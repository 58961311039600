import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../configuration/i18n';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    TextField,
    Stack,
    Tooltip,
    Typography
} from '@mui/material';
import { bankAccountValidation, isWSS, paymentTypes } from '../../redux/Selectors.js';
import { validateBankAccount } from '../../redux/Actions';
import { debounce } from '../../common/tools.js';
import { TrashCanIcon as DeleteIcon, StarIcon } from '../../common/widgetTools';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import i18n, { localeMap, maskMap, formatMap } from '../../configuration/i18n';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const BankAccount = ({ bankAccount, preferredCallback, deleteCallback, test }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [id, setId] = useState();
    const [iban, setIban] = useState('');
    const [ibanHidden, setIbanHidden] = useState('');
    // const [ibanError, setIbanError] = useState('');
    const [bankName, setBankName] = useState('');
    const [mandateValidTo, setMandateValidTo] = useState('');
    const [accountHolder, setAccountHolder] = useState('');
    const [preferred, setPreferred] = useState(false);

    const _bankAccountValidation = useSelector((state) => bankAccountValidation(state));


    useEffect(() => {
        if (bankAccount.iban) {
            setId(bankAccount._id);
            setIban(bankAccount.iban);
            let l = bankAccount.iban.length;
            setIbanHidden(bankAccount.ibanHidden || '-***' + bankAccount.iban.substring(l - 3, l));
            setBankName(bankAccount.bankName);
            setAccountHolder(bankAccount.accountHolder);
            setMandateValidTo(bankAccount.mandateValidTo);
            setPreferred(bankAccount.preferred);
        }
        return (() => {
        });
    }, [bankAccount]);

    // useEffect(() => {
    //     alert(bankAccount);
    // }, [bankAccount]);

    useEffect(() => {
        if (_bankAccountValidation && String(id) === _bankAccountValidation.value._elementId) {
            if (_bankAccountValidation?.success) {
                setIban(_bankAccountValidation.value?.iban);
                setBankName(_bankAccountValidation.value?.bankName);
                //              setIbanError('');
            }
            else {
                setBankName('');
                //              setIbanError(_bankAccountValidation?.errorText);
            }
        }
    }, [_bankAccountValidation]);

    const preferredChanged = value => {
        if (!preferred) {
            setPreferred(!preferred);
            preferredCallback(iban);
        }
    };

    const handleDelete = () => {
        deleteCallback(iban);
    };

    const mandateValidToChanged = value => {
        setMandateValidTo(value);
        bankAccount.mandateValidTo = value;
        bankAccount._modified = true;
    };

    const accountHolderChanged = value => {
        setAccountHolder(value);
        bankAccount.accountHolder = value;
        bankAccount._modified = true;
    };

    const handleIbanChange = value => {
        setIban(value);
        if (value) {
            handleValidation(value);
        }
        else {
            //           setIbanError('');
            setBankName('');
        }
    };

    const handleValidation = debounce((iban) => {
        dispatch(validateBankAccount(iban, id));
    }, 500);

    const Label = ({ text }) => {
        return (
            <Typography
                sx={{ fontSize: 14 }}>
                {t(text)}:
            </Typography>
        );
    }

    const DataBold = ({ text }) => {
        return (
            <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                {text}
            </Typography>
        );
    }

    const mandateValidToColor = new Date(bankAccount.mandateValidTo) < new Date() ? 'error' : '';

    return (
        <Card elevation={4} sx={{ minHeight: "250px" }}>
            <CardHeader title={
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant='cardTitle'>
                        {bankName}
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end">
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                        <Tooltip placement="bottom" arrow title={<Typography>{t('customerdata.bankAccount.preferred.tooltip')}</Typography>}>
                            <IconButton onClick={preferredChanged} sx={{ color: preferred ? 'warning.main' : '' }}>
                                <StarIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>
            }>
            </CardHeader >
            <CardContent>
                <Grid container width='100%' >
                    <Grid item xs={4} >
                        <Label text='customerdata.bankAccount.ibanHidden' />
                    </Grid>
                    <Grid item xs={8}>
                        <DataBold text={ibanHidden} />
                    </Grid>
                    <Grid item xs={4} >
                        <Label text='customerdata.bankAccount.mandateId' />
                    </Grid>
                    <Grid item xs={8}>
                        <DataBold text={bankAccount.mandateId || ''} />
                    </Grid>
                    <Grid item xs={4} >
                        <Label sx={{ color: 'error.main' }} text='customerdata.bankAccount.mandateValidTo' />
                    </Grid>
                    <Grid item xs={8}>
                        {mandateValidTo && (
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={localeMap[i18n.language]}
                            >
                                <DatePicker
                                    format={formatMap[i18n.language]}
                                    mask={maskMap[i18n.language]}
                                    value={new Date(mandateValidTo)}
                                    onChange={(newValue) => {
                                        mandateValidToChanged(newValue);
                                    }}
                                    slotProps={{
                                        textField: {
                                            variant: 'standard',
                                            color: mandateValidToColor,
                                            inputProps: {
                                                sx: {
                                                    color: mandateValidToColor === 'error' ? 'error.main' : ''
                                                }
                                            }
                                        },
                                        openPickerIcon: {
                                            color: mandateValidToColor,
                                        }
                                    }}
                                />
                            </LocalizationProvider>)}
                    </Grid>
                    <Grid item xs={4} >
                        <Label text='customerdata.bankAccount.accountHolder' />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            variant="standard"
                            type="text"
                            value={accountHolder || ''}
                            onChange={e => accountHolderChanged(e.target.value)}
                            adapterlocale={localeMap[i18n.language]}
                        />
                        {/* <DataBold text={accountHolder} /> */}
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'nowrap',
                    flexDirection: 'row',
                    justifyContent: 'flex-end'
                }}
            >
            </CardActions>
        </Card >
    )
}

export const CreateBankAccount = ({ open, closeCallback }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [elementId, setElementId] = useState(String(Math.random()));
    const [iban, setIban] = useState('');
    const [ibanError, setIbanError] = useState('');
    const [bankName, setBankName] = useState('');
    const [accountHolder, setAccountHolder] = useState('');
    const _bankAccountValidation = useSelector((state) => bankAccountValidation(state));

    const isIbanError = Boolean(ibanError);

    useEffect(() => {
        if (_bankAccountValidation && elementId === _bankAccountValidation.value._elementId) {
            if (_bankAccountValidation.success) {
                setIban(_bankAccountValidation.value.iban);
                setBankName(_bankAccountValidation.value.bankName);
                setIbanError('');
            }
            else {
                setBankName('');
                setIbanError(_bankAccountValidation.errorText);
            }
        }
    }, [_bankAccountValidation]);

    const handleClose = () => {
        setElementId(String(Math.random()));
        setIban('');
        setBankName('');
        setAccountHolder('');
        closeCallback();
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        closeCallback({
            _id: null, _created: true, iban, accountHolder, bankName
        });
        setElementId(String(Math.random()));
        setIban('');
        setBankName('');
        setAccountHolder('');
    };

    const handleIbanChange = value => {
        setIban(value);
        if (value) {
            handleValidation(value);
        }
        else {
            setIbanError('');
            setBankName('');
        }
    };

    const handleValidation = debounce((iban) => {
        dispatch(validateBankAccount(iban, elementId));
    }, 500);

    return (
        <Dialog
            PaperProps={{
                sx: {
                    m: 0,
                    position: 'fixed',
                    top: 200
                }
            }}
            maxWidth="lg"
            open={open}
            onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle >{t('customerdata.bankAccount.dialog.title')}</DialogTitle>
                <DialogContent>
                    <Box alignItems="left" sx={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControl sx={{ m: 10 }}>
                            <DialogContentText>
                                {t('customerdata.bankhint')}
                            </DialogContentText>
                            <TextField
                                type="text"
                                autoFocus
                                margin="dense"
                                id="name"
                                label={t('customerdata.bankAccount.iban')}
                                fullWidth
                                variant="standard"
                                required
                                helperText={ibanError}
                                error={isIbanError}
                                value={iban}
                                onChange={e => handleIbanChange(e.target.value)}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 10 }}>
                            <TextField
                                type="text"
                                margin="dense"
                                id="accountHolder"
                                label={t('customerdata.bankAccount.accountHolder')}
                                fullWidth
                                variant="standard"
                                required
                                value={accountHolder}
                                onChange={e => setAccountHolder(e.target.value)}
                            />
                        </FormControl>
                        <FormControl sx={{ m: 10 }}>
                            <TextField
                                margin="dense"
                                id="bankName"
                                label={t('customerdata.bankAccount.bankName')}
                                fullWidth
                                variant="standard"
                                readOnly
                                value={bankName}
                            />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ textTransform: 'none' }} variant="navigationBack" onClick={handleClose}>{t('main.cancel')}</Button>
                    {/* <Button variant="navigationAction" onClick={handleOk}>{t('main.ok')}</Button> */}
                    <Button sx={{ textTransform: 'none' }} variant="navigationAction" type="submit" >{t('main.ok')}</Button>
                </DialogActions>
            </form>
        </Dialog >
    )
};

export const CreateBankAccountSmall = ({ baccount, valid, paymethod, readOnly }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _bankAccountValidation = useSelector((state) => bankAccountValidation(state));
    const _isWSS = useSelector((state) => isWSS(state));
    const _paymentTypes = useSelector(state => paymentTypes(state));

    // eslint-disable-next-line no-unused-vars
    const [elementId, setElementId] = useState(String(Math.random()));
    const [iban, setIban] = useState('');
    const [ibanError, setIbanError] = useState('');
    const [bankName, setBankName] = useState('');
    const [accountHolder, setAccountHolder] = useState('');
    const [bic, setBic] = useState('');
    const [bicRequired, setBicRequired] = useState(false);
    const [selectedPaymentType, setSelectedPaymentType] = useState();
    const [isReadOnly, setIsReadOnly] = useState(false);

    const isIbanError = Boolean(ibanError);

    const findPaymentType = key => _paymentTypes.find(e => e._key === key);


    useEffect(() => {
        if (baccount) {
            setIban(baccount.iban);
            setBankName(baccount.bankName);
            setAccountHolder(baccount.accountHolder);
            setBic(baccount.swift);
        } else {
            setIban('');
            setBankName('');
            setAccountHolder('');
            setBic('');
        }
        setSelectedPaymentType(findPaymentType(paymethod));
        setIsReadOnly(readOnly === true ? true : false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSelectedPaymentType(findPaymentType(paymethod));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymethod]);

    useEffect(() => {
        setBicRequired(isBicRequired(iban, selectedPaymentType))
    }, [iban, selectedPaymentType]);

    useEffect(() => {
        if (_bankAccountValidation && elementId === _bankAccountValidation.value._elementId) {
            if (_bankAccountValidation.success) {
                setIban(_bankAccountValidation.value.iban);
                setBankName(_bankAccountValidation.value.bankName);
                setIbanError('');
                baccount.iban = _bankAccountValidation.value.iban;
                baccount.bankName = _bankAccountValidation.value.bankName;
                baccount._id = null;
                baccount._created = true;
                baccount.ibanHidden = baccount.bankName + "-***" + baccount.iban.substring(baccount.iban.length - 3);
                baccount.swift = bic;
            }
            else {
                setBankName('');
                setIbanError(_bankAccountValidation.errorText);
                baccount.iban = '';
                baccount.bankName = '';
                baccount.swift = '';
                baccount.ibanHidden = '';
            }
        }
    }, [_bankAccountValidation, bic]);

    useEffect(() => {
        baccount.accountHolder = accountHolder;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountHolder])

    useEffect(() => {
        console.log("isIbanError = " + isIbanError);
        if (valid) {
            valid(!isIbanError && !(isBicRequired && bic && bic !== ''));
        }
    }, [ibanError])

    function isBicRequired(iban, selectedPaymentType) {
        if (selectedPaymentType && selectedPaymentType.bicCountryCodes) {
            var requiresBic = selectedPaymentType.bicCountryCodes.map(c => c.toUpperCase());
            return Boolean(iban) && requiresBic.indexOf(iban.substring(0, 2).toUpperCase()) !== -1;
        } else return false;
    }

    const handleIbanChange = value => {
        setIban(value);
        if (value) {
            handleValidation(value);
        }
        else {
            setIbanError('');
            setBankName('');
            setBic('');
        }
    };

    const handleValidation = debounce((iban) => {
        dispatch(validateBankAccount(iban, elementId));
    }, 500);

    const getInputProps = () => {
        return (
            {
                style: { fontSize: 14 }
            }
        )
    }

    const getInputLabelProps = () => {
        return (
            {
                style: { fontSize: 12 }
            }
        )
    }

    return (
        <Box >
            <form>
                <Box alignItems="left" sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                    <FormControl sx={{}}>

                        <TextField
                            type="text"
                            autoFocus
                            margin="dense"
                            id="name"
                            label={t('customerdata.bankAccount.iban')}
                            fullWidth
                            variant="standard"
                            required
                            disabled={isReadOnly}
                            helperText={ibanError}
                            error={isIbanError}
                            value={iban}
                            onChange={e => handleIbanChange(e.target.value)}
                            inputProps={getInputProps()}
                            InputLabelProps={getInputLabelProps()}
                        />
                    </FormControl>
                    {!_isWSS && (<FormControl sx={{}}>
                        <TextField
                            type="text"
                            margin="dense"
                            id="accountHolder"
                            label={t('customerdata.bankAccount.accountHolder')}
                            fullWidth
                            variant="standard"
                            required
                            disabled={isReadOnly}
                            value={accountHolder}
                            onChange={e => setAccountHolder(e.target.value)}
                            inputProps={getInputProps()}
                            InputLabelProps={getInputLabelProps()}
                        />
                    </FormControl>)}
                    <FormControl sx={{}}>
                        <TextField
                            margin="dense"
                            id="bankName"
                            label={t('customerdata.bankAccount.bankName')}
                            fullWidth
                            variant="standard"
                            disabled={true}
                            value={bankName}
                            inputProps={getInputProps()}
                            InputLabelProps={getInputLabelProps()}
                        />
                    </FormControl>
                    {_isWSS && bicRequired && (<FormControl sx={{}}>
                        <TextField
                            type="text"
                            margin="dense"
                            id="accountHolder"
                            label={t('customerdata.bankAccount.swift')}
                            fullWidth
                            variant="standard"
                            required
                            value={bic}
                            onChange={e => setBic(e.target.value)}
                            inputProps={getInputProps()}
                            InputLabelProps={getInputLabelProps()}
                        />
                    </FormControl>)}
                </Box>

            </form>
        </Box >
    )
};
