import React from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MsgPopper from '../components/common/MsgPopper';
import { useTranslation } from 'react-i18next';
import {
    adBoxTextFlows,
    assetBaseUrl,
    components,
    creativeUrl,
    isMotifColoured,
    material,
    motifColumns,
    namedMedia,
    formxUri,
    formxUserFieldValues,
    properties,
    selectedTemplate,
    templateMediaNames,
    textFlows,
    upsellingTextflows,
    onlineId
} from '../redux/Selectors.js';
import {
    addTextFlow,
    setUpsellingImages,
    setUpsellingTextflows,
    clearAlternatives,
    setMotif,
    setStyloDone,
    setStyloSaved,
    setStyloReady,
    setStyloRequest,
    setTemplateUserFields,
    setUserFields,

} from '../redux/Actions.js';

/**
 * 
 * @param {*} props 
 * @returns 
 */
const Stylo = forwardRef(({ eventHandler, motifUuid }, ref) => {
    const ID = 'stylo-frame';

    const style = {
        width: "100%",
        height: "100%",
        border: 'none'
    };

    const { t } = useTranslation();
    const navigate = useNavigate();
    const _adBoxTextFlows = useSelector((state) => adBoxTextFlows(state));
    const _assetBaseUrl = useSelector((state) => assetBaseUrl(state));
    const _selectedTemplate = useSelector((state) => selectedTemplate(state));
    const _components = useSelector((state) => components(state));
    const _isMotifColoured = useSelector((state) => isMotifColoured(state));
    const _motifColumns = useSelector((state) => motifColumns(state));
    const _textFlows = useSelector((state) => textFlows(state));
    const _upsellingTextflows = useSelector((state) => upsellingTextflows(state));
    const _formxUserFieldValues = useSelector((state) => formxUserFieldValues(state));
    const _formxUri = useSelector((state) => formxUri(state));
    const _material = useSelector((state) => material(state));
    const _namedMedia = useSelector((state) => namedMedia(state));
    const _templateMediaNames = useSelector((state) => templateMediaNames(state));
    const _creativeUrl = useSelector((state) => creativeUrl(state));
    const _properties = useSelector((state) => properties(state));
    const _onlineId = useSelector((state) => onlineId(state));

    const styloFrame = useRef(null);
    const [fontPool, setFontPool] = useState(null);
    const dispatch = useDispatch();
    // const [creativeRawtext, setCreativeRawtext] = useState();
    // const [creativeStatistics, setCreativeStatistics] = useState();
    // const [creativeParams, setCreativeParams] = useState();

    const [ready, setReady] = useState(false);

    const [msgOpen, setMsgOpen] = useState(false);
    //   const [placement, setPlacement] = useState('bottom');
    const [msgText, setMsgText] = useState('');
    const [hasCollapseButton, setHasCollapseButton] = useState(false);
    const upsellingTextTimeout = useRef(null);
    const upsellingImageTimeout = useRef(null);

    const placement = 'bottom';

    // https://dokuwiki.alfa.de/doku.php?id=de:stylo:postmethodguide
    const eventListener = e => {
        if (e.data.method) {
            console.log(`method=${e.data.method}`);
            switch (e.data.method) {
                default:
                    break;
            }
        }
    };

    useEffect(() => {
        window.addEventListener('message', eventListener);
        return () => {
            window.removeEventListener('message', eventListener);
        };
    }, []);

    useEffect(() => {
        return (() => {
            window.clearTimeout(upsellingTextTimeout.current);
            window.clearTimeout(upsellingImageTimeout.current);
            dispatch(setStyloDone()); // remove progress bar on page change (e.g. to customer area)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (_onlineId) {
            dispatch(setUserFields([{
                name: 'OnlineId',
                force: true,
                value: _onlineId ? _onlineId.text : ""
            }]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_onlineId]);

    /**
     * Initialize stylo with new template
     */
    useEffect(() => {
        if (_components) {
            //            dispatch(initCreative());
            eventHandler('loading');
            dispatch(setStyloRequest());
            if (_components.galleryPools) {
                let fontPools = _components.galleryPools.find(p => p.poolType === "font");
                if (fontPools && fontPools.logoPools && fontPools.logoPools.length) {
                    let fontPool = fontPools.logoPools[0].shortName;
                    setFontPool(fontPool);
                    if (stylo() && stylo().styloModify) {
                        stylo().styloModify("setFontPool", fontPool);
                    }
                }
            }

            let odt = `/webstore/styloproxy/Stylo.html?noCtrlS=true&doc=${encodeURIComponent(_creativeUrl)}`;
            const mobile = _properties.styloMenu === 'mobile';
            if (mobile) {
                odt += '&mobile';
            }
            odt += `&design=${_properties.styloDesign}`;
            styloFrame.current.src = odt;
            dispatch(clearAlternatives());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_components]);

    const injectText = tf => {
        let at = {};
        if (tf && tf.textFlows) {
            Object.entries(tf.textFlows).forEach(f => {
                if (!at[f[0]]) at[f[0]] = { "value": f[1] };
            });
            console.log("injectText " + JSON.stringify(at));
            stylo().styloModify("setAbstractTexts", JSON.stringify(at));
        }
    };

    /**
     * Inject textflows stylo document
     */
    useEffect(() => {
        console.log("useEffect _textFlows ...");
        if (_textFlows && stylo()) {
            if (stylo().styloModify) {
                injectText(_textFlows);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_textFlows]);

    /**
     * Inject formx field values and images into stylo document
     */
    useEffect(() => {
        if (stylo() && stylo().styloModify && ready) {
            if (_formxUserFieldValues && _formxUserFieldValues.UserFields) {
                if (stylo().styloModify) {
                    // Textflows
                    let at = {};
                    _formxUserFieldValues.UserFields.forEach(f => {
                        at[f.name] = { "value": f.value.replace(/\n/mg, "<br>") }
                    });
                    // ??
                    // if (this.allTextFlows && this.allTextFlows.textFlows) Object.entries(this.allTextFlows.textFlows).forEach(f => {
                    //     if (!at[f[0]]) at[f[0]] = { "value": f[1] };
                    // });
                    stylo().styloModify("setAbstractTexts", JSON.stringify(at));
                }
                if (_material) {        // Bilder 
                    _templateMediaNames.forEach(n => {
                        let m = _material.find(e => n === e.formxWidgetName);
                        if (m) {
                            stylo().styloModify("importNamedImage", JSON.stringify({ "name": n, "url": m._links.material.href }));
                        }
                        let org = _namedMedia ? _namedMedia.find(e => e.getAttribute("name") === n) : null;
                        if (m) {
                            org.setAttribute("dummy", "false");
                        }
                        else if (org.getAttribute("dummy") !== "true") {
                            //org.setAttribute("dummy", "true");
                            stylo().styloModify("importNamedImage", JSON.stringify({ "name": n, "url": null }));
                        }
                    });
                }
            }
        }
    }, [_formxUserFieldValues, _material, _namedMedia, _templateMediaNames, ready]);

    /**
  * Inject adbox-fields into stylo document
  */
    useEffect(() => {
        console.log("useEffect _adBoxTextFlows, _selectedTemplate" + JSON.stringify(_adBoxTextFlows));
        if (_adBoxTextFlows && stylo()) {
            if (stylo().styloModify) {
                stylo().styloModify("setAbstractTexts", JSON.stringify(_adBoxTextFlows));
            }
        }
    }, [_adBoxTextFlows, _selectedTemplate]);

    /**
     * Adjust motif to to new width
     */
    useEffect(() => {
        if (_motifColumns && stylo() && _selectedTemplate) {
            if (stylo().styloModify) {
                stylo().styloModify("setWidth", String((_selectedTemplate.mmWidths[_motifColumns - _selectedTemplate.minWidth]) * 1000));
            }
        }
    }, [_motifColumns, _selectedTemplate]);

    /**
     * Toggle motif colouring
     */
    useEffect(() => {
        if (stylo() && _selectedTemplate) {
            if (stylo().styloModify) {
                stylo().styloModify("setColor", String(_isMotifColoured));
            }
        }
    }, [_isMotifColoured, _selectedTemplate]);

    /**
     * Inject formx images into stylo document
     */
    // useEffect(() => {
    //     if (_formxUserFieldValues && _formxUserFieldValues.UserFields && stylo()) {
    //         if (stylo().styloModify) {

    //             // Bilder 
    //             _templateMediaNames.forEach(n => {
    //                 let m = _material.find(e => n === e.formxWidgetName);
    //                 if (m) {
    //                     stylo().styloModify("importNamedImage", JSON.stringify({ "name": n, "url": m._links.material.href }));
    //                 }
    //                 let org = _namedMedia ? _namedMedia.find(e => e.getAttribute("name") == n) : null;
    //                 if (m) {
    //                     org.setAttribute("dummy", "false");
    //                 }
    //                 else if (org.getAttribute("dummy") != "true") {
    //                     stylo().styloModify("importNamedImage", JSON.stringify({ "name": n, "url": null }));
    //                 }
    //             });
    //         }
    //     }
    // }, [_material]);

    useImperativeHandle(ref, () => ({ validate, save, getTextflows, isComplete, setCollapseButton, setPdfButton }));

    const stylo = () => {
        if (styloFrame &&
            styloFrame.current &&
            styloFrame.current.contentWindow) {
            return styloFrame.current.contentWindow;
        }
        else {
            return undefined;
        }
    }

    const setPdfButton = () => {
        stylo().styloModify("attachButton", JSON.stringify([{ "icon": 'lightFilePdf', "toggleicon": 'lightFilePdf', "id": "filepdf" }]));
    }

    const setCollapseButton = (down) => {
        if (down === true) {
            console.log("set stylo button down");
            //           stylo().styloModify("attachButton", JSON.stringify([{ "icon": '', "toggleicon": '', "id": "resize" }]));
            if (hasCollapseButton) {
                stylo().styloModify("setAttachButton", JSON.stringify([{ "id": "resize", "state": true }]));
            } else {
                stylo().styloModify("attachButton", JSON.stringify([{ "icon": 'lightChevronDown', "toggleicon": 'lightChevronUp', "id": "resize" }]));
                setHasCollapseButton(true);
            }
        } else {
            console.log("set stylo button up");
            //           stylo().styloModify("attachButton", JSON.stringify([{ "icon": '', "toggleicon": '', "id": "resize" }]));
            if (hasCollapseButton) {
                stylo().styloModify("setAttachButton", JSON.stringify([{ "id": "resize", "state": false }]));
            } else {
                stylo().styloModify("attachButton", JSON.stringify([{ "icon": 'lightChevronUp', "toggleicon": 'lightChevronDown', "id": "resize" }]));
                setHasCollapseButton(true);
            }
        }
    }

    const isComplete = () => {
        var cs = JSON.parse(stylo().styloData("getStatistics"));
        if (cs["has-dummy-text"] === "true" || cs["has-dummy-image"] === "true") {
            setMsgText(t('creative.contentInputRequired'));
            setMsgOpen(true);
            return false;
        }
        if (cs["overflow"] === "true") {
            setMsgText(t('creative.overflow'));
            setMsgOpen(true);
            return false;
        }
        if (cs["image-below-min-resolution"] === "true") {
            setMsgText(t('creative.imageBelowMinResolution'));
            setMsgOpen(true);
            return false;
        }
        return true;
    };

    const save = (route) => {
        dispatch(setStyloRequest());
        getTextflows();
        updateUpsellingTextflows();
        let id = `${_assetBaseUrl}/${motifUuid}`;
        console.log(`Stylo.save() id=${id}`);
        if (route) {
            stylo().styloSave(id, function (success) {
                dispatch(setStyloDone());
                if (success) {
                    dispatch(setStyloSaved(true));
                    navigate(route);
                }
            });
        }
        else {
            return new Promise((resolve, reject) => {
                stylo().styloSave(id, function (success) {
                    dispatch(setStyloDone());
                    if (success) {
                        dispatch(setStyloSaved(true));
                        resolve(id);
                    }
                });
            });
        }
    };

    function handleMouseMove(ev) {
        if (msgOpen === true) {
            setMsgOpen(false);
        }
    }

    const validate = () => {
        alert('validate');
    };

    const hasHiddenFormx = () => {
        return _selectedTemplate && _selectedTemplate.creativeDefaultLayout === 4 && _selectedTemplate.creativeLayouts[0] === 4 && _selectedTemplate.formxName;
    }

    const isStructure = () => {
        return Boolean(_formxUri) && !hasHiddenFormx();
    };

    const getTextflows = () => {
        if (isStructure()) {
            return;
        }
        if (stylo().styloData) {
            const command = "getNamedTextflows";
            //            const command = styloFrame.current.src.endsWith("motif.odt") ? "getNamedTextflows" : "getUpdatedTextflows";
            let data = stylo().styloData(command, JSON.stringify({ withUserFields: true, userFieldsRefOnly: true }));
            if (data) {
                console.log("addTextFlow");
                dispatch(addTextFlow(JSON.parse(data)));
            }
        }
    };

    const setLicense = () => {
        let now = new Date();
        let lCode = now.getTime() - 123000987000;
        let lCodeStr = String(lCode).split("").reverse().join("");
        let lCodeLen = lCodeStr.length;
        let hCode = now.getTime() + 456000654000;
        let hCodeLen = String(hCode).length;
        let internalStr = "WebStore:::" + lCodeLen + "" + lCodeStr + "" + hCodeLen + "" + hCode;
        stylo().styloModify("setLRights", internalStr);
    };

    const getCreativeParams = () => {
        if (stylo().styloData) {
            var cs = stylo().styloData("getStatistics");
            var cr = stylo().styloData("getRawText");
            if (cr) cr = cr.trim().replace(/[\s\r\n]+/g, " ");
            if (_selectedTemplate.textLeadFieldName) {
                var ufs = stylo().styloData("getUserFields");
                var uf = JSON.parse(ufs).UserFields.find(f => f.name === _selectedTemplate.textLeadFieldName);
                var raw = uf ? uf.value : null;
                if (!raw) {
                    var tfs = stylo().styloData("getNamedTextflows", JSON.stringify({ "withUserFields": true, "userFieldsRefOnly": true }));
                    raw = JSON.parse(tfs).textFlows[_selectedTemplate.textLeadFieldName];
                }
                if (raw) cr = raw.replace(/<\/p>/g, " ").replace(/<.*?>/g, "").trim().replace(/[\s\r\n]+/g, " ");
            }
            if (_selectedTemplate.textLeadCharCount) {
                cr = cr.substring(0, _selectedTemplate.textLeadCharCount);
            }
            dispatch(setMotif(JSON.parse(cs), cr, null));
        }
    };

    /**
     */
    const updateUpsellingTextflows = () => {
        let f = stylo().styloData;
        if (f) {
            let data = f("getNamedTextflows", JSON.stringify({ withUserFields: true, userFieldsRefOnly: true }));
            if (data) {
                dispatch(setUpsellingTextflows(JSON.parse(data)));
                getCreativeParams();
            }
        }
    };

    /**
     */
    const updateUpsellingImages = () => {
        let f = stylo().styloData;
        if (f) {
            let data = f("getNamedImages");
            if (data) {
                dispatch(setUpsellingImages(JSON.parse(data)));
            }
        }
    };

    /**
     * Callback function to handle stylo events
     * 
     * @param {*} type 
     * @param {*} command 
     * @param {*} arg0 
     * @param {*} arg1 
     * 
     * see: https://dokuwiki.alfa.de/doku.php?id=de:stylo:interfaceguide
     * 
     * @returns 
     */
    const styloCallback = (type, command, arg0, arg1) => {
        switch (type) {
            case 'infoEvent':
                switch (command) {
                    case 'showDocument':
                        eventHandler('loaded');
                        dispatch(setStyloDone());
                        dispatch(setStyloReady(true));
                        console.log(`TODO styloCallback: type=${type} command=${command}`);
                        setReady(true);
                        // self.styloReady = true;
                        // self.$.stylo.style.opacity = 1;
                        // self.spinner(false);
                        // self.dispatch(self.actions.book.fetchTemplateAlternatives(self.selectedMarket));

                        // if (self.orderToRepeat && self.orderToRepeat.adBoxNumber) {
                        //     console.log("order to repeat: do not initialize adbox number");
                        // } else {
                        //     self.adBox = null;
                        // }

                        setLicense();

                        if (_textFlows) {
                            stylo().styloModify("updateTextflows", "");
                        }
                        // setTimeout(function () {
                        //     self.creativeParams = self.creativeRawtext = self.creativeStatistics = null;
                        //     self.getCreativeParams.call(self);
                        // }, 100);
                        dispatch(setUserFields());
                        // if (self.isAdBox.call(self, self.selectedTemplate) == "inline-block") self.componentsLoaded.call(self);
                        // if (!self.isWSS) self.$.stylo.contentWindow.styloModify("setColor", String(self.selectedTemplate && self.selectedTemplate.defaultColorType != "blackAndWhite"));
                        updateUpsellingTextflows();
                        dispatch(setTemplateUserFields(stylo().styloData("getAllUserFields")));
                        getCreativeParams();

                        console.log("inject Text _upsellingTextFlows");
                        injectText(_upsellingTextflows);
                        if (_adBoxTextFlows && stylo()) {
                            if (stylo().styloModify) {
                                stylo().styloModify("setAbstractTexts", JSON.stringify(_adBoxTextFlows));
                            }
                        }
                        if (_motifColumns) {
                            stylo().styloModify("setWidth", String((_selectedTemplate.mmWidths[_motifColumns - _selectedTemplate.minWidth]) * 1000));
                        }
                        break;
                    case 'textModified':
                        if (upsellingTextTimeout.current) {
                            window.clearTimeout(upsellingTextTimeout.current);
                        }
                        upsellingTextTimeout.current = window.setTimeout(updateUpsellingTextflows, 2000);
                        break;
                    case 'imageModified':
                        // SUP-44445 Alternative bekommt kein Bildupdate                     
                        if (upsellingImageTimeout.current) {
                            window.clearTimeout(upsellingImageTimeout.current);
                        }
                        upsellingImageTimeout.current = window.setTimeout(updateUpsellingImages, 2000);
                        break;
                    default:
                        break;
                }
                break;
            case 'galleryPool':
                switch (command) {
                    case 'getNames':
                    case 'getContent':
                        console.log(`TODO styloCallback: type=${type} command=${command}`);
                        break;
                    case 'getCurrent':
                        return fontPool;
                    default:
                        break;
                }
                break;
            case 'textInjection':
                switch (command) {
                    case 'getTextflow':
                        return _textFlows && _textFlows.textFlows ? _textFlows.textFlows[arg0] : null;
                    case 'getValue':
                        let ret = null;
                        if (arg0 === 'OnlineId' && _onlineId) {
                            ret = _onlineId.text;
                        }
                        console.log("onlineId = " + ret);
                        return ret;
                    default:
                        break;
                }
                break;
            case 'authority':
                switch (command) {
                    case 'getCurrent':
                        return _selectedTemplate ? _selectedTemplate.styloOnlineAuthority : null;
                    default:
                        break;
                }
                break;
            case 'changeEvent':
                console.log(command);
                console.log(arg0);
                switch (command) {
                    case 'attachButton':
                        const buttonEvent = JSON.parse(arg0);
                        eventHandler(buttonEvent.id, { 'expand': !buttonEvent.state });
                        break;
                    default:
                        getCreativeParams();
                }
                break;
            default:
                break;
        }
    };

    const styloLoaded = () => {
        stylo().styloCallback = styloCallback;
    };

    if (!_selectedTemplate) {
        return null;
    }

    return (
        <Box sx={{ height: '100%' }} onMouseMove={(ev) => handleMouseMove(ev)}>
            <iframe title="stylo" ref={styloFrame} id={ID} style={style} onLoad={styloLoaded} ></iframe>
            <MsgPopper
                open={msgOpen}
                anchorRef={styloFrame}
                placement={placement}
                arrow={true}
                text={msgText}
            >
            </MsgPopper>
        </Box >
    );

});

export default Stylo;
