import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { properties } from '../redux/Selectors.js';
import {
    Box
} from '@mui/material';
import '../configuration/i18n';

const AppLogo = () => {

    const _properties = useSelector(state => properties(state));

    return (
        <Box sx={{ backgroundCcolor: _properties.logoBackgroundColor }}>
            <img style={{ maxHeight: 50 }} alt="{_appTitle}" src="/webstore/service/v1/masterdata/resources/image/logo.png"></img>
        </Box>
    );
}

export default AppLogo;