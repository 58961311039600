import React from 'react';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';


import { useTranslation } from 'react-i18next';
import '../../configuration/i18n';
import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField
} from '@mui/material';

import { device, isWSS, isGuestAuthenticated } from '../../redux/Selectors';
import { CustomExpandMore, TrashCanIcon } from '../../common/widgetTools';
import { DEVICE_MOBILE } from '../../common/navigationTools';


/**
 * 
 * @param {*} props 
 * @returns 
 */
export const TeleComm = forwardRef(({ item, valid, bpNew, onDelete, onChange, index }, ref) => {
    const { t } = useTranslation();

    const _device = useSelector((state) => device(state));
    const _isWSS = useSelector(state => isWSS(state));
    const _isGuestAuthenticated = useSelector(state => isGuestAuthenticated(state));

    const [mobilityType, setMobilityType] = useState(_device === DEVICE_MOBILE ? 'mobile' : '');
    const [internationalAreaCode, setInternationalAreaCode] = useState('');
    const [areaCode, setAreaCode] = useState('');
    const [subscriberCode, setSubscriberCode] = useState('');
    const [showError, setShowError] = useState(false);
    const [formIsReadOnly, setFormIsReadOnly] = useState(false);

    useImperativeHandle(ref, () => ({
        showErrors: () => {
            setShowError(true);
        }
    }));

    useEffect(() => {
        item.valid = mobilityType !== '' && /*internationalAreaCode !== '' || */ areaCode !== '' && subscriberCode !== '';
        onChange(item, index);
    }, [internationalAreaCode, areaCode, subscriberCode]);

    useEffect(() => {
        setMobilityType(item.mobilityType);
        setInternationalAreaCode(item.internationalAreaCode);
        setAreaCode(item.areaCode);
        setSubscriberCode(item.subscriberCode);
        setFormIsReadOnly(_isWSS && !_isGuestAuthenticated)
    }, []);

    const handleTypeChanged = event => {
        setMobilityType(event.target.value);
        item.mobilityType = event.target.value;
    };

    const internationalAreaCodeChanged = (value) => {
        setInternationalAreaCode(value);
        item.internationalAreaCode = value;
    };

    const areaCodeChanged = (value) => {
        setAreaCode(value);
        item.areaCode = value;
    };

    const subscriberCodeChanged = (value) => {
        setSubscriberCode(value);
        item.subscriberCode = value;
    };

    const handleDelete = (e) => {
        onDelete(item);
    }



    return (<Stack
        direction="row"
        alignItems="left"
        spacing={_device === 'mobile' ? 3 : '2em'}
        sx={{ mt: _device === 'mobile' ? 1 : 2 }}
    >
        {_device === 'desktop' && (<FormControl variant='standard' sx={{ minWidth: '6em' }} >
            <InputLabel id="type-label">{t('customerdata.contact.mobilityType.title')}</InputLabel>
            <Select
                id="type-select"
                labelId="type-label"
                value={mobilityType}
                error={showError && mobilityType === ''}
                // inputProps={{ readOnly: protectedFromChange }}
                IconComponent={CustomExpandMore}
                onChange={handleTypeChanged}
            >
                <MenuItem value="fixed">{t('customerdata.contact.mobilityType.fixed')}</MenuItem>
                <MenuItem value="mobile">{t('customerdata.contact.mobilityType.mobile')}</MenuItem>
            </Select>
        </FormControl>)}


        <FormControl sx={{ minWidth: _device === 'mobile' ? '10%' : '6em' }}>
            <TextField
                variant="standard"
                label={_device === 'mobile' ? t('customer.m.home.contact.internationalAreaCode') : t('customerdata.contact.internationalAreaCode')}
                type="tel"
                required
                error={showError && internationalAreaCode === ''}
                value={internationalAreaCode}
                onChange={e => internationalAreaCodeChanged(e.target.value)}
                disabled={formIsReadOnly}
            />
        </FormControl>
        <FormControl sx={{ minWidth: _device === 'mobile' ? '10%' : '6em', maxWidth: '6em' }} >
            <TextField
                variant="standard"
                label={t('customerdata.contact.areaCode')}
                type="tel"
                required
                error={showError && areaCode === ''}
                value={areaCode}
                onChange={e => areaCodeChanged(e.target.value)}
                disabled={formIsReadOnly}

            />
        </FormControl>
        <FormControl sx={{ minWidth: _device === 'mobile' ? '30%' : '10em' }}>
            <TextField
                variant="standard"
                label={t('customerdata.subscriber')}
                type="text"
                required
                value={subscriberCode}
                error={showError && subscriberCode === ''}
                onChange={e => subscriberCodeChanged(e.target.value)}
                disabled={formIsReadOnly}
            />
        </FormControl>
        {!_isWSS ? (<IconButton onClick={handleDelete} >
            <TrashCanIcon />
        </IconButton>) : (<></>)}
    </Stack >
    );

});

export default TeleComm;

