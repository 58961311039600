import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { selectedMarket, properties } from '../redux/Selectors.js';
import { urlIsSvg, SVGIcon, readSVG } from '../common/SvgTools.js';

export const MarketLink = React.forwardRef((props, ref) => {
    const { t } = useTranslation();
    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _properties = useSelector((state) => properties(state));
    const [favouriteXml] = useState('');
    const [isSVG, setIsSVG] = useState(false);
    const [svgMarket, setSVGMarket] = useState({});
    const hrefFavourits = '/webstore/resources/images/all-markets.svg';

    useEffect(() => {
        isSVGIcon();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isSVG === true) {
            createSVG();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.market, isSVG]);

    async function isSVGIcon() {
        let isSvgIcon = await urlIsSvg(props?.market?._links ? props.market._links.marketIcon.href : hrefFavourits)
        setIsSVG(isSvgIcon);
    }

    async function createSVG() {
        let m = props?.market;
        if (m?._links) {
            m.svg = await readSVG(props.market._links.marketIcon.href);
        } else {
            m.svg = await readSVG(hrefFavourits);
        }
        setSVGMarket(m);
    }



    const MarketSvgIcon = ({ market, selected, properties }) => {

        const color = selected && selected._id === market._id ? properties.appPrimaryColor : properties.appPrimaryColor;
        if (market?.svg) {
            market.svg.fill = color;
            market.svg.name = market.name;
        }
        console.log(color);
        console.log(market?.svg);

        const MSvgIcon = (props) => {
            //       return <></>
            return <SVGIcon svg={market.svg} {...props} />;
        }

        return market?.svg ?
            //    (<img style={{ height: "2rem", fill: color }} src={href} alt={market.name}></img>)
            (<MSvgIcon sx={{ fontSize: "2rem", pr: 2 }} />)
            :
            (<></>);
    };



    const getButtonStyle = (m, e) => {
        let _m = m || { _id: 999999 };
        let transform, borderBottom;
        if (_m && e && _m._id === e._id) {
            transform = 'none';
            borderBottom = `solid ${_properties.appPrimaryColor} 3px`
        }
        else {
            transform = 'none';
        };
        return {
            color: _properties.appPrimaryColor,
            textTransform: transform,
            '& .MuiButtonBase-root': {
                p: 0,
                minWidth: 30,
                mt: 2
            },
            borderBottom: borderBottom,
            mb: -1.5,
            mt: 2
        };
    };

    const getButtonIcon = (e) => {
        let href;
        if (e && e._links) {
            href = e._links.marketIcon.href;
        } else {
            if (favouriteXml !== '') {
                href = favouriteXml;
            } else {
                href = '/webstore/resources/images/all-markets.svg'
            }
        }
        return href;
    };

    return (
        <Box sx={getButtonStyle(_selectedMarket, props.market)} >
            <Button
                ref={ref}
                onClick={(e) => { props.marketAction(props.market); }}
                sx={{ backgroundColor: "_properties.logoBackgroundColor", color: "_properties.appEmphasizedTextColor", fontSize: 14, mt: 10, mb: 5, ml: 5, mr: 5 }}
                id={props.market._id}
                size="normal"
                variant="text"
            >
                {isSVG === false && (<img alt={props.market.name}
                    style={{ marginLeft: 8, marginRight: 8, verticalAlign: 'middle', width: 24, height: 24, backgroundColor: _properties.logoBackgroundColor }}
                    src={getButtonIcon(props.market)}
                ></img>)}
                {isSVG === true && (<MarketSvgIcon market={svgMarket} selected={_selectedMarket} properties={_properties} />)}
                <Box display={{ xs: 'none', md: 'block' }} sx={{ ml: 1, fontSize: 16 }}>{
                    props.market.shortName === 'ALL_MARKETS' ? t('marketpicker.allMarkets') : props.market.name}
                </Box>
            </Button>
        </Box>
    );
});

export default React.forwardRef((props, ref) => <MarketLink {...props} forwardedRef={ref} />);