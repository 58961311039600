import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n, { localeMap, formatMap } from '../../configuration/i18n';
import {
    Card, CardContent, FormControl, Grid, Typography, Box, Stack, TextField,
    Button, Checkbox, Switch, Collapse, Select, MenuItem, InputLabel
} from '@mui/material';
import { TextareaAutosize } from '@mui/material';
import AreaCardHeader from '../common/AreaCardHeader';
import { OrderCriteriaArea } from './OrderCriteriaArea';
import { Label } from '../common/Commons';
import { AlternativeContacts } from '../customer/AlternativeContacts';
import {
    properties, selectedOffer, orderPriceResponse, defaults, features, isCicAuthenticated,
    orderTypes, pendingOrder, businessPartner
} from '../../redux/Selectors';
import {
    setOrderIsCombi, setHasAlternativeAddress, setOrderType, setOrderFixPrice,
    setPriceCalculation, setOrderComment, setVoucher, setServicePackageId
} from '../../redux/Actions.js';
import { CustomExpandMore } from '../../common/widgetTools';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const AdditionalDataCard = forwardRef(({ height }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _properties = useSelector((state) => properties(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const _defaults = useSelector((state) => defaults(state));
    const _features = useSelector((state) => features(state));
    const _isCicAuthenticated = useSelector((state) => isCicAuthenticated(state));
    const _orderTypes = useSelector((state) => orderTypes(state));
    const _order = useSelector((state) => pendingOrder(state));
    const _businessPartner = useSelector((state) => businessPartner(state));
    const _orderPriceResponse = useSelector((state) => orderPriceResponse(state));

    const [isOrderVoucherEnabled, setIsOrderVoucherEnabled] = useState(false);
    const [ordVoucherValue, setOrdVoucherValue] = useState('');
    const [isVoucherFieldEnabled, setIsVoucherFieldEnabled] = useState(true);
    const [isVoucherButtonEnabled, setIsVoucherButtonEnabled] = useState(false);
    const [combiChecked, setCombiChecked] = useState(false);
    const [ordType, setOrdType] = useState('');
    const [priceCalc, setPriceCalc] = useState('regular');
    const [fixPrice, setFixPrice] = useState('');
    const [isFixPrice, setIsFixPrice] = useState(false);
    const [ordComment, setOrdComment] = useState('');
    const [voucherErrorMsg, setVoucherErrorMsg] = useState('');


    console.log(_properties);
    console.log(_selectedOffer);

    useEffect(() => {
        // sapCombination
        console.log("_order?.orderIsCombi  = " + _order?.orderIsCombi);
        var checked = _selectedOffer?.sapCombination && (_order?.orderIsCombi || _selectedOffer?.sapDefault || _selectedOffer?.sapForce);
        /*       if (this.orderToRepeat) {
                   if (this.pendingOrder && this.pendingOrder.orderIsCombi) {
                       checked = true;
                   }
               } */
        dispatch(setOrderIsCombi(checked));
        setCombiChecked(checked);

        // voucher
        setOrdVoucherValue(_order?.voucher ? _order.voucher : _defaults.voucher);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsOrderVoucherEnabled(_selectedOffer?.orderVoucher)
    }, [_selectedOffer]);

    useEffect(() => {
        setIsVoucherButtonEnabled(isVoucherFieldEnabled && Boolean(ordVoucherValue));
    }, [ordVoucherValue, isVoucherFieldEnabled])

    useEffect(() => {
        if (_orderPriceResponse) {
            if (_orderPriceResponse.success) {
                setVoucherErrorMsg('');
            }
            else {
                // ASE-395: Einloesen von Gutschein, etc
                //   let priceMessage = t('main.commonFailure') + '<br/>';
                let priceMessage = '';
                if (_orderPriceResponse.errors && ordVoucherValue && ordVoucherValue !== '') {
                    priceMessage += _orderPriceResponse.errors.map(m => m.text)[0];
                }
                setVoucherErrorMsg(priceMessage);
            }
        }
    }, [_orderPriceResponse]);


    const handleVoucherChange = (event) => {
        setVoucherErrorMsg('');
        setOrdVoucherValue(event.target.value);
        dispatch(setVoucher());
    }

    const cashVoucher = (event) => {
        setIsVoucherFieldEnabled(false);
        dispatch(setVoucher(ordVoucherValue));
    }

    const handleCombiChange = (event) => {
        setCombiChecked(event.target.checked);
        dispatch(setOrderIsCombi(event.target.checked));
    };

    useImperativeHandle(ref, () => ({ setVoucherFieldEnabled }));


    const setVoucherFieldEnabled = (isEnabled) => {
        setIsVoucherFieldEnabled(isEnabled);
    }

    const [showAltContacts, setShowAltContacts] = useState(false);
    const handleExpandAltAddr = () => {
        if (showAltContacts === true) {
            setShowAltContacts(false);
            dispatch(setHasAlternativeAddress(false));
        } else {
            setShowAltContacts(true);
            dispatch(setHasAlternativeAddress(true));
        }
    }

    const handleOrderTypeChange = (event) => {
        console.log("changed orderType to " + event.target.value)
        setOrdType(event.target.value);
        dispatch(setOrderType(event.target.value));
    };

    const handlePriceCalculationChanged = (event) => {
        setPriceCalc(event.target.value);
        dispatch(setPriceCalculation(event.target.value));
        setIsFixPrice(event.target.value === "fix");
    }

    const handleOrderFixPriceChange = (event) => {
        setFixPrice(event.target.value);
        dispatch(setOrderFixPrice(event.target.value.replace(",", ".")));
    }

    const handleOrderCommentChange = (event) => {
        setOrdComment(event.target.value);
        dispatch(setOrderComment(event.target.value));
    }

    const handleServicePackageChange = (event) => {
        dispatch(setServicePackageId(event.target.value));
    }

    return (
        <Card sx={{ height: '100%' }} >
            <AreaCardHeader title={t('schedule.additionalDataCardTitle')} />
            <CardContent sx={{ pt: 4, height: height - 40 }}>
                <Box
                    sx={{
                        mb: 2,
                        height: height,
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                        overflowY: "auto"
                    }}
                >
                    <Grid container width='100%' spacing='10' sx={{ mt: 5 }}>
                        <OrderCriteriaArea />

                        {isOrderVoucherEnabled && (
                            <>
                                <Grid item xs={5} >
                                    <Label text='admin.settings.orderVoucher' />
                                </Grid>
                                <Grid item xs={7}>
                                    <Stack
                                        direction="row"
                                        spacing={4}
                                    >
                                        <TextField
                                            variant='standard'
                                            value={ordVoucherValue}
                                            onChange={handleVoucherChange}
                                            disabled={!isVoucherFieldEnabled}
                                            sx={{ width: 200 }}
                                            error={voucherErrorMsg !== ''}
                                            helperText={voucherErrorMsg}
                                        />
                                        <Button
                                            variant='outlined'
                                            onClick={cashVoucher}
                                            disabled={!isVoucherButtonEnabled}
                                            sx={{}} >
                                            {t('admin.settings.cash')}
                                        </Button>
                                    </Stack>
                                </Grid>
                            </>
                        )}

                        {_businessPartner?.servicePackages?.length > 0 && (
                            <>
                                <Grid item xs={5} >
                                    <Box sx={{ mt: 3 }}>
                                        <Label text={t('order.servicePackage')} />
                                    </Box>
                                </Grid>
                                <Grid item xs={7}>
                                    <FormControl variant="standard" sx={{
                                        m: 1, minWidth: 120, fontSize: 12, '& .MuiInput-root': { mt: '1px !important' },
                                        '& .MuiInputLabel-root': { ml: '-14px !important' }
                                    }}>
                                        <InputLabel id="crit-label">{_order.servicePackageId ? '' : t('schedule.emptySelect')}</InputLabel>
                                        <Select
                                            id="crit-select"
                                            name='servicePackage'
                                            value={_order.servicePackageId ? _order.servicePackageId : ''}
                                            inputProps={{ style: { fontSize: 14 } }}
                                            onChange={handleServicePackageChange}
                                            label={t('schedule.emptySelect')}
                                            sx={{ width: 200, '& #crit-select': { fontSize: 14 } }}
                                            disableUnderline
                                            IconComponent={CustomExpandMore}
                                        >
                                            {_businessPartner.servicePackages.map(item => {
                                                return (<MenuItem value={item._id} sx={{ fontSize: 14 }}>{item.shortName}</MenuItem>);
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </>
                        )}


                        {_selectedOffer?.sapCombination && (
                            <>
                                <Grid item xs={4} >
                                    <Label text='order.sap.combination' />
                                </Grid>
                                <Grid item xs={8}>
                                    <Checkbox
                                        checked={combiChecked}
                                        onChange={handleCombiChange}
                                        disabled={_selectedOffer?.sapCombination && _selectedOffer.sapForce}
                                        sx={{ p: 0 }} />
                                </Grid>
                            </>
                        )}

                        {_features?.ASE_1017?.status === "on" && (
                            <>
                                <Grid item xs={4} >
                                    <Label text='order.alternativeAddress' />
                                </Grid>
                                <Grid item xs={8}>
                                    <Switch
                                        onChange={() => handleExpandAltAddr()}>
                                    </Switch>
                                    <Collapse in={showAltContacts}
                                        orientation='vertical'>
                                        <AlternativeContacts />
                                    </Collapse>
                                </Grid>
                            </>
                        )}

                        {_isCicAuthenticated && (
                            <>
                                <Grid item xs={4} >
                                    <Label text='order.orderType' />
                                </Grid>
                                <Grid item xs={8}>
                                    <Select
                                        value={ordType}
                                        onChange={handleOrderTypeChange}
                                        variant="standard"
                                        sx={{ width: 300 }} >
                                        {_orderTypes.map((item, index) =>
                                            <MenuItem value={item.key}>{item.value}</MenuItem>
                                        )}
                                    </Select>
                                </Grid>
                            </>
                        )}

                        {_isCicAuthenticated && (
                            <>
                                <Grid item xs={4} >
                                    <Label text='order.priceCalculation' />
                                </Grid>
                                <Grid item xs={5}>
                                    <Select
                                        value={priceCalc}
                                        onChange={handlePriceCalculationChanged}
                                        variant="standard"
                                        sx={{ width: 300 }} >
                                        <MenuItem value="regular">{t('order.priceRegular')}</MenuItem>
                                        <MenuItem value="fix">{t('order.priceFix')}</MenuItem>
                                        <MenuItem value="free">{t('order.priceNone')}</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={3}>
                                    {isFixPrice && (<TextField
                                        variant='standard'
                                        type="number"
                                        value={fixPrice}
                                        onChange={handleOrderFixPriceChange}
                                        sx={{ width: 150 }} />
                                    )}
                                </Grid>
                            </>
                        )}

                        {_isCicAuthenticated && (
                            <>
                                <Grid item xs={4} >
                                    <Label text='order.comment' />
                                </Grid>
                                <Grid item xs={8}>
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="stretch"
                                        spacing={2}
                                    >
                                        <TextareaAutosize
                                            value={ordComment}
                                            onChange={handleOrderCommentChange}
                                            variant="standard" />
                                    </Stack>
                                </Grid>
                            </>
                        )}


                    </Grid>

                </Box>
            </CardContent >
        </Card >
    );

});