import React from 'react';
import i18n from 'i18next';
import { languages, loadMessages } from '../configuration/i18n';

import { useSelector } from 'react-redux';
import {
    busy,
    device,
    helpUrl,
    properties,
    versionInfo,
} from '../redux/Selectors.js';

import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    LinearProgress,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Stack,
    Toolbar,
    Typography
} from '@mui/material';
import { HelpIcon, InfoIcon, SettingsIcon } from '../common/widgetTools';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';


import ReactCountryFlag from "react-country-flag";

import Check from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import '../configuration/i18n';
import AppLogo from './AppLogo';
import AuthenticationStatus from './AuthenticationStatus.js';

const AppHeader = () => {

    const { t } = useTranslation();

    const _helpUrl = useSelector(state => helpUrl(state));
    const _busy = useSelector(state => busy(state));
    const _device = useSelector(state => device(state));
    const _properties = useSelector(state => properties(state));
    const _versionInfo = useSelector(state => versionInfo(state));

    /* Language menu */
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(menuAnchorEl);

    /* Mobile menu */
    const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = React.useState(null);
    const isMobileMenuOpen = Boolean(mobileMenuAnchorEl);

    const handleHelpOpen = () => {
        const newWindow = window.open(_helpUrl, '_blank', 'noopener, noreferrer');
        if (newWindow) {
            newWindow.opener = null;
        }
    };

    const handleMenuOpen = () => {
        setMenuAnchorEl(document.getElementById(id));
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleAccessible = () => {
        setAccessibilityOpen(true);
    }

    const handleMobileMenuClose = () => {
        setMobileMenuAnchorEl(null);
    };

    const setLanguage = language => {
        loadMessages(language);
        handleMenuClose();
        handleMobileMenuClose();
    };

    // --------------------------------------------------
    // accessibilty dialog
    // --------------------------------------------------
    const [accessibilityOpen, setAccessibilityOpen] = React.useState(false);
    const AccessibilityDialog = () => {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={'large'}
                open={accessibilityOpen}
                onClose={() => { setAccessibilityOpen(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogTitle>{t('accessibilty.title')}</DialogTitle>
                    <DialogContentText id="alert-dialog-description">
                        <Stack alignItems="center" spacing={5}>
                            <Typography variant="text">{t('accessibilty.description')}</Typography>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ textTransform: 'none' }} variant="text" onClick={() => { window.location = '/webstore/app/index.html?mobile=true' }}>OK</Button>
                    <Button sx={{ textTransform: 'none' }} variant="text" onClick={() => { setAccessibilityOpen(false) }}>{t('main.cancel')}</Button>
                </DialogActions>
            </Dialog >
        )
    };



    // --------------------------------------------------
    // info dialog
    // --------------------------------------------------
    const [infoOpen, setInfoOpen] = React.useState(false);
    const InfoDialog = () => {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={infoOpen}
                onClose={() => { setInfoOpen(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Stack alignItems="center" spacing={5}>
                        <AppLogo />            <div style={{ backgroundImage: "url(../public/webstore-splash.png)" }}></div>
                    </Stack>
                    <DialogTitle></DialogTitle>
                    <DialogContentText id="alert-dialog-description">
                        <Stack alignItems="center" spacing={5}>
                            <Typography variant="text">Powered by alfa WebStore NG</Typography>
                            <Typography variant="text">Version: {_versionInfo.version} </Typography>
                            <Typography variant="text">&#xA9; alfa Media Partner GmbH &#xB7; {new Date().getFullYear()} </Typography>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ textTransform: 'none' }} variant="text" onClick={() => { setInfoOpen(false) }}>OK</Button>
                </DialogActions>
            </Dialog >
        )
    };

    const menuId = 'language-menu';
    const LanguageMenu = () => {
        return (
            <Menu
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                id={menuId}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={handleMenuClose}>
                <MenuList
                    dense>
                    {
                        languages.map(e => {
                            if (e.key !== i18n.language) {
                                return (
                                    <MenuItem
                                        key={e.key}>
                                        <ListItemText
                                            // styling über theme greift in chrome nicht ?? !!
                                            primaryTypographyProps={{ fontSize: '14px' }}
                                            variant="menu" inset onClick={() => { setLanguage(e.key); }} color="inherit">
                                            {e.name}
                                        </ListItemText>
                                    </MenuItem>);
                            }
                            else {
                                return (
                                    <MenuItem key={e.key}>
                                        <ListItemIcon><Check /></ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>{e.name}</ListItemText>
                                    </MenuItem>);
                            }
                        })

                    }
                    {/* {_isBusinessPartnerAuthenticated && (
                        <>
                            <Divider />
                            <MenuItem
                                disabled={location.pathname.startsWith(CUSTOMER_PAGE_LOCATION)}
                                onClick={handleOpenCustomerPage}
                                key={'CustomerData'}>
                                <ListItemIcon><SettingsIcon /></ListItemIcon>
                                <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>{t('status.myAccount')}</ListItemText>
                            </MenuItem>
                            <MenuItem
                                disabled={location.pathname.startsWith(CUSTOMER_PAGE_LOCATION)}
                                onClick={handleLogout}
                                key={'Logout'}>
                                <ListItemIcon><LogoutIcon /></ListItemIcon>
                                <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>{t('status.logout')}</ListItemText>
                            </MenuItem>
                        </>)} */}

                    <Divider />
                    <MenuItem onClick={handleHelpOpen} key={'Help'}>
                        <ListItemIcon><HelpIcon /></ListItemIcon>
                        <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>{t('admin.icons.helpIcon')}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => { setInfoOpen(true); setMenuAnchorEl(null) }} key={'Info'}>
                        <ListItemIcon><InfoIcon /></ListItemIcon>
                        <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>{t('admin.settings.about')}</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu >
        )
    };

    const mobileMenuId = 'mobile-menu';
    const MobileMenu = () => {
        return (
            <Menu
                anchorEl={mobileMenuAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                id={mobileMenuId}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMobileMenuOpen}
                onClose={handleMobileMenuClose}>
                <MenuList dense>
                    {
                        languages.map(e => {
                            if (e.key !== i18n.language) {
                                return (<MenuItem key={e.key}>
                                    <ListItemText sx={{ color: 'red', fontSize: 14 }} inset onClick={() => { setLanguage(e.key); }} color="inherit">
                                        {e.name}
                                    </ListItemText>
                                </MenuItem>);
                            }
                            else {
                                return (<MenuItem key={e.key}>
                                    <ListItemIcon><Check /></ListItemIcon>
                                    {e.name}
                                </MenuItem>);
                            }
                        })
                    }
                    <Divider></Divider>
                    <MenuItem>
                        <Button
                            color="inherit"
                            startIcon={<LocaleIcon />}>
                            {i18n.language.toUpperCase()}
                        </Button>
                    </MenuItem>
                    <MenuItem>
                        <Button
                            onClick={handleHelpOpen}
                            color="inherit"
                            startIcon={<HelpIcon size='lg' />}>
                            {t('Hilfe')}
                        </Button>
                    </MenuItem>
                </MenuList>
            </Menu>
        )
    };
    const LocaleIcon = () => {
        //       let locale = _properties.definedlocation || '';
        let locale = i18n.language || '';
        if (!locale) {
            const lang = navigator.language;
            locale = lang.length > 2 ? lang.substring(3, 5) : lang.substring(0, 2);
        }
        if (locale === "en") locale = "gb";
        return (
            <ReactCountryFlag
                svg
                countryCode={locale.toUpperCase()}
                style={{
                    fontSize: '1em',
                    lineHeight: '1em',
                }}>

            </ReactCountryFlag>
        )
    };

    const ProgressBar = () => {
        return _busy ? <LinearProgress variant="query" color="secondary" ></LinearProgress> : <Box sx={{ height: '4px' }}></Box>;
    };

    const id = 'app-bar-id';
    const idButton = 'button-id';
    return (
        // <Box id={id} sx={{ width: "100%", flexGrow: 1 }}>

        <AppBar id={id} position="static" sx={{ color: _properties.appToolbarContrast }}>
            <ProgressBar></ProgressBar>
            {/* <Grid container columns={16}>
                <Grid item sm={0} md={1} lg={1} />
                <Grid item sm={16} md={14} lg={14} > */}
            <Toolbar sx={{ ml: { lg: '5%', sm: '2%' }, mr: { lg: '5%', sm: '2%' } }}>
                <AppLogo />
                <Box sx={{ flexGrow: 1 }} />
                {/* <Box sx={{ pr: "1rem" }}>
                    <Button variant="text">Text</Button>
                    <Button variant="contained">Contained</Button>
                    <Button variant="outlined">Outlined</Button>
                    <Button disabled variant="text">Text</Button>
                    <Button disabled variant="contained">Contained</Button>
                    <Button disabled variant="outlined">Outlined</Button>
                </Box> */}
                <Box id={idButton}><AuthenticationStatus menuAnchor={idButton} /></Box>
                {'mobile' === _device && (
                    <IconButton onClick={handleMenuOpen} color="primary"><SettingsIcon /></IconButton>
                )}
                {'mobile' !== _device && (
                    <>
                        <Button color="inherit" xvariant="menu" onClick={handleMenuOpen} startIcon={<LocaleIcon />}>
                            {i18n.language.toUpperCase()}
                        </Button>
                        <IconButton
                            color="inherit"
                            onClick={handleMenuOpen}
                            size="small">
                            <SettingsIcon />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            onClick={handleAccessible}
                            size="small">
                            <AccessibilityNewIcon />
                        </IconButton>

                    </>
                )}
                <LanguageMenu></LanguageMenu>
                <MobileMenu></MobileMenu>
                <InfoDialog />
                <AccessibilityDialog />
            </Toolbar>
            {/* </Grid>
                <Grid item sm={0} md={1} lg={1} />
            </Grid> */}

        </AppBar>
        // </Box >
    );
}

export default AppHeader